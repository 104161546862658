import React, { Component } from "react";
import moment from "moment";
import withStyles from '@mui/styles/withStyles';
import { createFilterOptions } from '@mui/material/Autocomplete';
import GameDataService from "../../services/game.service";
import PlayerDataService from "../../services/player.service";
import PlayerGroupDataService from "../../services/playerGroup.service";
import MatchDataService from "../../services/match.service"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useHeaderStore } from "src/store/store";



import { Grid, TextField, FormControlLabel, Checkbox, Button, Divider, Autocomplete } from '@mui/material';



const useStyles = theme => ({
  container: {
    textAlign: 'center',
  },
  submit: {
    display: 'block',
    margin: 'auto',
  },
  playerDivider: {
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 16px)'
    },
  }
});

class AddMatch extends Component {

  constructor(props) {
    super(props);    
    this.onChangeDateMatch = this.onChangeDateMatch.bind(this);
    this.onChangeCommentMatch = this.onChangeCommentMatch.bind(this);
    this.onChangePlayerCount = this.onChangePlayerCount.bind(this);
    this.addMatch = this.addMatch.bind(this);

    this.collection_id = localStorage.getItem("collectionID");

    this.game  = this.props.match.params.game_id;

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "match.addMatch", help: "help.addMatch"})

    this.state = {
      id: null,
      title: "",
      commentMatch: null,
      dateMatch: moment().format("YYYY-MM-DD"),
      coop: false,
      extensions: {},
      playerGroups: {},
      recordPoints: false,

      playerCount: 0,
      player: [],
      selectedExtensions: [],
      selectedPlayerGroup: null,
      
      allPlayers: [],

      dateError: "",
      submitted: false,
      loading: true,

      metaInfo: [],
    }
  }

  componentDidMount() {
    this.retrieveGameDetails(this.game);  
  }


  retrieveGameDetails(id) {    
    GameDataService.getForMatch(id, this.collection_id)
    .then(response => {
      let selectedExtensions = [];
      let recordPoints = 0;
      if(id != response.data.data.game.id) {
        selectedExtensions.push(response.data.data.extensions.find((extension) => extension.id == id))
      }
      selectedExtensions.map((extension, index) => {
        recordPoints = recordPoints + extension.recordPoints
      })
      this.setState({
        id: response.data.data.game.id,
        title: response.data.data.game.title,
        coop: response.data.data.game.coop,
        maingame_recordPoints: response.data.data.game.recordPoints,
        recordPoints: recordPoints + response.data.data.game.recordPoints > 0 ? 1 : 0,
        extensions: response.data.data.extensions,
        selectedExtensions: selectedExtensions,
        metaInfo: response.data.meta,
        loading: false
      });
      this.retrievePlayers();
      this.retrievePlayerGroups();
      this.props.setStateValue('isLoading', false)
      const player_cnt = response.data.data.game.min_players || 1
      for(var i = 0; i < player_cnt; i++) {
        this.onChangePlayerCount()
      }
    })
    .catch(e => {
      console.log(e);
    });
}

retrievePlayers() {
  PlayerDataService.getAll(this.collection_id)
    .then(response => {
      this.setState({
        allPlayers: response.data.data.players
      })
    })
    .catch(e => {
      console.log(e);
    });
}

retrievePlayerGroups() {
  PlayerGroupDataService.getAll(this.collection_id).then((response) => {
    this.setState({
      playerGroups: response.data.groups,
    });
  });
}

sortFunction(a, b) {
  if (a['player_name'] === b['player_name']) {
    return 0;
  }
  else {
    return (a['player_name'] < b['player_name']) ? -1 : 1;
  }
}

onChangeDateMatch(e) {
    this.setState({
      dateMatch: e.target.value
    });
  }

onChangeExtensions(value) {
  let recordPoints = this.state.maingame_recordPoints;
  value.length > 0 && value.map((extension, value) => {
    recordPoints = recordPoints + extension.recordPoints;
  })
  this.setState({
    selectedExtensions: value,
    recordPoints: recordPoints > 0 ? 1 : 0
  });
}  

onChangePlayerGroup(group) {
  if(group) {
    if(this.state.selectedPlayerGroup && this.state.selectedPlayerGroup !== group) {
      this.state.selectedPlayerGroup.players.map((player) => {
        this.state.allPlayers.push(player)
      })
      this.state.allPlayers.sort(this.sortFunction)
    }
    this.setState({
      selectedPlayerGroup: group,
      playerCount: group.players.length,
      player: group.players
    });
    group.players.map((player, index) => {
      for(var i = 0; i <= this.state.allPlayers.length - 1; i++){
        if(this.state.allPlayers[i]['player_id'] == player.player_id){
          this.state.allPlayers.splice(i--,1);
        }      
      }
    })
  } else {
    this.setState({
      selectedPlayerGroup: group
    });
  }
}  

  onChangeCommentMatch(e) {
    this.setState({
      commentMatch: e.target.value
    });
  }

  onChangePlayer(value,index) {
    if (value) {
      if(this.state.player[index].player_id && this.state.player[index].player_id !== value.player_id) {
        var playerDetails = this.state.player[index]
        this.state.allPlayers.push(playerDetails)
        this.state.allPlayers.sort(this.sortFunction)
      }
      this.setState({
        player: [
          ...this.state.player.slice(0,index),
          {
            player_id: value.player_id,
            player_name: value.player_name,
            winner: false,
            points: false,
          },
          ...this.state.player.slice(index+1)
        ],
      });
      for(var i = 0; i <= this.state.allPlayers.length - 1; i++){
        if(this.state.allPlayers[i]['player_id'] == value.player_id){
          this.state.allPlayers.splice(i--,1);
        }
      }
    } else {
      var playerDetails = this.state.player[index]
      this.setState({
        player: [
          ...this.state.player.slice(0,index),
          {
            player_id: "",
            player_name: "",
            winner: false,
            points: null,
          },
          ...this.state.player.slice(index+1)
        ]
      });
      this.state.allPlayers.push(playerDetails)
      this.state.allPlayers.sort(this.sortFunction)
    }
  }

  onChangeWinner(value, index) {
      this.setState({
        player: [
          ...this.state.player.slice(0,index),
          {
            ...this.state.player[index],
            winner: value,
          },
          ...this.state.player.slice(index+1)
        ],
      });
  }

  onChangePoints(event, index) {
      this.setState({
        player: [
          ...this.state.player.slice(0,index),
          {
            ...this.state.player[index],
            points: event.target.value,
          },
          ...this.state.player.slice(index+1)
        ],
      });
  }

  onChangePlayerCount() {
      this.setState({
          playerCount: this.state.playerCount + 1,
          player: [
            ...this.state.player.slice(0,this.state.playerCount),
            {
              player_id: "",
              player_name: "",
              winner: false,
              points: null,
            },
            ...this.state.player.slice(this.state.playerCount + 1)
          ]
      });
  }

  addMatch() {
    var data = {
      gameID: this.state.id,
      extensions: this.state.selectedExtensions,
      collectionID: this.collection_id,
      dateMatch: this.state.dateMatch,
      commentMatch: this.state.commentMatch,
      players: this.state.player,
    }

    if (data.dateMatch !== null && data.dateMatch !== "") {
      MatchDataService.create(data)
      .then(response => {
        this.props.setStateValue('alertMessage', { severity: 'success', message: 'Die Partie wurde erfolgreich erfasst', key: Math.random() })
        this.props.history.push(`/games/${this.game}`); 
      })
      .catch(e => {
        if(e.response.status === 403) {
          this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.error.code}`, key: Math.random() })
          this.props.history.push(`/`); 
        } else {
          this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Erfassen der Partie', key: Math.random() })
          console.log(e);
        }
      });
    } else {
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Erfassen der Partie', key: Math.random() })
      this.setState({
        dateError: {
          status: true,
          message: "Das Datum darf nicht leer sein.",
        },  
      })
    }
  }
  
  render() {
    const { allPlayers, playerCount, player, extensions, playerGroups, coop, recordPoints, metaInfo, loading } = this.state;
    const { classes } = this.props;

    if (!loading && (!metaInfo.privileges?.owner && !metaInfo.privileges?.match_insert)) {
      this.props.setStateValue('alertMessage', { severity: 'error', message: `error.2012`, key: Math.random() })
      this.props.history.push(`/`); 
    }else {
    return (
      <React.Fragment>
          <form> 
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="title"
                name="title"
                label="Hauptspiel"
                value={this.state.title}
                autoComplete="game-title"
                disabled
                fullWidth
                variant="standard" />
            </Grid>
            { extensions.length > 0 &&
            <Grid item xs={12} sm={6}>
            <Autocomplete
                  multiple
                  id="extensions"
                  options={extensions}
                  value={this.state.selectedExtensions}
                  onChange={(event, value) => this.onChangeExtensions(value)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.title}
                  getItemValue={(option) => option.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.title}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Gespielte Erweiterungen" />
                  )}
                  />
            </Grid>
            }
            <Grid item xs={12} sm={6}>
              <TextField
                type ="date"
                id="dateMatch"
                name="dateMatch"
                label="Datum"
                value={this.state.dateMatch}
                onChange={this.onChangeDateMatch}
                error={this.state.dateError.status}
                helperText={this.state.dateError.message}
                fullWidth
                InputLabelProps={{ shrink: true, }}
                variant="standard" />              
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="comment"
                name="comment"
                label="Anmerkung"
                value={this.state.commentMatch}
                onChange={this.onChangeCommentMatch}
                autoComplete="off"
                fullWidth
                variant="standard" />
            </Grid>
            { !extensions.length > 0 && <Grid item xs={12} sm={6} /> }
            { playerGroups.length > 0 &&
            (<>
            <Grid item xs={12} sm={6}>                  
              <Autocomplete
                  options={playerGroups}
                  getOptionLabel={(option) => option.group_name}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Spielergruppe wählen"
                    inputProps={{...params.inputProps, autoComplete: 'off', 'aria-autocomplete': 'none'}}
                    variant="standard" />}
                  onChange={(event, value) => this.onChangePlayerGroup(value)}
                  autoComplete="off"
                  fullWidth
                  />
            </Grid>
            <Grid item xs={12} sm={6} /> 
            </>)
            }
            {/*this.state.coop &&
            <Grid item xs={3} sm={2}>
              <FormControlLabel
                control={<Checkbox 
                color="primary" 
                id={`winner-}`}
                name={`winner-}`}
                onChange={(event, value) => this.onChangeWinner(value, 1)} />}
                labelPlacement="start"
                label="Gemeinsam gewonnen"
              />
            </Grid>   
                */}
             { Array(playerCount).fill(null).map((value, index) => (
               <>
               <Grid container item xs={12} sm={recordPoints? 12 : 6} md={6} spacing={3}>
                <Grid item xs={recordPoints ? 12 : 9} sm={recordPoints ? 6 : 9} md={recordPoints ? 8 : 10}>
                  <Autocomplete
                  options={allPlayers}
                  value={player[index]}
                  getOptionLabel={(option) => option.player_name}
                  isOptionEqualToValue={(option, value) => option.player_id === value.player_id}
                  /*renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.player_name} 
                    </Box>
                  )}*/
                  renderInput={(params) => <TextField
                    {...params}
                    label={`Spieler ${index + 1}`}
                    inputProps={{...params.inputProps, autoComplete: 'off', 'aria-autocomplete': 'none'}}
                    variant="standard" />}
                  onChange={(event, value) => this.onChangePlayer(value, index)}
                  autoComplete="off"
                  fullWidth
                  />
                </Grid>
                {recordPoints===1 &&
                <Grid item xs={6} sm={4} md={2}>
                  <TextField
                    id={`points-${index + 1}`}
                    name={`points-${index + 1}`}
                    type="number"
                    label="Punkte"
                    value={this.state.player[index]?.points}
                    onChange={(event, value) => this.onChangePoints(event, index)}
                    autoComplete="off"
                    fullWidth
                    variant="standard" 
                  />
                </Grid>        
                }
                <Grid item xs={recordPoints ? 6 : 3} sm={recordPoints ? 2 : 3} md={recordPoints ? 2 : 2}> 
                  <FormControlLabel
                    control={<Checkbox 
                    color="primary" 
                    id={`winner-${index + 1}`}
                    name={`winner-${index + 1}`}
                    checked={this.state.player[index]?.winner} 
                    onChange={(event, value) => this.onChangeWinner(value, index)} />}
                    labelPlacement="top"
                    label="Sieger"
                  />
                </Grid>
                <Divider className={classes.playerDivider} variant="middle" />
              </Grid>
              </>        
             ))}
            <Grid item xs={12} sm={12}>
              <Button className={classes.submit} variant="contained" color="primary" onClick={this.onChangePlayerCount}>Spieler hinzufügen</Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button className={classes.submit} variant="contained" color="primary" onClick={this.addMatch}>Speichern</Button>
            </Grid>
          </Grid>
          </form>
      </React.Fragment>
    );
    }
  }
}

export default withStyles(useStyles)(AddMatch)