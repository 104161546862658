import http from "../http-common";

class ClassLendDataService {
  getAll(collection_id) {
    return http.get(`/lends?c=${collection_id}`);
  }
   
  get(id) {
    return http.get(`/lends/${id}`);
  }

  create(data) {
    return http.post(`/lends`, data);
  }

  update(id, data) {
    return http.put(`/lends/${id}`, data);
  }

  delete(id) {
    return http.delete(`/lends/${id}`);
  }

}

const LendDataService = new ClassLendDataService();
export default LendDataService;
