import React, { Component } from 'react'
import { authenticationService } from '../../auth/authentication.service';
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import withStyles from '@mui/styles/withStyles';
import UserDataService from "../../services/user.service";

import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Link,
  Box,
  Typography,
} from '@mui/material';

function Copyright() {
  return (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://spieleordner.de/">
      Spieleordner
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
  );
  }

const useStyles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })


class SignUp extends Component {
  constructor(props) {
    super(props);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
      this.props.history.push("/");
    }
    this.props.setStateValue('isLoading', false)
  }
  
  state = {
    
  }

  componentWillUnmount() {
    this.props.setStateValue('isLoading', true)
  }


  _handleSubmit = ({
    username,
    firstname,
    lastname,
    email,
    password,
    password999,
    setSubmitting,
    setFieldError,
    resetForm,
  }) => {
    var data = {username: username, firstname: firstname, lastname: lastname, email: email, password: password, password999: password999}; 
    if(!password999) {
      UserDataService.signup(data)
      .then(
        response => {
          setSubmitting(false)
          this.props.setStateValue('alertMessage', { severity: 'success', message: 'Um die Registrierung abzuschließen, haben wir dir eine E-Mail geschickt. Bitte klicke auf den Link um deinen Account zu aktivieren', key: Math.random() })
          this.props.history.push('/'); 
        }
      )
      .catch(
        error => { 
          setSubmitting(false)
          this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler bei der Registrierung', errorList: error.response.data, key: Math.random() })
        }
      ); 
    } else {
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Um die Registrierung abzuschließen, haben wir dir eine E-Mail geschickt. Bitte klicke auf den Link um deinen Account zu aktivieren', key: Math.random() })
      this.props.history.push('/'); 
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Formik
        initialValues={{
          username: '',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
          password999: ''
        }}
        validationSchema={object().shape({
          username: string().min(3, 'Der Benutzername muss mindestens 3 Zeichen enthalten').max(32, 'Der Benutzername darf maximal 32 Zeichen enthalten').required('Bitte einen Benutzernamen eingeben').matches(/^[a-zA-Z0-9]+$/, 'Der Benutzername darf nur aus Buchstaben und Zahlen bestehen'),
          firstname: string().max(32, 'Der Vorname darf maximal 32 Zeichen enthalten').required('Bitte einen Vornamen eingeben'),
          lastname: string().max(32, 'Der Nachname darf maximal 32 Zeichen enthalten').matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-]+$/, 'Bitte einen gültigen Nachnamen eingeben').required('Bitte einen Nachnamen eingeben'),
          email: string()
          .required('Bitte eine E-Mail eingeben')
          .email("Bitte eine gültige E-Mail eingeben"),
          password: string().min(6, 'Das Passwort muss mindestens 6 Zeichen enthalten').max(64, 'Das Passwort darf maximal 64 Zeichen enthalten').required('Bitte ein Passwort eingeben'),
          confirmPassword: string()
          .oneOf([ref('password')], 'Passwörter stimmen nicht überein')
          .required('Bitte das Passwort wiederholen')
        })}
        onSubmit={(
          { username, firstname, lastname, email, password, password999 },
          { setSubmitting, setStatus, setFieldError, resetForm, }
        ) =>
          this._handleSubmit({
            username,
            firstname,
            lastname,
            email,
            password,
            password999,
            setSubmitting,
            setStatus,
            setFieldError,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return <>        
            <Container component="main" maxWidth="xs">
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
            <div className={classes.paper}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="username"
                    label="Benutzername"
                    name="username"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                  />
                  <FormHelperText
                    error={Boolean(touched.username && errors.username)}
                  >
                    {touched.username && errors.username
                      ? errors.username
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="firstname"
                    label="Vorname"
                    name="firstname"
                    variant="outlined"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.firstname && errors.firstname)}
                  />
                  <FormHelperText
                    error={Boolean(touched.firstname && errors.firstname)}
                  >
                    {touched.firstname && errors.firstname
                      ? errors.firstname
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="lastname"
                    label="Nachname"
                    name="lastname"
                    variant="outlined"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.lastname && errors.lastname)}
                  />
                  <FormHelperText
                    error={Boolean(touched.lastname && errors.lastname)}
                  >
                    {touched.lastname && errors.lastname
                      ? errors.lastname
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="email"
                    label="E-Mail"
                    name="email"
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.email && errors.email)}
                  />
                  <FormHelperText
                    error={Boolean(touched.email && errors.email)}
                  >
                    {touched.email && errors.email
                      ? errors.email
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.password && errors.password)}
                  variant="standard">
                  <TextField
                    name="password"
                    label="Passwort"
                    type="password"
                    id="password"
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.password && errors.password)}
                  />
                  <FormHelperText
                    error={Boolean(touched.password && errors.password)}
                  >
                    {touched.password && errors.password ? errors.password : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  variant="standard">
                  <TextField
                    name="confirmPassword"
                    label="Passwort wiederholen"
                    type="password"
                    id="confirmPassword"
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  >
                    {touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                  </FormHelperText>
                </FormControl> 
                <FormControl
                  sx={{display: 'none'}}>
                  <TextField
                    name="password999"
                    type="password"
                    variant="outlined"
                    value={values.password999}
                    onChange={handleChange}
                  />
                </FormControl>                
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  fullWidth
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {'Registrieren'}
                </Button>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
            </Container>
          </>;
          
        }}
      />
    );
  }
}

export default withStyles(useStyles)(SignUp)