import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import {
  StatiticHeader,
  StatisticTable,
} from "src/components/StyledComponents";
import TabPanel from "src/components/TabPanel";

const Games = ({ value, index, statistic, t, ...other }) => {

  return (
    <TabPanel value={value} index={index} dir="ltr">
      <StatiticHeader
        variant="h5"
        align="center"
        gutterBottom
      >
        Spiele je Standort
      </StatiticHeader>
      <TableContainer component={Paper} sx={{ marginBottom: "5px" }}>
        <StatisticTable
          aria-label="statistic-locations-games"
        >
          <TableBody>
            {statistic?.games_per_location &&
            statistic.games_per_location.length > 0 ? (
              statistic.games_per_location.map((location, index) => (
                <TableRow>
                  <TableCell>{location.location_name}</TableCell>
                  <TableCell align="right">{location.cnt_games}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell>
                  Diese Sammlung enthält noch keine Standorte
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </StatisticTable>
      </TableContainer>
      <StatiticHeader
        variant="h5"
        align="center"
        gutterBottom
      >
        Spiele je Kategorie
      </StatiticHeader>
      <TableContainer component={Paper}>
        <StatisticTable
          aria-label="statistic-categories-games"
        >
          <TableBody>
            {statistic?.games_per_category &&
              statistic.games_per_category.map((category, index) => (
                <TableRow>
                  <TableCell>{category.category_name}</TableCell>
                  <TableCell align="right">{category.cnt_games}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StatisticTable>
      </TableContainer>
    </TabPanel>
  );
};
export default Games;
