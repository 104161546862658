import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VersionDataService from "src/services/version.service";
import { useHeaderStore, useLoadingStore } from "src/store/store";

const VersionHistory = () => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    useHeaderStore.setState({
      title: "version.versionHistory",
      help: "help.versionHistory",
    });
    VersionDataService.getAll()
      .then((response) => {
        setVersions(response.data);
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((error) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(error);
      });
  }, []);

  return (
    <Container>
      {versions.map((version) => (
        <Accordion key={version.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ marginRight: "1em" }}>
              {version.version}
            </Typography>
            {version.active === 1 && (
              <Chip
                label="Aktiv"
                color="primary"
                size="small"
                variant="outlined"
              />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Veröffentlicht am: {format(version.release_date, "dd.MM.yyyy")}
            </Typography>
            <Typography
              sx={{ "& ul": { listStyle: "inside" } }}
              dangerouslySetInnerHTML={{ __html: version.release_notes }}
            ></Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default VersionHistory;
