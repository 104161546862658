import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { useParams, Link } from "react-router-dom";

import {
  useAlertMessageStore,
  useHeaderStore,
  useLoadingStore,
} from "src/store/store";
import StatisticDataService from "src/services/statistic.service";

import {
  Box,
  InputLabel,
  FormControl,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

const StatisticRow = ({ label, value, link }) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell align="right">
      {link ? <Link to={link}>{value}</Link> : value}
    </TableCell>
  </TableRow>
);

const formatDate = (date) => {
  try {
    return format(new Date(date), "dd.MM.yyyy");
  } catch (e) {
    return "n/A";
  }
};

export default function AccountStatistic() {
  const [collection, setCollection] = useState(0);
  const [collectionList, setCollectionList] = useState([]);
  const [statistic, setStatistic] = useState({});
  const [statisticList, setStatisticList] = useState([]);
  const { collection_id, player_id } = useParams();

  useEffect(() => {
    useHeaderStore.setState({
      title: "statistic.statisticAccount_one",
      help: "help.statisticAccount",
    });
    useLoadingStore.setState({ isLoading: true });

    fetchStatistic();
  }, [collection_id, player_id]);

  const fetchStatistic = async () => {
    useLoadingStore.setState({ isLoading: true });
    try {
      const response = await StatisticDataService.findByAccount();
      const tmp_collectionList = response.data.statistic.map(
        (collection_statistic) => {
          return collection_statistic.collection;
        }
      );
      const statisticList = response.data.statistic;
      setCollectionList(tmp_collectionList);
      setStatisticList(statisticList);
      const curr_statistic = statisticList.find((statistic) => {
        return Number(statistic.collection.id) === 0;
      });
      setStatistic(curr_statistic);
    } catch (e) {
      console.error(e);
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: `Fehler beim Laden der Statistik`,
        },
      });
    } finally {
      useLoadingStore.setState({ isLoading: false });
    }
  };

  const handleCollectionChange = (e) => {
    const collection_id = Number(e.target.value);
    const curr_statistic = statisticList.find((statistic) => {
      return statistic.collection.id === collection_id;
    });
    setStatistic(curr_statistic);
    setCollection(collection_id);
  };

  const formattedDateLatestBurrow = statistic?.latest_burrow
    ? statistic.latest_burrow.date_end
      ? `(${formatDate(statistic.latest_burrow?.date_start)} - ${formatDate(
          statistic.latest_burrow?.date_end
        )})`
      : `(seit ${formatDate(statistic.latest_burrow?.date_start)})`
    : "";
  const formattedDateLatestMatch = statistic?.latest_match
    ? `(${formatDate(statistic.latest_match.date_match)})`
    : "";
  return (
    <>
      <Box>
        <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
          <InputLabel id="collection-selec-label">Sammlung</InputLabel>
          <Select
            native
            labelId="collection-select-label"
            id="collection-select"
            value={collection}
            label="Sammlung"
            onChange={handleCollectionChange}
          >
            {collectionList?.length > 0 &&
              collectionList.map((collection, index) => (
                <option value={collection.id} key={collection.name}>
                  {collection.name}
                </option>
              ))}
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="statistic-details">
          <TableBody>
            <StatisticRow
              label="Anzahl Partien"
              value={statistic.cnt_matches || "n/A"}
            />
            <StatisticRow
              label="Anzahl Siege"
              value={statistic.cnt_wins || "n/A"}
            />
            <StatisticRow
              label="Siegesrate"
              value={`${statistic.winrate?.toLocaleString("de-de") || "n/A"}%`}
            />
            <StatisticRow
              label="Spiel mit den meisten Partien"
              value={
                statistic.most_played_game
                  ? `${statistic.most_played_game?.game.title || "n/A"} (${
                      statistic.most_played_game?.cnt_matches || "n/A"
                    })`
                  : "n/A"
              }
              link={
                statistic.most_played_game &&
                `/games/${statistic.most_played_game.game.id}`
              }
            />
            <StatisticRow
              label="Spiel mit den meisten Siegen"
              value={
                statistic.most_won_game
                  ? `${statistic.most_won_game?.game.title || "n/A"} (${
                      statistic.most_won_game?.cnt_wins || "n/A"
                    })`
                  : "n/A"
              }
              link={
                statistic.most_won_game &&
                `/games/${statistic.most_won_game.game.id}`
              }
            />
            <StatisticRow
              label="Letzte Partie"
              value={`${
                statistic.latest_match?.game.title || "n/A"
              } ${formattedDateLatestMatch}`}
              link={
                statistic.latest_match &&
                `/games/${statistic.latest_match.game.id}`
              }
            />
            <StatisticRow
              label="Letzte Leihe"
              value={`${
                statistic.latest_burrow?.game.title || "n/A"
              } ${formattedDateLatestBurrow}`}
              link={
                statistic.latest_burrow &&
                `/games/${statistic.latest_burrow.game.id}`
              }
            />
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
