import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import withStyles from '@mui/styles/withStyles';
import { compose } from 'recompose'
import { withTranslation } from "react-i18next";
import ContactDataService from "../../../services/contact.service";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";


import {
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';


const useStyles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      display: 'block',
      margin: 'auto',
      marginTop: '16px',
    },
    contentBox: {
      width: '100%',
      maxWidth: 450,
    }
  })


class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "contact.contact", help: "help.contactForm"})

  }

  componentDidMount() {
    this.props.setStateValue('isLoading', false)
  }

  _handleSubmit = ({
    subject,
    message,
    setSubmitting,
    resetForm,
  }) => {
    const { innerWidth: width, innerHeight: height } = window;

    var data = {
      subject: subject,
      message: message,
      localStorage: localStorage,
      history: this.props.history,
      currentUser: this.props.currentUser.username,
      resolution: `${width} x ${height}`,
      version: process.env.REACT_APP_VERSION
    }
    ContactDataService.createSingle(data)
    .then(response => {
      resetForm()
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Vielen Dank für deine Nachricht. Wir werden uns zeitnah bei dir melden!', key: Math.random() })
      setSubmitting(false)
    })
    .catch(err => {
      //resetForm({ values: { subject: subject, message: message } })
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Senden der Nachricht', key: Math.random() })
      setSubmitting(false)
    })
  }

  render() {
    const { classes, t } = this.props;
    return (
      <AccountDashboardLayout>
      <Box className={classes.contentBox}>
      <Formik
        initialValues={{
          subject: '',
          message: '',
        }}
        validationSchema={object().shape({
          subject: string().required('Bitte einen Betreff eingeben'),
          message: string().required('Bitte eine Nachricht eingeben'),
        })}
        onSubmit={(
          { subject, message },
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            subject,
            message,
            setSubmitting,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return (
              <Container component="main" maxWidth="xs">
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open sx={{ zIndex: 1}}>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="subject"
                    name="subject"
                    required
                    label={t('contact.contactForm.subject')}
                    variant="outlined"
                    value={values.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.subject && errors.subject)}
                  />
                  <FormHelperText
                    error={Boolean(touched.subject && errors.subject)}
                  >
                    {touched.subject && errors.subject
                      ? errors.subject
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.message && errors.message)}
                  variant="standard">
                  <TextField
                    id="message"
                    name="message"
                    required
                    label={t('contact.contactForm.message')}
                    variant="outlined"
                    multiline
                    minRows={5}
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.message && errors.message)}
                  />
                  <FormHelperText
                    error={Boolean(touched.message && errors.message)}
                  >
                    {touched.message && errors.message ? errors.message : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {t('contact.contactForm.submit')}
                </Button>
              </form>
            </Container>
          );
        }}
      />
      </Box>
      </AccountDashboardLayout>
    );
  }
}

export default compose(withTranslation(),withStyles(useStyles)) (ContactForm)