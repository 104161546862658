import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GameDataService from "../../services/game.service";
import LocationDataService from "../../services/location.service";
import CategoryDataService from "../../services/category.service";
import UserDataService from "../../services/user.service";
import BGDBDataService from "../../services/bgdb.service";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CasinoIcon from "@mui/icons-material/Casino";
import { handleResponseAxios } from "../../helpers/handle-response";
import {
  useAlertMessageStore,
  useHeaderStore,
  useLoadingStore,
} from "src/store/store";

import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Card,
  CardActionArea,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Rating,
} from "@mui/material";

const useStyles = (theme) => ({
  submit: {
    display: "block",
    margin: "auto",
  },
  search: {
    marginBottom: "10px",
    marginLeft: "-15px",
    background: "white",
  },
  searchAccordion: {
    marginBottom: "10px",
  },
  searchAccordionDetails: {
    flexDirection: "column",
  },
  resultPaper: {
    marginBottom: "5px",
  },
  picture: {
    "& img": {
      height: "10vh",
    },
  },
  MuiSnackbar: {
    paddingTop: "64px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

class AddGame extends Component {
  constructor(props) {
    super(props);
    this.onChangeExpandSearch = this.onChangeExpandSearch.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeEAN = this.onChangeEAN.bind(this);
    this.onChangeDesigner = this.onChangeDesigner.bind(this);
    this.onChangePublisher = this.onChangePublisher.bind(this);
    this.onChangeMaingame = this.onChangeMaingame.bind(this);
    this.onChangeMinPlayers = this.onChangeMinPlayers.bind(this);
    this.onChangeMaxPlayers = this.onChangeMaxPlayers.bind(this);
    this.onChangeMinAge = this.onChangeMinAge.bind(this);
    this.onChangeGameDuration = this.onChangeGameDuration.bind(this);
    this.onChangeDatePurchased = this.onChangeDatePurchased.bind(this);
    this.onChangeDateReleased = this.onChangeDateReleased.bind(this);
    this.onChangeCoop = this.onChangeCoop.bind(this);
    this.onChangeRecordPoints = this.onChangeRecordPoints.bind(this);
    this.onChangeRating = this.onChangeRating.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeCategories = this.onChangeCategories.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onChangeBgdbID = this.onChangeBgdbID.bind(this);
    this.addGame = this.addGame.bind(this);
    this.handleAddMultiple = this.handleAddMultiple.bind(this);

    this.onChangeSearchName = this.onChangeSearchName.bind(this);
    this.fetchBGDBGames = this.fetchBGDBGames.bind(this);
    this.collection_id = localStorage.getItem("collectionID");

    useLoadingStore.setState({ isLoading: true });
    useHeaderStore.setState({
      title: "game.addGame_one",
      help: "help.addGame",
    });

    this.state = {
      id: null,
      title: "",
      ean: "",
      designer: "",
      publisher: "",
      selectedMaingame: null,
      minPlayers: "",
      maxPlayers: "",
      minAge: "",
      gameDuration: "",
      datePurchased: "",
      dateReleased: "",
      coop: false,
      recordPoints: false,
      rating: "",
      description: "",
      selectedCategories: [],
      selectedLocation: null,
      image: "",
      bgdbID: "",

      titleError: {
        status: false,
        message: "",
      },
      minPlayersError: {
        status: false,
        message: "",
      },
      maxPlayersError: {
        status: false,
        message: "",
      },
      minAgeError: {
        status: false,
        message: "",
      },
      gameDurationError: {
        status: false,
        message: "",
      },
      datePurchasedError: {
        status: false,
        message: "",
      },
      dateReleasedError: {
        status: false,
        message: "",
      },

      locations: [],
      categories: [],
      maingames: [],

      expandSearch: false,
      searchName: "",
      searchResults: false,
      BGDBGames: [],

      privileges: [],
      loading: true,
    };
  }

  componentWillMount() {
    this.checkAccountType();
  }

  componentDidMount() {
    this.retrieveCategories();
    this.retrieveLocations();
    this.retrieveMainGames();
    this.initialState = this.state;
  }

  checkAccountType() {
    UserDataService.checkAccountType("addGame", this.collection_id)
      .then((response) => {})
      .catch((e) => {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: `error.${e.response.data.message}`,
        },
      });
        this.props.history.push(`/`);
      });
  }

  handleAddMultiple() {
    useAlertMessageStore.setState({
      alertMessage: {
        severity: "success",
        message: "Das Spiel wurde erfolgreich hinzugefügt",
      },
    });
    this.setState({
      ...this.initialState,
    });
    this.retrieveCategories();
    this.retrieveLocations();
    this.retrieveMainGames();
  }

  onChangeSearchName(e) {
    this.setState({
      searchName: e.target.value,
    });
    this.state.BGDBGames.length === 0 &&
      this.setState({
        searchResults: false,
      });
  }

  fetchBGDBGames() {
    useLoadingStore.setState({ isLoading: true });
    BGDBDataService.getMultiple(this.state.searchName)
      .then((response) => {
        useLoadingStore.setState({ isLoading: false });
        if (response.data.games.length === 0) {
          useAlertMessageStore.setState({
            alertMessage: {
              severity: "info",
              message: "Es wurde leider kein passendes Spiel gefunden",
            },
          });
        }
        this.setState({
          searchResults: true,
          BGDBGames: response.data.games,
        });
      })
      .catch((e) => {
        useLoadingStore.setState({ isLoading: false });
        console.dir(e);
        useAlertMessageStore.setState({
          alertMessage: {
            severity: "error",
            message: "Fehler beim Zugriff auf die BGDB-API",
          },
        });
        this.setState({
          BGDBGames: [],
        });
      });
  }

  selectGame(game) {
    if (game) {
      this.setState({
        title: game.name,
        bgdbID: game.id,
        designer: game.designer || null,
        publisher: game.publisher || null,
        minPlayers: game.minplayers,
        maxPlayers: game.maxplayers,
        minAge: game.minage,
        gameDuration: game.playtime,
        dateReleased: game.yearpublished,
        image: game.image,
        coop: game.coop,
      });
      this.onChangeExpandSearch();
    }
  }

  retrieveCategories() {
    CategoryDataService.getAll()
      .then((response) => {
        this.setState({
          categories: response.data.data,
        });
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }
  retrieveLocations() {
    LocationDataService.getAll(this.collection_id)
      .then((response) => {
        this.setState({
          locations: response.data.data,
        });
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }
  retrieveMainGames() {
    GameDataService.getMainGames(this.collection_id)
      .then((response) => {
        this.setState({
          maingames: response.data.data,
          privileges: response.data.meta.privileges,
          loading: false,
        });
        useLoadingStore.setState({ isLoading: false });
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }

  onChangeExpandSearch() {
    this.setState({
      expandSearch: !this.state.expandSearch,
    });
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeEAN(e) {
    this.setState({
      ean: e.target.value,
    });
  }

  onChangeDesigner(e) {
    this.setState({
      designer: e.target.value,
    });
  }

  onChangePublisher(e) {
    this.setState({
      publisher: e.target.value,
    });
  }

  onChangeMaingame(value) {
    this.setState({
      selectedMaingame: value,
    });
  }

  onChangeMinPlayers(e) {
    this.setState({
      minPlayers: e.target.value,
    });
  }

  onChangeMaxPlayers(e) {
    this.setState({
      maxPlayers: e.target.value,
    });
  }

  onChangeMinAge(e) {
    this.setState({
      minAge: e.target.value,
    });
  }

  onChangeGameDuration(e) {
    this.setState({
      gameDuration: e.target.value,
    });
  }

  onChangeDatePurchased(e) {
    this.setState({
      datePurchased: e.target.value,
    });
  }

  onChangeDateReleased(e) {
    this.setState({
      dateReleased: e.target.value,
    });
  }

  onChangeCoop(e) {
    this.setState({
      coop: !this.state.coop,
    });
  }

  onChangeRecordPoints(e) {
    this.setState({
      recordPoints: !this.state.recordPoints,
    });
  }

  onChangeRating(e) {
    this.setState({
      rating: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  onChangeCategories(value) {
    this.setState({
      selectedCategories: value,
    });
  }

  onChangeLocation(value) {
    this.setState({
      selectedLocation: value,
    });
  }

  onChangeImage(e) {
    this.setState({
      image: e.target.value,
    });
  }

  onChangeBgdbID(e) {
    this.setState({
      bgdbID: e.target.value,
    });
  }

  addGame(multiple) {
    let category_ids = [];
    this.state.selectedCategories.map((category, index) =>
      category_ids.push({ id: category.id })
    );
    var data = {
      collection_id: this.collection_id,
      title: this.state.title,
      ean: this.state.ean,
      designer: this.state.designer,
      publisher: this.state.publisher,
      minPlayers: this.state.minPlayers === "" ? null : this.state.minPlayers,
      maxPlayers: this.state.maxPlayers === "" ? null : this.state.maxPlayers,
      minAge: this.state.minAge === "" ? null : this.state.minAge,
      gameDuration:
        this.state.gameDuration === "" ? null : this.state.gameDuration,
      datePurchased:
        this.state.datePurchased === "" ? null : this.state.datePurchased,
      dateReleased:
        this.state.dateReleased === "" ? null : this.state.dateReleased,
      coop: this.state.coop,
      recordPoints: this.state.recordPoints,
      maingameID:
        !this.state.selectedMaingame || this.state.selectedMaingame.id === ""
          ? null
          : this.state.selectedMaingame.id,
      rating: this.state.rating,
      description: this.state.description,
      categoriesID: category_ids === "" ? null : category_ids,
      locationID:
        !this.state.selectedLocation || this.state.selectedLocation?.id === ""
          ? null
          : this.state.selectedLocation.id,
      image: this.state.image,
      bgdbID: this.state.bgdbID,
    };

    if (data.title !== "") {
      GameDataService.create(data)
        .then((response) => {
          if (multiple) {
            this.handleAddMultiple();
          } else {
            useAlertMessageStore.setState({
              alertMessage: {
                severity: "success",
                message: `success.${response.data.message}`,
              },
            });
            this.props.history.push(`/games/${response.data.id}`);
          }
        })
        .catch((e) => {
          if (e.response.status === 403) {
            useAlertMessageStore.setState({
              alertMessage: {
                severity: "error",
                message: `error.${e.response.data.error.code}`,
              },
            });
            this.props.history.push("/");
          } else {
            useAlertMessageStore.setState({
              alertMessage: {
                severity: "error",
                message: `error.${e.response.data.message}`,
              },
            });
          }
        });
    } else {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: "error.2100",
        },
      });
      this.setState({
        titleError: {
          status: true,
          message: "Der Titel darf nicht leer sein.",
        },
      });
    }
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const {
      maingames,
      categories,
      locations,
      searchResults,
      BGDBGames,
      expandSearch,
      privileges,
      loading,
    } = this.state;
    const { classes } = this.props;

    if (!loading && !privileges.owner && !privileges.game_insert) {
      useAlertMessageStore.setState({
        alertMessage: {
          severity: "error",
          message: "error.2002",
        },
      });
      return <Redirect to={{ pathname: `/` }} />;
    } else {
      return (
        <React.Fragment>
          <Accordion
            className={classes.searchAccordion}
            expanded={expandSearch}
            onChange={this.onChangeExpandSearch}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Spiel für Import suchen
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.searchAccordionDetails}>
              <Grid className={classes.search} container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    id="searchTitle"
                    name="searchTitle"
                    label="Titel"
                    value={this.state.searchName}
                    onChange={this.onChangeSearchName}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    onClick={this.fetchBGDBGames}
                  >
                    Spiel suchen
                  </Button>
                </Grid>
              </Grid>
              {searchResults &&
                (BGDBGames.length > 0 ? (
                  BGDBGames.map((game, index) => (
                    <Card variant="outlined" style={{ marginBottom: "10px" }}>
                      <CardActionArea onClick={() => this.selectGame(game)}>
                        <div
                          className="gameInfo-card"
                          style={{ alignItems: "center" }}
                        >
                          <picture className={classes.picture}>
                            <source srcSet={game.image} alt={game.name} />
                            <img src="images/no_image.png" alt={game.name} />
                          </picture>
                          <div className="gameInfo-details">
                            <Typography gutterBottom variant="subtitle1">
                              {game.name}
                            </Typography>
                            {game.designer && (
                              <Typography gutterBottom variant="subtitle2">
                                {game.designer}
                              </Typography>
                            )}
                            {game.publisher && (
                              <Typography gutterBottom variant="subtitle2">
                                {game.publisher}
                              </Typography>
                            )}
                          </div>
                        </div>
                      </CardActionArea>
                    </Card>
                  ))
                ) : (
                  <Card
                    variant="outlined"
                    style={{ marginBottom: "10px" }}
                  ></Card>
                ))}
            </AccordionDetails>
          </Accordion>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="title"
                  name="title"
                  label="Titel"
                  value={this.state.title}
                  onChange={this.onChangeTitle}
                  error={this.state.titleError.status}
                  helperText={this.state.titleError.message}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="maingameID"
                  value={this.state.selectedMaingame}
                  options={maingames}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.title}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hauptspiel"
                      variant="standard"
                    />
                  )}
                  onChange={(e, value) => this.onChangeMaingame(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="ean"
                  name="ean"
                  label="EAN"
                  value={this.state.ean}
                  onChange={this.onChangeEAN}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="designer"
                  name="designer"
                  label="Autor"
                  value={this.state.designer}
                  onChange={this.onChangeDesigner}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="publisher"
                  name="publisher"
                  label="Publisher"
                  value={this.state.publisher}
                  onChange={this.onChangePublisher}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="minPlayers"
                  name="minPlayers"
                  label="Min. Spieler"
                  value={this.state.minPlayers}
                  onChange={this.onChangeMinPlayers}
                  inputProps={{ min: 0 }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="maxPlayer"
                  name="maxPlayer"
                  label="Max. Spieler"
                  value={this.state.maxPlayers}
                  onChange={this.onChangeMaxPlayers}
                  inputProps={{ min: 0 }}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3} lg={2}>
                <TextField
                  type="number"
                  id="minAge"
                  name="minAge"
                  label="Min. Alter"
                  value={this.state.minAge}
                  onChange={this.onChangeMinAge}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="number"
                  id="gameDuration"
                  name="gameDuration"
                  label="Spieldauer"
                  value={this.state.gameDuration}
                  onChange={this.onChangeGameDuration}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="date"
                  id="datePurchased"
                  name="datePurchased"
                  label="Anschaffungsdatum"
                  value={this.state.datePurchased}
                  onChange={this.onChangeDatePurchased}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  type="number"
                  id="dateReleased"
                  name="dateReleased"
                  label="Veröffentlicht"
                  value={this.state.dateReleased}
                  onChange={this.onChangeDateReleased}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  multiple
                  id="categoriesID"
                  value={this.state.selectedCategories}
                  options={categories}
                  onChange={(event, value) => this.onChangeCategories(value)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.category_name}
                  getItemValue={(option) => option.id}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.category_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="Genre" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Autocomplete
                  id="locationID"
                  value={this.state.selectedLocation}
                  options={locations}
                  getOptionLabel={(option) => option.location_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Standort"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => this.onChangeLocation(value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6} sm={3} md={2} lg={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="coop"
                      checked={this.state.coop}
                      value={this.state.coop}
                      onChange={this.onChangeCoop}
                    />
                  }
                  label="Koop"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={6} sm={3} md={4} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="recordPoints"
                      checked={this.state.recordPoints}
                      value={this.state.recordPoints}
                      onChange={this.onChangeRecordPoints}
                    />
                  }
                  label="Punkte erfassen"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <Typography component="legend">Bewertung</Typography>
                <Rating
                  name="rating"
                  precision={0.5}
                  value={this.state.rating}
                  onChange={this.onChangeRating}
                  icon={<CasinoIcon color={"primary"} />}
                  emptyIcon={<CasinoIcon />}
                />
              </Grid>
              <Grid item xs={6} sm={6} lg={3}>
                <TextField
                  type="string"
                  id="bgdbID"
                  name="bgdbID"
                  label="BoardGameGeek-ID"
                  value={this.state.bgdbID}
                  onChange={this.onChangeBgdbID}
                  fullWidth
                  variant="standard"
                />
                </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="string"
                  id="description"
                  name="description"
                  label="Hinweis"
                  value={this.state.description}
                  onChange={this.onChangeDescription}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="string"
                  id="image"
                  name="image"
                  label="Bild"
                  value={this.state.image}
                  onChange={this.onChangeImage}
                  fullWidth
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="primary"
                  onClick={() => this.addGame(false)}
                >
                  Spiel hinzufügen
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.submit}
                  variant="contained"
                  color="gainsboro"
                  onClick={() => this.addGame(true)}
                >
                  Spiel hinzufügen & Neu
                </Button>
              </Grid>
            </Grid>
          </form>
        </React.Fragment>
      );
    }
  }
}

export default withStyles(useStyles)(AddGame);
