import React from "react";
import { useEffect, useState } from "react";
import CollectionDataService from "../services/collection.service";
import NotificationDataService from "../services/notification.service";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import { alpha, useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { handleResponseAxios } from "../helpers/handle-response";
import moment from "moment";
import { useTranslation } from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HelpBox from "src/components/HelpBox.js";
import { useGameSearchStore, useHeaderStore } from '../store/store';

import {
  AppBar,
  Badge,
  ClickAwayListener,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Toolbar,
  Typography,
  InputBase,
  InputAdornment,
  Select,
  Container,
  Paper,
  Popper,
} from '@mui/material';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: "1432px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "240px",
      maxWidth: "calc(99.9% - 240px)",
    },
    [theme.breakpoints.up("xxl")]: {
      paddingLeft: "24px",
    },
    [theme.breakpoints.up("xxxl")]: {
      marginLeft: "auto",
      maxWidth: "1872px",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  pageTitle: {
    position: "relative",
    marginRight: "auto",
  },
  search: {
    position: "relative",
    /*borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },*/
    marginLeft: "auto",
    marginRight: "auto",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  notificationSettingsIcon: {
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
  },
  inputRoot: {
    color: "inherit",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.between("xs", "sm")]: {
      maxWidth: '80%'
    },    
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "calc(100% + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "60vw",
      "&:focus": {
        width: "calc(60vw + 2ch)",
      },
    },
    [theme.breakpoints.up("md")]: {
      width: "30vw",
      "&:focus": {
        width: "calc(30vw + 2ch)",
      },
    },
    collectionSelection: {
      fontSize: "14px",
    },
  },
  popupPaper: {
    width: "100%",
    maxWidth: "50ch",
    height: "100%",
    maxHeight: "500px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "35ch",
    },
  },
  notificationUnread: {
    backgroundColor: "#547890",
    color: "#fff",
    "&:hover": {
      color: "#000",
    },
  },
  toolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [init, setInit] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [notificationList, setNotificationList] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [openHelpBox, setOpenHelpBox] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    setStateValue,
    location,
    pageTitle,
    forceCollectionUpdate,
    socket,
    help,
  } = props;
  const username = props.currentUser?.username;
  const open = Boolean(anchorEl);
  const id = open ? "notification-popup" : undefined;

  const gameSearch = useGameSearchStore(state => state);
  const updateSearchString = useGameSearchStore(state => state.updateSearchString);
  const resetSearchString = useGameSearchStore(state => state.resetSearchString);
  const headerStore = useHeaderStore(state => state);
  
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleOpenHelpBox = () => {
    setOpenHelpBox(true);
  };

  const retrieveCollections = () => {
    CollectionDataService.getAllForSelection()
      .then((response) => {
        const collections = [];
        response.data.data.owned.map((owned) => (
          collections.push(owned)
        ));
        response.data.data.access.map((access) => (
          collections.push(access)
        ));
        setCollectionList(collections);
        setInit(false);
        props.setStateValue("forceCollectionUpdate", false);
        if (localStorage.getItem("collectionID") === "null") {
          localStorage.setItem(
            "collectionID",
            response.data.data.owned[0]?.collection_id || null
          );
        }
        if (!collections.find(collection => collection.collection_id === Number(localStorage.getItem("collectionID")))) {
          localStorage.setItem(
            "collectionID",
            response.data.data.owned[0]?.collection_id || null
          );
          props.history.push("/");
        }
      })
      .catch((err) => {
        handleResponseAxios(err.response);
      });
  };

  const collectionChange = (e) => {
    localStorage.setItem("collectionID", e.target.value);
    props.history.push("/");
  };

  const handleNotificationRead = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!anchorEl) {
      NotificationDataService.getUnreadNotifications().then((response) => {
        setNotificationList(response.data.notifications);
      });
    }
    setNotificationCount(null);
    let title = pageTitle ? `Spieleordner - ${pageTitle}` : "Spieleordner";
    document.title = notificationCount
      ? `(${notificationCount}) ${title}`
      : title || "Spieleordner";
  };

  useEffect(() => {
    retrieveCollections();
  }, [location]);

  useEffect(() => {
    if (forceCollectionUpdate === true) {
      retrieveCollections();
    }
  }, [forceCollectionUpdate]);

  useEffect(() => {
    let title = pageTitle ? `Spieleordner - ${pageTitle}` : "Spieleordner";
    document.title = notificationCount
      ? `(${notificationCount}) ${title}`
      : title || "Spieleordner";
  }, [notificationCount]);

  useEffect(() => {
    let title = pageTitle ? `Spieleordner - ${pageTitle}` : "Spieleordner";
    document.title = notificationCount
      ? `(${notificationCount}) ${title}`
      : title || "Spieleordner";
  }, [pageTitle]);

  useEffect(() => {
    socket &&
      socket.on("newNotification", function (data) {
        setNotificationCount(data);
      });
  }, [socket]);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <img
          src="/logo192.png"
          alt="logo"
          style={{
            display: "block",
            margin: "auto",
            width: "192px",
            height: "192px",
          }}
        />
      </div>
      <Divider />
      <List>
        {[
          {
            titel: t("collection.collection_one"),
            secondary: "",
            route: "/",
          },
          {
            titel: "Partieverlauf",
            secondary: "",
            route: "/matchHistory",
          },
          {
            titel: "Leihverlauf",
            secondary: "",
            route: "/lendHistory",
          },

          {
            titel: "Erweiterte Suche",
            secondary: "",
            route: "/search",
          },
          {
            titel: "Statistik",
            secondary: "",
            route: `/statistic/collection/${localStorage.getItem("collectionID")}`,
          },
          {
            titel: "Meine Statistik",
            secondary: "",
            route: `/statistic/account`,
          } /*
      {
        titel: 'Termine',
        secondary: '',
        route: '/events'
      },*/,
          {
            titel: "Archiv",
            secondary: "",
            route: "/archive",
          },
          {
            titel: t("setting.setting_other"),
            secondary: username,
            route: "/settings",
          },
        ].map((menuObject, index) => (
          <ListItem
            button
            onClick={mobileOpen ? handleDrawerToggle : undefined}
            component={Link}
            to={menuObject.route}
            key={menuObject.titel}
          >
            <ListItemText
              primary={menuObject.titel}
              secondary={menuObject.secondary}
              key={menuObject.titel}
            />
          </ListItem>
        ))}
        <Divider />
        <ListItem>
          <ListItemText secondary="Aktuelle Sammlung" />
        </ListItem>
        <ListItem>
          <Select
            native
            className={classes.collectionSelection}
            value={localStorage.getItem("collectionID")}
            fullWidth
            onChange={collectionChange}
            variant="standard"
          >
            {collectionList?.length > 0 &&
              collectionList.map((collection, index) => (
                <option
                  value={collection.collection_id}
                  key={collection.collection_name}
                >
                  {collection.collection_name}
                </option>
              ))}
          </Select>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      {openHelpBox && <HelpBox func={headerStore.help} handleHelpBoxClose={() => setOpenHelpBox(false)} /> }
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Container className={classes.container}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            {(location.pathname === "/" ||
              location.pathname === "/archive") && (
              <>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Suchen..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    value={gameSearch.searchString}
                    onChange={(e) => updateSearchString(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end" sx={{ color: "inherit" }}>
                        <IconButton
                          aria-label="reset search"
                          color="inherit"
                          onClick={resetSearchString}
                          size="large"
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {i18n.exists(headerStore.help || undefined) && (
                    <IconButton
                      color="inherit"
                      edge="end"
                      size="small"
                      onClick={handleOpenHelpBox}
                      sx={{ display: "contents", padding: 0 }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  )}
                </div>
              </>
            )}
            {location.pathname !== "/" && location.pathname !== "/archive" && (
              <>
                <IconButton
                  aria-label="back"
                  color="inherit"
                  className={classes.goBack}
                  onClick={props.history.goBack}
                  size="small"
                >
                  <ChevronLeftIcon />
                </IconButton>
                <Typography variant="h6" className={classes.pageTitle}>
                  {" "}
                  {t(headerStore.title)}{" "}
                  {i18n.exists(headerStore.help || undefined) && (
                    <IconButton
                      color="inherit"
                      edge="end"
                      size="small"
                      onClick={handleOpenHelpBox}
                      sx={{ display: "contents", padding: 0 }}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  )}
                </Typography>
              </>
            )}

            <IconButton
              aria-label="notification"
              color="inherit"
              className={classes.goBack}
              onClick={handleNotificationRead}
              size="small"
            >
              <Badge
                badgeContent={notificationCount}
                color="error"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-end"
              disablePortal
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper className={classes.popupPaper}>
                  <List>
                    <ListSubheader>
                      {t("notification.notification_other")}
                      <IconButton
                        className={classes.notificationSettingsIcon}
                        aria-label="notification-settings"
                        color="inherit"
                        edge="end"
                        onClick={handleClickAway}
                        component={Link}
                        to="/settings/notifications"
                        key="notification-settings"
                        size="small"
                      >
                        <SettingsOutlinedIcon />
                      </IconButton>
                      <Divider />
                    </ListSubheader>
                    {notificationList?.length > 0 &&
                      notificationList.map((notification, index) => (
                        <>
                          <ListItem
                            button
                            onClick={handleClickAway}
                            component={Link}
                            to={notification.url}
                            className={
                              notification.seen_at
                                ? classes.notificationRead
                                : classes.notificationUnread
                            }
                          >
                            <ListItemText
                              primary={notification.text}
                              secondary={moment(notification.created_at).format(
                                "DD.MM.YYYY HH:mm"
                              )}
                            />
                          </ListItem>{" "}
                          <Divider />
                        </>
                      ))}
                    {notificationList?.length === 0 && (
                      <ListItem>
                        <ListItemText primary="Noch keine Benachrichtigung erhalten" />
                      </ListItem>
                    )}
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Container>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default withRouter(ResponsiveDrawer);
