import axios from "axios";
import { authHeader } from "./helpers/auth-header";
import { useAlertMessageStore } from './store/store';

const base_url = process.env.REACT_APP_BASE_URL;

//"Content-type": "application/json"
const http = axios.create({
  baseURL: `${base_url}/api`,
  headers: { "Content-type": "application/json" },
});

http.interceptors.request.use(function (config) {
  // Do something before request is sent
  let authKey = authHeader();
  config.headers["Authorization"] = authKey;
  return config;
});

http.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error.response?.data?.error?.code) {
    useAlertMessageStore.setState({ alertMessage: { severity: "error", message: `error.${error.response.data.error.code}` } })
    return Promise.reject({ ...error, handled: true})
  }
  return Promise.reject(error)
});

export default http;
