import React, { Component } from "react";
import { authenticationService } from '../../../auth/authentication.service';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import SessionDataService from "../../../services/session.service";
import withStyles from '@mui/styles/withStyles';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import UpdateIcon from '@mui/icons-material/Update';
import { history } from '../../../helpers/history';
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { compose } from 'recompose';
import { withTranslation } from "react-i18next";
import { useHeaderStore } from "src/store/store";
import { FlexBox } from "src/components/flex-box";
import {
  Pagination,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
} from "@mui/material";


moment.locale('de')

const useStyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chevron: {
    textAlign: 'right'
  },
  delete: {
    color: 'red',
  },
  activeSession: {
    color: '#00a400',
    fontWeight: 'bold',
  },
  icon: {
    verticalAlign: 'middle',
  },
  contentList: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  contentBox: {
    width: '100%',
    maxWidth: 450,
  }
});


class Sessions extends Component {

  constructor(props) {
    super(props);    
    this.deleteSession = this.deleteSession.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "session.session_other", help: "help.sessions"})

     this.state = {
       sessionError: "",
       sessions: [],

       currentPage: 1
    }
  }
 
  componentDidMount() {
    this.retrieveSessions();
  }  

retrieveSessions() {
  SessionDataService.getAll()
    .then(response => {
      this.setState({
        sessions: response.data.data,
      })
      this.props.setStateValue('isLoading', false)
    })
    .catch(err => console.log(err))
}

deleteSession(session) {
  if(session.current_session) {
    authenticationService.logout();
    history.push('/');
  } else {
    SessionDataService.delete(session.id)
      .then(response => {
        this.retrieveSessions();
        this.props.setStateValue('alertMessage', { severity: 'success', message: 'Die Session wurde erfolgreich gelöscht', key: Math.random() })
      })
      .catch(e => {
        this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Anlegen der Session', errorList: e.response.data.data, key: Math.random() })
      })
    }
}

handleChangePage(event, value) {
  this.setState({
    currentPage: value,
  });
};


render() {
  const { sessions, currentPage } = this.state
  const { classes, t } = this.props;

  
  const itemsPerPage = 5;
  const cntPages = Math.ceil(sessions.length/itemsPerPage)
  const indexOfLastPost = currentPage * itemsPerPage;
  const indexOfFirstPost = indexOfLastPost - itemsPerPage;


  return (
    <React.Fragment>
    <AccountDashboardLayout>
    <Box className={classes.contentBox}>
      <List component="nav" className={classes.contentList}>
        {sessions.slice(indexOfFirstPost,indexOfLastPost).map((session) => (
          <>
          <ListItem>
            <ListItemText primary={session.platform} secondary={<>{session.browser} {session.current_session ? (<span> &#183;<span className={classes.activeSession}> {t('thisDevice')}</span></span>): ''}<br /> 
            <UpdateIcon className={classes.icon} /> {moment(session.last_seen).format(t('date.session'))} <br />
            <AddModeratorIcon className={classes.icon} /> {moment(session.created_at).format(t('date.session'))} <br />
            </>} />
            <ListItemSecondaryAction>
            <IconButton
              edge="end"
              color="error"
              onClick={() => this.deleteSession(session)}
              size="large">
              <RemoveModeratorIcon />
            </IconButton>
          </ListItemSecondaryAction>
          </ListItem>
          <Divider />
          </>
        ))}
      </List>
      <FlexBox justifyContent="center" mt={5}>
          <Pagination
            count={cntPages}
            color="primary"
            variant="outlined"
            onChange={this.handleChangePage}
          />
        </FlexBox>
      </Box>
      </AccountDashboardLayout>
      </React.Fragment>
  );
  
}
}

export default compose(withTranslation(),withStyles(useStyles))(Sessions)