import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import GameDataService from "../../services/game.service";
import LocationDataService from "../../services/location.service";
import CategoryDataService from "../../services/category.service";
import moment from "moment";
import AlertDialog from "../../components/AlertDialog";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import CasinoIcon from "@mui/icons-material/Casino";
import { Formik } from "formik";
import { replaceEmptyString } from "../../helpers/replaceEmptyString";
import { object, ref, string } from "yup";
import { useHeaderStore } from "src/store/store";

import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Rating,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";

const useStyles = (theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    display: "block",
    margin: "auto",
  },
  search: {
    marginBottom: "10px",
    background: "white",
    border: "1px solid black",
  },
  resultPaper: {
    marginBottom: "5px",
  },
  picture: {
    "& img": {
      height: "10vh",
    },
    MuiSnackbar: {
      paddingTop: "64px",
    },
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

class EditGame extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteGame = this.handleDeleteGame.bind(this);
    this.handleArchiveGame = this.handleArchiveGame.bind(this);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this);
    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "game.modifyGame_one",
      help: "help.modifyGame",
    });

    this.state = {
      game: {},
      locations: [],
      categories: [],
      maingames: [],

      showMessage: props.showMessage,

      openSpeedDial: false,

      metaInfo: [],
      loading: true,
    };
  }

  componentDidMount() {
    const id = this.props.match.params.game_id;
    this.retrieveGameDetails(id);
    this.retrieveCategories();
    this.retrieveLocations();
    this.retrieveMainGames();
  }

  static getDerivedStateFromProps(props, state) {
    return {
      showMessage: props.showMessage,
    };
  }

  retrieveGameDetails(id) {
    GameDataService.get(id)
      .then((response) => {
        this.setState({
          game: {
            id: response.data.data.game.id,
            collection_id: response.data.data.game.collection_id,
            title: response.data.data.game.title,
            ean: response.data.data.game.ean,
            designer: response.data.data.game.designer,
            publisher: response.data.data.game.publisher,
            minPlayers: response.data.data.game.min_players,
            maxPlayers: response.data.data.game.max_players,
            minAge: response.data.data.game.min_age,
            gameDuration: response.data.data.game.game_duration,
            datePurchased: moment(
              response.data.data.game.date_purchased
            ).isValid()
              ? moment(response.data.data.game.date_purchased).format(
                  "YYYY-MM-DD"
                )
              : null,
            dateReleased: response.data.data.game.date_released,
            coop: response.data.data.game.coop,
            recordPoints: response.data.data.game.recordPoints,
            selectedMaingame: {
              id: response.data.data.game.maingame_id,
              title: response.data.data.game.maingame_title,
            },
            description: response.data.data.game.description,
            rating: response.data.data.game.rating,
            selectedCategories: response.data.data.categories,
            selectedLocation: {
              id: response.data.data.game.location_id,
              location_name: response.data.data.game.location_name,
            },
            image: response.data.data.game.image,
            bgdbID: response.data.data.game.bgdb_id,
            archived: response.data.data.game.archived,
            createdAt: moment(response.data.data.game.created_at).isValid()
              ? moment(response.data.data.game.created_at).format("DD.MM.YYYY")
              : "",
            updatedAt: moment(response.data.data.game.updated_at).isValid()
              ? moment(response.data.data.game.updated_at).format("DD.MM.YYYY")
              : "",
          },

          loading: false,
          metaInfo: response.data.meta,
        });
        const ids = [];
        response.data.data.categories.map(
          (category, index) => (
            ids.push({ id: category.id }),
            this.setState({
              categoriesID: ids,
            })
          )
        );
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: `error.${e.response.data.error.code}`,
          key: Math.random(),
        });
      });
  }

  retrieveCategories() {
    CategoryDataService.getAll()
      .then((response) => {
        this.setState({
          categories: response.data.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveLocations() {
    LocationDataService.getAll(this.collection_id)
      .then((response) => {
        this.setState({
          locations: response.data.data,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrieveMainGames() {
    GameDataService.getMainGames(this.collection_id)
      .then((response) => {
        this.setState({
          maingames: response.data.data.filter(
            (maingame) =>
              maingame.id !== parseInt(this.props.match.params.game_id)
          ),
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleDeleteGame() {
    this.setState({
      deleteGame: true,
    });
  }

  handleArchiveGame() {
    this.setState({
      archiveGame: true,
    });
  }

  alertDialog(title) {
    const handleDialogClose = () => {
      this.setState({
        deleteGame: false,
      });
    };

    const handleDialogConfirm = () => {
      GameDataService.delete(
        this.state.game.id,
        this.collection_id,
        this.state.game.title
      )
        .then((response) => {
          handleDialogClose();
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Das Spiel wurde erfolgreich gelöscht",
            key: Math.random(),
          });
          this.props.history.push(`/`);
        })
        .catch((e) => {
          handleDialogClose();
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: `error.${e.response.data.error.code}`,
            key: Math.random(),
          });
        });
    };

    return (
      <AlertDialog
        title={title + " wirklich löschen?"}
        description="Es werden alle Statistiken und Historien in Verbindungen mit diesem Spiel gelöscht."
        cancel="Abbrechen"
        confirm="Löschen"
        handleDialogClose={handleDialogClose}
        handleDialogConfirm={handleDialogConfirm}
      />
    );
  }

  alertDialogArchive(title) {
    const handleDialogClose = () => {
      this.setState({
        archiveGame: false,
      });
    };

    const handleDialogConfirm = () => {
      const data = {
        id: this.state.game.id,
        title: this.state.game.title,
        collection_id: this.collection_id,
        archive: !this.state.game.archived,
      };
      GameDataService.archive(data)
        .then((response) => {
          handleDialogClose();
          const message = this.state.game.archived
            ? "verschoben"
            : "archiviert";
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Das Spiel wurde erfolgreich " + message,
            key: Math.random(),
          });
          this.props.history.push(`/`);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    return (
      <AlertDialog
        title={
          this.state.game.archived
            ? title + " wirklich verschieben?"
            : title + " wirklich archivieren?"
        }
        description={
          this.state.game.archived
            ? "Das Spiel wird in die aktive Sammlung verschoben."
            : "Das Spielt wird in das Archiv verschoben. Alle Statistiken und Historien in Verbindungen mit diesem Spiel bleiben erhalten."
        }
        cancel="Abbrechen"
        confirm={this.state.game.archived ? "Verschieben" : "Archivieren"}
        handleDialogClose={handleDialogClose}
        handleDialogConfirm={handleDialogConfirm}
      />
    );
  }

  handleChangeSpeedDial() {
    this.setState({
      openSpeedDial: !this.state.openSpeedDial,
    });
  }

  _handleSubmit = ({ values, setSubmitting, resetForm }) => {
    var data = replaceEmptyString(values, null);
    GameDataService.update(
      this.state.game.id,
      { game: data },
      this.collection_id
    )
      .then((response) => {
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Das Spiel wurde erfolgreich bearbeitet",
          key: Math.random(),
        });
        this.props.history.push(`/games/${response.data.id}`);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: `error.${e.response.data.error.code}`,
            key: Math.random(),
          });
          this.props.history.push("/");
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Bearbeiten des Spiels",
            key: Math.random(),
          });
        }
      });
  };

  render() {
    const {
      game,
      loading,
      metaInfo,
      openSpeedDial,
      deleteGame,
      archiveGame,
      maingames,
      locations,
      categories,
    } = this.state;
    const { classes } = this.props;
    const editable = game.collection_id == this.collection_id;

    if (
      !(
        editable &&
        (metaInfo.privileges.game_modify || metaInfo.privileges.owner)
      ) &&
      !loading
    ) {
      if (!metaInfo.privileges.game_modify) {
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: "error.2003",
          key: Math.random(),
        });
      }
      return <Redirect to={{ pathname: `/` }} />;
    }

    return (
      <>
        {deleteGame ? this.alertDialog(game.title) : null}
        {archiveGame ? this.alertDialogArchive(game.title) : null}
        {!loading && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              title: game.title,
              maingame: game.selectedMaingame,
              ean: game.ean,
              designer: game.designer,
              publisher: game.publisher,
              minPlayers: game.minPlayers,
              maxPlayers: game.maxPlayers,
              minAge: game.minAge,
              gameDuration: game.gameDuration,
              datePurchased: game.datePurchased,
              dateReleased: game.dateReleased,
              location: game.selectedLocation,
              categories: game.selectedCategories,
              coop: game.coop,
              recordPoints: game.recordPoints,
              description: game.description,
              rating: game.rating,
              image: game.image,
              bgdbID: game.bgdbID,
            }}
            validationSchema={object().shape({
              title: string().required("Bitte einen Titel eingeben"),
            })}
            onSubmit={({ ...values }, { setSubmitting, resetForm }) =>
              this._handleSubmit({
                values,
                setSubmitting,
                resetForm,
              })
            }
            render={(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isValid,
                isSubmitting,
                dirty,
              } = props;
              return (
                <>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.title && errors.title)}
                          variant="standard"
                        >
                          <TextField
                            id="title"
                            name="title"
                            label="Titel"
                            type="text"
                            variant="standard"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.title && errors.title)}
                          />
                          <FormHelperText
                            error={Boolean(touched.title && errors.title)}
                          >
                            {touched.title && errors.title ? errors.title : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.maingame && errors.maingame)}
                          variant="standard"
                        >
                          <Autocomplete
                            id="maingame"
                            name="maingame"
                            options={maingames}
                            getOptionLabel={(option) => option.title}
                            defaultValue={
                              values.maingame?.id !== null
                                ? values.maingame
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Hauptspiel"
                                variant="standard"
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue(
                                "maingame",
                                value !== null ? value : null
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.maingame && errors.maingame)}
                            fullWidth
                          />
                          <FormHelperText
                            error={Boolean(touched.maingame && errors.maingame)}
                          >
                            {touched.maingame && errors.maingame
                              ? errors.maingame
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.ean && errors.ean)}
                          variant="standard"
                        >
                          <TextField
                            id="ean"
                            name="ean"
                            label="EAN"
                            type="text"
                            variant="standard"
                            value={values.ean}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.ean && errors.ean)}
                          />
                          <FormHelperText
                            error={Boolean(touched.ean && errors.ean)}
                          >
                            {touched.ean && errors.ean ? errors.ean : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.designer && errors.designer)}
                          variant="standard"
                        >
                          <TextField
                            id="designer"
                            name="designer"
                            label="Autor"
                            type="text"
                            variant="standard"
                            value={values.designer}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.designer && errors.designer)}
                          />
                          <FormHelperText
                            error={Boolean(touched.designer && errors.designer)}
                          >
                            {touched.designer && errors.designer
                              ? errors.designer
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.publisher && errors.publisher)}
                          variant="standard"
                        >
                          <TextField
                            id="publisher"
                            name="publisher"
                            label="Publisher"
                            type="text"
                            variant="standard"
                            value={values.publisher}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.publisher && errors.publisher
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.publisher && errors.publisher
                            )}
                          >
                            {touched.publisher && errors.publisher
                              ? errors.publisher
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} lg={2}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.minPlayers && errors.minPlayers
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="minPlayers"
                            name="minPlayers"
                            label="Min. Spieler"
                            type="number"
                            variant="standard"
                            value={values.minPlayers}
                            onChange={handleChange}
                            inputProps={{ min: 0 }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.minPlayers && errors.minPlayers
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.minPlayers && errors.minPlayers
                            )}
                          >
                            {touched.minPlayers && errors.minPlayers
                              ? errors.minPlayers
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} lg={2}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.maxPlayers && errors.maxPlayers
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="maxPlayers"
                            name="maxPlayers"
                            label="Max. Spieler"
                            type="number"
                            variant="standard"
                            value={values.maxPlayers}
                            onChange={handleChange}
                            inputProps={{ min: 0 }}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.maxPlayers && errors.maxPlayers
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.maxPlayers && errors.maxPlayers
                            )}
                          >
                            {touched.maxPlayers && errors.maxPlayers
                              ? errors.maxPlayers
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} lg={2}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.minAge && errors.minAge)}
                          variant="standard"
                        >
                          <TextField
                            id="minAge"
                            name="minAge"
                            label="Min. Alter"
                            type="number"
                            variant="standard"
                            value={values.minAge}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.minAge && errors.minAge)}
                          />
                          <FormHelperText
                            error={Boolean(touched.minAge && errors.minAge)}
                          >
                            {touched.minAge && errors.minAge
                              ? errors.minAge
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.gameDuration && errors.gameDuration
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="gameDuration"
                            name="gameDuration"
                            label="Spieldauer"
                            type="number"
                            variant="standard"
                            value={values.gameDuration}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.gameDuration && errors.gameDuration
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.gameDuration && errors.gameDuration
                            )}
                          >
                            {touched.gameDuration && errors.gameDuration
                              ? errors.gameDuration
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.datePurchased && errors.datePurchased
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="datePurchased"
                            name="datePurchased"
                            label="Anschaffungsdatum"
                            type="date"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            value={values.datePurchased}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.datePurchased && errors.datePurchased
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.datePurchased && errors.datePurchased
                            )}
                          >
                            {touched.datePurchased && errors.datePurchased
                              ? errors.datePurchased
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.dateReleased && errors.dateReleased
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="dateReleased"
                            name="dateReleased"
                            label="Veröffentlicht"
                            type="number"
                            variant="standard"
                            value={values.dateReleased}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.dateReleased && errors.dateReleased
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.dateReleased && errors.dateReleased
                            )}
                          >
                            {touched.dateReleased && errors.dateReleased
                              ? errors.dateReleased
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.categories && errors.categories
                          )}
                          variant="standard"
                        >
                          <Autocomplete
                            multiple
                            id="categories"
                            options={categories}
                            value={values.categories}
                            onChange={(e, value) => {
                              setFieldValue(
                                "categories",
                                value !== null ? value : null
                              );
                            }}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.category_name}
                            isOptionEqualToValue={(option, value) =>
                              option.category_name === value.category_name
                            }
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                  }
                                  checkedIcon={
                                    <CheckBoxIcon fontSize="small" />
                                  }
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.category_name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Genre"
                                fullWidth
                              />
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.categories && errors.categories
                            )}
                          >
                            {touched.categories && errors.categories
                              ? errors.categories
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.location && errors.location)}
                          variant="standard"
                        >
                          <Autocomplete
                            id="location"
                            name="location"
                            options={locations}
                            getOptionLabel={(option) => option.location_name}
                            defaultValue={
                              values.location?.id !== null
                                ? values.location
                                : null
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Standort"
                                variant="standard"
                              />
                            )}
                            onChange={(e, value) => {
                              setFieldValue(
                                "location",
                                value !== null ? value : null
                              );
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.location && errors.location)}
                            fullWidth
                          />
                          <FormHelperText
                            error={Boolean(touched.location && errors.location)}
                          >
                            {touched.location && errors.location
                              ? errors.location
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2} lg={1}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.coop && errors.coop)}
                          variant="standard"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                name="coop"
                                checked={values.coop}
                                value={values.coop}
                                onChange={(e, value) => {
                                  setFieldValue("coop", value);
                                }}
                              />
                            }
                            label="Koop"
                            labelPlacement="top"
                          />

                          <FormHelperText
                            error={Boolean(touched.coop && errors.coop)}
                          >
                            {touched.coop && errors.coop ? errors.coop : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3} md={4} lg={2}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.recordPoints && errors.recordPoints
                          )}
                          variant="standard"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                name="recordPoints"
                                checked={values.recordPoints}
                                value={values.recordPoints}
                                onChange={(e, value) => {
                                  setFieldValue("recordPoints", value);
                                }}
                              />
                            }
                            label="Punkte erfassen"
                            labelPlacement="top"
                          />

                          <FormHelperText
                            error={Boolean(
                              touched.recordPoints && errors.recordPoints
                            )}
                          >
                            {touched.recordPoints && errors.recordPoints
                              ? errors.recordPoints
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.rating && errors.rating)}
                          variant="standard"
                        >
                          <Typography component="legend">Bewertung</Typography>
                          <Rating
                            name="rating"
                            precision={0.5}
                            value={values.rating}
                            onChange={handleChange}
                            icon={<CasinoIcon color={"primary"} />}
                            emptyIcon={<CasinoIcon />}
                          />
                          <FormHelperText
                            error={Boolean(touched.rating && errors.rating)}
                          >
                            {touched.rating && errors.rating
                              ? errors.rating
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={6} lg={3}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.bgdbID && errors.bgdbID)}
                          variant="standard"
                        >
                          <TextField
                            id="bgdbID"
                            name="bgdbID"
                            label="BoardGameGeek-ID"
                            type="text"
                            variant="standard"
                            value={values.bgdbID}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.bgdbID && errors.bgdbID)}
                          />
                          <FormHelperText
                            error={Boolean(touched.bgdbID && errors.bgdbID)}
                          >
                            {touched.bgdbID && errors.bgdbID
                              ? errors.bgdbID
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          variant="standard"
                        >
                          <TextField
                            id="description"
                            name="description"
                            label="Beschreibung"
                            type="text"
                            variant="standard"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          >
                            {touched.description && errors.description
                              ? errors.description
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          margin="dense"
                          error={Boolean(touched.image && errors.image)}
                          variant="standard"
                        >
                          <TextField
                            id="image"
                            name="image"
                            label="Bild"
                            type="text"
                            variant="standard"
                            value={values.image}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.image && errors.image)}
                          />
                          <FormHelperText
                            error={Boolean(touched.image && errors.image)}
                          >
                            {touched.image && errors.image ? errors.image : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={Boolean(!isValid || isSubmitting || !dirty)}
                          className={classes.submit}
                        >
                          {"Spiel speichern"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <SpeedDial
                    ariaLabel="Spielmenü"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
                    open={openSpeedDial}
                    onOpen={this.handleChangeSpeedDial}
                    onClose={this.handleChangeSpeedDial}
                  >
                    {(metaInfo.privileges.game_delete === 1 ||
                      metaInfo.privileges.owner === 1) && (
                      <SpeedDialAction
                        key="Löschen"
                        icon={<DeleteIcon color="error" />}
                        tooltipTitle="Löschen"
                        onClick={this.handleDeleteGame}
                      />
                    )}
                    <SpeedDialAction
                      key="Archivieren"
                      icon={
                        this.state.game.archived ? (
                          <UnarchiveIcon color="primary" />
                        ) : (
                          <ArchiveIcon color="primary" />
                        )
                      }
                      tooltipTitle={
                        this.state.game.archived ? "Verschieben" : "Archivieren"
                      }
                      onClick={this.handleArchiveGame}
                    />
                  </SpeedDial>
                </>
              );
            }}
          />
        )}
      </>
    );
  }
}

export default withStyles(useStyles)(EditGame);
