import React, { Component } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import withStyles from '@mui/styles/withStyles';
import CollectionDataService from "../../../services/collection.service";
import UserDataService from "../../../services/user.service";
import { compose } from 'recompose'
import { withTranslation } from "react-i18next";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { useHeaderStore } from "src/store/store";


import {
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Container,
  Box,
} from '@mui/material';


const useStyles = theme => ({
    submit: {
      display: 'block',
      margin: 'auto',
      marginTop: '16px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    contentBox: {
      width: '100%',
      maxWidth: 450,
    }
  })


class AddCollection extends Component {
  constructor(props) {
    super(props);
    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "collection.addCollection", help: "help.addCollection"})

    this.checkAccountType = this.checkAccountType.bind(this)

    this.state = {
      collection_title: "",

    }
  }

  componentWillMount(){
    this.checkAccountType();
  }

  componentDidMount() {
    this.props.setStateValue('isLoading', false)
  }

  checkAccountType() {
    UserDataService.checkAccountType("addCollection", 0)
    .then(response => {
    })
    .catch(e => {
      this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.message}`, key: Math.random() })
      this.props.history.push(`/settings/collections`); 
    })
  }

  _handleSubmit = ({
    collection_title,
    setSubmitting,
    setError,
    resetForm,
  }) => {
    var data = {
      collection_title: collection_title,
    }
    CollectionDataService.create(data)
    .then(response => {
      this.props.setStateValue('forceCollectionUpdate', true)
      this.props.setStateValue('alertMessage', { severity: 'success', message: `success.${response.data.message}`, key: Math.random() })
      this.props.history.push(`/settings/collections`); 
    })
    .catch(e => {
      setSubmitting(false)
      this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.message}`, key: Math.random() })
      console.log(e);
    });
  }

  render() {
    const { classes, t } = this.props; 
    const { collection_title } = this.state;
  
    return (
      <AccountDashboardLayout>
      <Box className={classes.contentBox}>
      <Formik
        enableReinitialize
        initialValues={{
          collection_title: collection_title,
        }}
        validationSchema={Yup.object().shape({
          collection_title: Yup.string().required('Bitte einen Sammlungsnamen eingeben').max(50, 'Der Sammlungsname darf maximal 50 Zeichen lang sein'),
        })}
        onSubmit={(
          { 
            collection_title,
          },
          { setSubmitting, setStatus, setError, resetForm }
        ) =>
          this._handleSubmit({
            collection_title,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          } = props
          return <>       
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
            
            <Container component="main" maxWidth="xs">
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth variant="standard">
                  <TextField
                    id="collection_title"
                    label={t('collection.collectionname')}
                    name="collection_title"
                    value={values.collection_title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.collection_title && errors.collection_title)}
                    autoComplete="off"
                    variant="standard" />
                  <FormHelperText
                    error={Boolean(touched.collection_title && errors.collection_title)}
                  >
                    {touched.collection_title && errors.collection_title
                      ? errors.collection_title
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {t('save')}
                </Button>
              </form>
              </Container>
          </>;
        
        }}
      />
      </Box>
      </AccountDashboardLayout>
    );
  }
}

export default compose(withTranslation(),withStyles(useStyles))(AddCollection)