import * as React from 'react';
import { Alert, Stack, Link as URL } from '@mui/material';


export default function AlertBox(props) {
    const { alerts } = props;

  return (
    <Stack sx={{ margin: 1 }} spacing={1}>
        { alerts && alerts.map((alert, key) => { console.dir(alert)
            return (
      <Alert sx={{ marginLeft: 'auto !important', marginRight: 'auto !important', width: { sm: '90%', lg: '600px' } }} severity={alert.severity} onClose={alert.onClose}>{alert.message} {(alert.actionText &&  alert.actionHref) && <URL href={alert.actionHref} target="_blank" rel="noopener">{alert.actionText}</URL>}</Alert>
            )
        })
    }
    </Stack>
  );
}