import '../css/App.css';
import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import { Backdrop, CircularProgress } from '@mui/material';

const useStyles = theme => ({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
      },
});

class Loading extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;

        return (
            <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default withStyles(useStyles)(Loading)