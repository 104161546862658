import React, { Component } from "react";
import LocationDataService from "../../../../../services/location.service";
import withStyles from "@mui/styles/withStyles";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  TextField,
  Box,
} from '@mui/material';

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chevron: {
    textAlign: "right",
  },
  delete: {
    color: "red",
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,
  },
  mobile: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 112px)",
    borderRight: "1px solid #ccc",
  },
});

class Locations extends Component {
  constructor(props) {
    super(props);
    this.addNewLocation = this.addNewLocation.bind(this);
    this.onChangeNewLocation = this.onChangeNewLocation.bind(this);
    this.onChangeEditLocation = this.onChangeEditLocation.bind(this);
    this.deleteLocation = this.deleteLocation.bind(this);
    this.saveLocation = this.saveLocation.bind(this);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "location.location_other", help: "help.locations"})

    this.state = {
      locationError: "",
      newLocation: "",
      locations: [],
      collection: this.props.match.params.collection_id,
      editLocation: [],
      editLocationError: "",
      privileges: {},
    };
  }

  componentDidMount() {
    this.retrieveLocations(this.state.collection);
  }

  retrieveLocations(collection) {
    LocationDataService.getAll(collection)
      .then((response) => {
        this.setState({
          locations: response.data.data,
          privileges: response.data.meta.privileges,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.history.push(`/`);
        }
      });
  }

  onChangeNewLocation(e) {
    this.setState({
      newLocation: e.target.value,
    });
  }

  addNewLocation() {
    var data = {
      locationName: this.state.newLocation,
      collectionID: this.state.collection,
    };

    if (data.locationName !== "") {
      LocationDataService.create(data)
        .then((response) => {
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Der Standort wurde erfolgreich hinzugefügt",
            key: Math.random(),
          });
          this.setState({
            newLocation: "",
          });
          this.retrieveLocations(this.state.collection);
        })
        .catch((e) => {
          if(e.response.status === 403) {
            this.props.history.push(`/`)
          } else {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: "Fehler beim Hinzufügen des Standortes",
              key: Math.random(),
            });
          }
        });
    } else {
      this.props.setStateValue("alertMessage", {
        severity: "error",
        message: "Fehler beim Hinzufügen des Standortes",
        key: Math.random(),
      });
      this.setState({
        locationError: {
          status: true,
          message: "Der Standort darf nicht leer sein",
        },
      });
    }
  }

  deleteLocation(location_id) {
    LocationDataService.delete(location_id, this.state.collection)
      .then((response) => {
        this.retrieveLocations(this.state.collection);
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Der Standort wurde erfolgreich gelöscht",
          key: Math.random(),
        });
      })
      .catch((e) => {
        if(e.response.status === 403) {
          this.props.history.push(`/`)
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Hinzufügen des Standortes",
            key: Math.random(),
          });
        }
      });
  }

  editLocation(location) {
    this.setState({
      editLocation: {
        id: location.id,
        name: location.location_name,
      },
    });
  }

  onChangeEditLocation(e) {
    this.setState({
      editLocation: {
        id: this.state.editLocation.id,
        name: e.target.value,
      },
    });
  }

  saveLocation() {
    var data = {
      locationName: this.state.editLocation.name,
      collectionID: this.state.collection,
    };

    if (data.locationName !== "") {
      LocationDataService.update(this.state.editLocation.id, data)
        .then((response) => {
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Der Standort wurde erfolgreich bearbeitet",
            key: Math.random(),
          });
          this.setState({
            editLocation: []
          });
          this.retrieveLocations(this.state.collection);
        })
        .catch((e) => {
          if(e.response.status === 403) {
            this.props.history.push(`/`)
          } else {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: "Fehler beim Hinzufügen des Standortes",
              key: Math.random(),
            });
          }
        });
    } else {
      this.props.setStateValue("alertMessage", {
        severity: "error",
        message: "Fehler beim Bearbeiten des Standortes",
        key: Math.random(),
      });
      this.setState({
        editLocationError: {
          status: true,
          message: "Der Standort darf nicht leer sein.",
        },
      });
    }
  }

  render() {
    const { locations, newLocation, editLocation, privileges } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AccountDashboardLayout>
          <Box className={classes.contentBox}>
            <List component="nav">
              {privileges.owner === 1 ||
              privileges.collectiondetail_modify === 1
                ? locations.map((location) =>
                    location.id === editLocation.id ? (
                      <ListItem>
                        <TextField
                          value={editLocation.name}
                          onChange={this.onChangeEditLocation}
                          error={this.state.editLocationError.status}
                          helperText={this.state.editLocationError.message}
                          fullWidth
                          variant="standard"
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={this.saveLocation}
                            size="large"
                          >
                            <CheckIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ) : (
                      <ListItem>
                        <ListItemText primary={location.location_name} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={() => this.editLocation(location)}
                            size="large"
                          >
                            <EditIcon />
                          </IconButton>
                          {(privileges.collectiondetail_delete === 1) && (
                          <IconButton
                            edge="end"
                            color="error"
                            onClick={() => this.deleteLocation(location.id)}
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                          )}
                        </ListItemSecondaryAction>
                      </ListItem>
                    )
                  )
                : locations.map((location) => (
                    <ListItem>
                      <ListItemText primary={location.location_name} />
                    </ListItem>
                  ))}
              {(privileges.collectiondetail_insert === 1) && (
                <>
                  <Divider />
                  <ListItem>
                    <TextField
                      id="newLocation"
                      name="newLocation"
                      label="Neuer Standort"
                      value={newLocation}
                      onChange={this.onChangeNewLocation}
                      error={this.state.locationError.status}
                      helperText={this.state.locationError.message}
                      fullWidth
                      variant="standard"
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={this.addNewLocation}
                        size="large"
                      >
                        <CheckIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>{" "}
                </>
              )}
            </List>
          </Box>
        </AccountDashboardLayout>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Locations);
