import React, { Component } from 'react'
import { authenticationService } from '../../auth/authentication.service';
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Link,
  Box,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import UserDataService from "../../services/user.service";

function Copyright() {
  return (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://spieleordner.de/">
      Spieleordner
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
  );
  }

const useStyles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })


class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.props.setStateValue('isLoading', true)


    // redirect to home if already logged in
    if (authenticationService.currentUserValue) { 
      this.props.history.push("/");
    }
  }
  
  state = {
  }


  componentDidMount() {
    this.props.setStateValue('isLoading', false)
  }


  _handleSubmit = ({
    username,
    setSubmitting,
    setFieldError,
    resetForm,
  }) => {
    var data = {username: username, }; 
    UserDataService.forgotPassword(data)
    .then(
      response => {
        setSubmitting(false)
        this.props.setStateValue('alertMessage', { severity: 'success', message: 'E-Mail zum Ändern des Passwortes wurde erfolgreich verschickt', key: Math.random() })
        this.props.history.push('/'); 
      }
    )
    .catch(
      error => { 
        setSubmitting(false)
        this.props.setStateValue('alertMessage', { severity: 'error', message: error.response.data, key: Math.random() })
      }
    ); 
  }

  render() {
    const { classes } = this.props;
    const {  } = this.state;
    return (
      <Formik
        initialValues={{
          username: '',
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          confirmPassword: '',
          entryCode: '',
        }}
        validationSchema={object().shape({
          username: string().required('Bitte einen Benutzernamen eingeben'),
        })}
        onSubmit={(
          { username },
          { setSubmitting, setStatus, setFieldError, resetForm, }
        ) =>
          this._handleSubmit({
            username,
            setSubmitting,
            setStatus,
            setFieldError,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return <>        
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
            <div className={classes.paper}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="username"
                    label="Benutzername"
                    name="username"
                    variant="outlined"
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.username && errors.username)}
                  />
                  <FormHelperText
                    error={Boolean(touched.username && errors.username)}
                  >
                    {touched.username && errors.username
                      ? errors.username
                      : ''}
                  </FormHelperText>
                </FormControl>
                
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  fullWidth
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {'Neues Passwort anfordern'}
                </Button>
              </form>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
            </Container>
          </>;
          
        }}
      />
    );
  }
}

export default withStyles(useStyles)(ForgotPassword)