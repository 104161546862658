import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";
import OrderDataService from "../services/order.service";
import moment from "moment";
import { useTranslation } from "react-i18next";

import {
  useTheme,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

export default function HelpBox(props) {
  const { func, handleHelpBoxClose } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleHelpBoxClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t(`${func}.title`)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <DialogContentText id="alert-dialog-information">
            {t(`${func}.body`)}
          </DialogContentText>
          <Container maxWidth="xs" sx={{ padding: "30px" }}></Container>
        </DialogContent>
        <DialogActions>
          {/*<Button>FAQ</Button>*/}
          <Button color="primary" variant="contained" onClick={handleHelpBoxClose}  autoFocus>
            Verstanden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
