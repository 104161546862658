import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CollectionDataService from "../../services/collection.service";
import { createFilterOptions } from "@mui/material/Autocomplete";
import PermissionDataService from "../../services/permission.service";
import PlayerDataService from "../../services/player.service";
import { useHeaderStore } from "src/store/store";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";

const filter = createFilterOptions();

const useStyles = (theme) => ({
  submit: {
    display: "block",
    margin: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class AddUser extends Component {
  constructor(props) {
    super(props);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "player.modifyPlayer", help: "help.modifyPlayer"});

    this.state = {
      collection: this.props.match.params.collection_id,
      playerID: this.props.match.params.player_id,
      privileges: {},

      newUserOpen: false,
      newUserName: "",

      playername: "",
      description: "",
      user: "",
      allUsers: [],
    };
  }

  componentDidMount() {
    this.retrieveCollectionDetails(this.state.collection);
    this.retrievePermissions(this.state.collection);
    this.retrievePlayer(this.state.playerID, this.state.collection);
  }

  retrieveCollectionDetails(id) {
    CollectionDataService.get(id).then((response) => {
      if (response.data.meta.privileges.owner !== 1 && response.data.meta.privileges.collectiondetail_modify !== 1) {
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: "Du hast keine Berechtigung, um die Sammlungsdetails dieser Sammlung zu bearbeiten.",
          key: Math.random(),
        });
        this.props.history.push(`/`);
      }
      this.setState({
        collectionName: response.data.data[0].collection_name,
        privileges: response.data.meta.privileges,
      });
    })
    .catch((e) => {
      this.props.history.push(`/`);
    });
  }

  retrievePermissions(collection) {
    PermissionDataService.getAllUsers(collection)
      .then((response) => {
        let users = [];
        response.data.data.map((user) =>
          users.push({ id: user.id, username: user.username })
        );
        this.setState({
          allUsers: users,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  retrievePlayer(player, collection) {
    PlayerDataService.get(player, collection)
      .then((response) => {
        this.setState({
          user: {
            id: response.data.data[0].user_id || null,
            username: response.data.data[0].username || null,
          },
          playername: response.data.data[0].player_name,
          description: response.data.data[0].description || null,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  sortFunction(a, b) {
    if (a['username'] === b['username']) {
      return 0;
    }
    else {
      return (a['username'] < b['username']) ? -1 : 1;
    }
  }

  toggleNewUserOpen(newUserName) {
    this.setState({
      newUserOpen: true,
      newUserName: newUserName,
    });
  }

  newUserDialog(classes) {
    const handleDialogClose = () => {
      this.setState({
        newUserOpen: false,
      });
    };

    const handleNewUserAdd = ({
      username,
      description,
      setSubmitting,
    }) => {
      var data = {
        username: username,
        description: description,
        collectionID: this.state.collection,
      };
      PermissionDataService.create(data)
        .then((response) => {
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Der Benutzer wurde erfolgreich zur Sammlung hinzugefügt",
            key: Math.random(),
          });
          handleDialogClose();
          this.setState({
            user: response.data.data.user,
          });
          this.state.allUsers.push(response.data.data.user);
          this.state.allUsers.sort(this.sortFunction);
        })
        .catch((e) => {
          setSubmitting(false);
          if (e.response.data.data.message) {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: e.response.data.data.message,
              key: Math.random(),
            });
          } else {
            this.props.setStateValue("alertMessage", {
              severity: "error",
              message: "Fehler beim Hinzufügen des Benutzers",
              key: Math.random(),
            });
            console.log(e);
          }
        });
    };

    return (
      <Formik
        enableReinitialize
        initialValues={{
          username: this.state.newUserName,
          description: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required("Bitte einen Benutzernamen eingeben"),
          description: Yup.string().max(
            30,
            "Der Hinweis darf maximal 30 Zeichen lang sein"
          ),
        })}
        onSubmit={(
          { username, description },
          { setSubmitting, setStatus, resetForm }
        ) =>
          handleNewUserAdd({
            username,
            description,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <>
              <CssBaseline />
              {isSubmitting && (
                <Backdrop className={classes.backdrop} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <Dialog
                open="open"
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Benutzer zur Sammlung hinzufügen
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="username"
                            label="Benutzername"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.username && errors.username)}
                            autoComplete="off"
                            variant="standard"
                          />
                          <FormHelperText
                            error={Boolean(touched.username && errors.username)}
                          >
                            {touched.username && errors.username
                              ? errors.username
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="description"
                            label="Hinweis"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            autoComplete="off"
                            variant="standard"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          >
                            {touched.description && errors.description
                              ? errors.description
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="error">
                    Abbrechen
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Speichern
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    );
  }

  _handleSubmit = ({
    playername,
    user,
    description,
    setSubmitting,
  }) => {
    var data = {
      playername: playername,
      user: user,
      description: description,
      collectionID: this.state.collection,
    };
    PlayerDataService.update(this.state.playerID, data)
      .then((response) => {
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Der Spieler wurde erfolgreich bearbeitet",
          key: Math.random(),
        });
        this.props.history.push(
          `/settings/collections/${this.state.collection}/players`
        );
      })
      .catch((e) => {
        setSubmitting(false);
        if(e.response.status === 403) {
          this.props.history.push('/');

        } else if (e.response?.data.data.message) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: e.response.data.data.message,
            key: Math.random(),
          });
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Bearbeiten des Spielers",
            key: Math.random(),
          });
          console.log(e);
        }
      });
  };

  render() {
    const { classes } = this.props;
    const {
      newUserOpen,
      playername,
      user,
      description,
      allUsers,
      privileges,
    } = this.state;

    if (privileges.owner === 1 || privileges.collectiondetail_modify === 1) {
      return (
        <>
          {newUserOpen ? this.newUserDialog(classes) : null}
          <AccountDashboardLayout>
          <Formik
            enableReinitialize
            initialValues={{
              playername: playername,
              user: user,
              description: description,
            }}
            validationSchema={Yup.object().shape({
              playername: Yup.string().required(
                "Bitte einen Spielernamen eingeben"
              ),
              description: Yup.string()
                .nullable()
                .max(30, "Der Hinweis darf maximal 30 Zeichen lang sein"),
            })}
            onSubmit={(
              { playername, user, description },
              { setSubmitting, setStatus, setError, resetForm }
            ) =>
              this._handleSubmit({
                playername,
                user,
                description,
                setSubmitting,
                setStatus,
                resetForm,
              })
            }
            render={(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
                setFieldValue,
              } = props;
              return (
                <>
                  <CssBaseline />
                  {isSubmitting && (
                    <Backdrop className={classes.backdrop} open>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="playername"
                            label="Spielername"
                            name="playername"
                            value={values.playername}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.playername && errors.playername
                            )}
                            autoComplete="off"
                            variant="outlined"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.playername && errors.playername
                            )}
                          >
                            {touched.playername && errors.playername
                              ? errors.playername
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="description"
                            label="Hinweis"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            autoComplete="off"
                            variant="outlined"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          >
                            {touched.description && errors.description
                              ? errors.description
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.user && errors.user)}
                          variant="standard"
                        >
                          <Autocomplete
                            name="username"
                            id="username"
                            value={values.user?.id ? values.user : null}
                            options={allUsers}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              if (params.inputValue !== "") {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  username: `"${params.inputValue}" zur Sammlung hinzufügen`,
                                });
                              }
                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              // e.g value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.username;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.username}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Verknüpfen mit Spieleordner-Benutzer"
                                inputProps={{
                                  ...params.inputProps,
                                  "aria-autocomplete": "none",
                                }}
                                variant="outlined"
                              />
                            )}
                            //onChange={(e, value) => { setFieldValue("user", value) }}
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                // timeout to avoid instant validation of the dialog's form.
                                setTimeout(() => {
                                  this.toggleNewUserOpen(newValue);
                                });
                              } else if (newValue && newValue.inputValue) {
                                this.toggleNewUserOpen(newValue.inputValue);
                              } else {
                                setFieldValue("user", newValue);
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.user && errors.user)}
                            freeSolo
                          />
                          <FormHelperText
                            error={Boolean(touched.user && errors.user)}
                          >
                            {touched.user && errors.user ? errors.user : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={Boolean(!isValid || isSubmitting)}
                          className={classes.submit}
                        >
                          {"Speichern"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </>
              );
            }}
          />
          </AccountDashboardLayout>
        </>
      );
    }
    return <></>;
  }
}

export default withStyles(useStyles)(AddUser);
