import React, { Component } from 'react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Grid,
  Autocomplete,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { createFilterOptions } from '@mui/material/Autocomplete';
import LendDataService from "../../services/lend.service";
import PlayerDataService from "../../services/player.service";
import { useHeaderStore } from "src/store/store";


const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      display: 'block',
      margin: 'auto',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })


class EditLend extends Component {
  constructor(props) {
    super(props);
    this.lendID = this.props.match.params.lend_id;
    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "lend.modifyLend", help: "help.modifyLend"})

    this.state = {
      allPlayers: [],
      title: "",
      gameID: null,
      collectionID: null,

      date_start: null,
      date_end: null,
      comment: "",
      player: null,
      status: null,

      metaInfo: [],
      loading: true,
    }
  }

  componentDidMount() {
    this.retrieveLendDetails(this.lendID)
    this.retrievePlayers();
  }

  retrieveLendDetails(id) {  
    LendDataService.get(id)
    .then(response => {
      if(response.data.meta.privileges.owner !== 1 && response.data.meta.privileges.lend_modify !== 1) {
        this.props.setStateValue('alertMessage', { severity: 'error', message: `error.2013`, key: Math.random() })
        this.props.history.push(`/`); 
      } else {
        this.setState({
          gameID: response.data.data.lend.game_id,
          title: response.data.data.lend.game_title,
          date_start: response.data.data.lend.date_start,
          date_end: response.data.data.lend.date_end,
          comment: response.data.data.lend.comment,
          player: {
            id: response.data.data.lend.player_id,
            player_name: response.data.data.lend.player_name,
          },
          metaInfo: response.data.meta,
          loading: false,
        })
        this.props.setStateValue('isLoading', false)
      }
    })
    .catch(e => {
      if(e.response.status === 403) {
        this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.error.code}`, key: Math.random() })
        this.props.history.push(`/`); 
      } 
      console.log(e);
    });
}

retrievePlayers() {
  PlayerDataService.getAll(this.collection_id)
    .then(response => {
      let players = []
      response.data.data.players.map((player) => (
        !player.owner &&
          players.push({id: player.player_id, player_name: player.player_name})
      ))
      this.setState({
        allPlayers: players
      })
    })
    .catch(e => {
      console.log(e);
    });
}

  _handleSubmit = ({
    game_id,
    collection_id,
    lend_start,
    lend_end,
    description,
    lend_to,
    setSubmitting,
    setError,
    resetForm,
  }) => {
    var data = {
      dateStart: lend_start,
      dateEnd: lend_end ? lend_end : null,
      comment: description,
      lendTo: lend_to.id,
    };
    LendDataService.update(this.lendID, data)
    .then(response => {
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Die Leihe wurde erfolgreich bearbeitet', key: Math.random() })
      this.props.history.push(`/games/${game_id}`); 
    })
    .catch(e => {
      setSubmitting(false)
      if(e.response.status === 403) {
        this.props.setStateValue('alertMessage', { severity: 'error', message: `error.${e.response.data.error.code}`, key: Math.random() })
        this.props.history.push(`/`); 
      } else {
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Bearbeiten der Leihe', key: Math.random() })
      console.log(e);
      }
    });
  }

  render() {
    const { classes } = this.props; 
    const { title, gameID, date_start, date_end, comment, collectionID, allPlayers, metaInfo, player, loading} = this.state;

    let validationShape= {
      lend_to: Yup.object().nullable().required('Bitte einen Spieler eingeben'),
      lend_start: Yup.date().required('Bitte ein Startdatum eingeben'),
    }

    if(date_end) {
      validationShape.lend_end = Yup.date().required('Bitte ein Enddatum eingeben')
    }

    return (
      <Formik
        enableReinitialize
        initialValues={{
          game_title: title,
          game_id: gameID,
          collection_id: collectionID,
          lend_to: player,
          description: comment,
          lend_start: date_start,
          lend_end: date_end,
        }}
        validationSchema={Yup.object().shape(validationShape)}
        onSubmit={(
          { game_id,
            collection_id,
            lend_start,
            lend_end,
            description,
            lend_to,},
          { setSubmitting, setStatus, setError, resetForm }
        ) =>
          this._handleSubmit({
            game_id,
            collection_id,
            lend_start,
            lend_end,
            description,
            lend_to,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            status,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          } = props
          return <>       
            <CssBaseline />
            { isSubmitting && 
              <Backdrop className={classes.backdrop} open>
                <CircularProgress color="inherit" />
               </Backdrop>
            }
            <div className={classes.paper}>
              <form className={classes.form} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="game_title"
                    label="Titel"
                    name="game_title"
                    disabled
                    value={values.game_title}
                    onBlur={handleBlur}
                    error={Boolean(touched.game_title && errors.game_title)}
                    variant="standard" />
                  <FormHelperText
                    error={Boolean(touched.game_title && errors.game_title)}
                  >
                    {touched.game_title && errors.game_title
                      ? errors.game_title
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    type ="date"
                    id="lend_start"
                    name="lend_start"
                    label="Startdatum"
                    value={values.lend_start}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                    variant="standard" />    
                  <FormHelperText
                    error={Boolean(touched.lend_start && errors.lend_start)}
                  >
                    {touched.lend_start && errors.lend_start
                      ? errors.lend_start
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    type ="date"
                    id="lend_end"
                    name="lend_end"
                    label="Enddatum"
                    value={values.lend_end}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    InputLabelProps={{ shrink: true, }}
                    variant="standard" />    
                  <FormHelperText
                    error={Boolean(touched.lend_end && errors.lend_end)}
                  >
                    {touched.lend_end && errors.lend_end
                      ? errors.lend_end
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="description"
                    label="Anmerkung"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    variant="standard" />
                  <FormHelperText
                    error={Boolean(touched.description && errors.description)}
                  >
                    {touched.description && errors.description
                      ? errors.description
                      : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={Boolean(touched.lend_to && errors.lend_to)}
                  variant="standard">
                  <Autocomplete
                    name="lend_to"
                    id="lend_to"
                    value={values.lend_to}
                    options={allPlayers}
                    getOptionLabel={(option) => option.player_name}
                    renderInput={(params) => <TextField
                      {...params}
                      label="Verliehen an"
                      inputProps={{...params.inputProps, 'aria-autocomplete': 'none'}}
                      variant="standard" />}
                    onChange={(e, value) => { setFieldValue("lend_to", value) }}
                    onBlur={handleBlur}
                    error={Boolean(touched.lend_to && errors.lend_to)}
                  />
                  <FormHelperText
                    error={Boolean(touched.lend_to && errors.lend_to)}
                  >
                    {touched.lend_to && errors.lend_to ? errors.lend_to : ''}
                  </FormHelperText>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {'Speichern'}
                </Button>
                </Grid>
              </Grid>
              </form>
            </div>
          </>;
        
        }}
      />
    );
  }
}

export default withStyles(useStyles)(EditLend)