import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import GameDataService from "../../services/game.service";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import noImage from "../../images/no_image.png";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleResponseAxios } from "../../helpers/handle-response";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CasinoIcon from '@mui/icons-material/Casino';
import queryString from "query-string";
import { array } from "yup";

import {
  Avatar,
  Chip,
  Container,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tabs,
  Tab,
  Box,
  Link as URL,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Rating,
} from '@mui/material';

const useStyles = (theme) => ({
  container: {
    textAlign: "center",
  },
  picture: {
      height: "15vh",
  },
  avatar: {
    height: "15vh",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  speedDialMatch: {
    position: "inherit",
    maxWidth: 0,
    maxHeight: 0,
    marginLeft: "auto",
    marginRight: "20px",
    "& .MuiFab-primary": {
      color: "#333",
      boxShadow: "none",
      background: "transparent",
    },
  },
  tableAltRow: {
    "&:nth-child(odd)": {
      backgroundColor: "#f2f2f2",
    },
  },
  tableAltTable: {
    "& tr:nth-child(even)": {
      backgroundColor: "#f2f2f2",
    },
  },
  chipRoot: {
    height: "auto",
    minHeight: "32px",
    margin: "1px",
  },
  chipLabel: {
    whiteSpace: "normal",
    margin: "1px",
    height: "auto",
    minHeight: "32px",
    display: "flex",
    alignItems: "center",
  },
  metainfo: {
    margin: theme.spacing(2),
  },
  SwipeAbleContainer: {
    paddingBottom: theme.spacing(5),
    minHeight: "55vh",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  winnerIcon: {
    color: "gold !important",
    verticalAlign: "middle",
  },
  statisticHeader: {
    marginTop: "1em",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class GameDetails extends Component {
  constructor(props) {
    super(props);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);

    this.props.setStateValue("isLoading", true);

    this.state = {
      id: "",
      title: "",
      ean: "",
      designer: "",
      publisher: "",
      minPlayers: "",
      maxPlayers: "",
      minAge: "",
      gameDuration: "",
      datePurchased: "",
      dateReleased: "",
      coop: false,
      recordPoints: false,
      description: "",
      categories: [],
      maingame: [],
      extensions: [],
      locationID: "",
      description: "",
      rating: "",
      image: "",
      apiUrl: "",


      tabIndex: 0,
    };
  }

  componentWillMount() {
    this.props.setStateValue("pageTitle", "Spieldetails");
    this.props.setStateValue('help', false);
  }

  componentDidMount() {
    const id = this.props.match.params.game_id;
    this.retrieveGameDetails(id);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      const id  = this.props.match.params.game_id;
      this.retrieveGameDetails(id);
    }
  }

  retrieveGameDetails(id) {
    GameDataService.getPublic(id, this.collection_id)
      .then((response) => {
        this.setState({
          id: response.data.data.game.id,
          collection_id: response.data.data.game.collection_id,
          collection_name: response.data.data.game.collection_name,
          title: response.data.data.game.title,
          ean: response.data.data.game.ean,
          designer: response.data.data.game.designer,
          publisher: response.data.data.game.publisher,
          minPlayers: response.data.data.game.min_players,
          maxPlayers: response.data.data.game.max_players,
          minAge: response.data.data.game.min_age,
          gameDuration: response.data.data.game.game_duration,
          datePurchased: moment(
            response.data.data.game.date_purchased
          ).isValid()
            ? moment(response.data.data.game.date_purchased).format(
                "DD.MM.YYYY"
              )
            : "",
          dateReleased: response.data.data.game.date_released,
          coop: response.data.data.game.coop,
          recordPoints: response.data.data.game.recordPoints,
          maingame: response.data.data.maingame,
          rating: response.data.data.game.rating,
          description: response.data.data.game.description,
          categories: response.data.data.categories,
          location: response.data.data.game.location_name,
          image: response.data.data.game.image,
          apiUrl: response.data.data.game.api_url,

          extensions: response.data.data.extensions,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }

  handleCategoryClick(categoryId) {
    this.props.setStateValue("categoryFilter", categoryId);
    this.props.history.push(`/`)
  }

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }



  render() {
    const game = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>
          {game.title}
        </Typography>
        {game.maingame.title && (<Typography variant="h6" align="center" gutterBottom>
          Erweiterung von <b>{game.maingame.title}</b>
        </Typography>)}
        {game.collection_id != this.collection_id && (
          <Typography
            variant="caption"
            align="center"
            gutterBottom
            sx={{ margin: "auto", marginTop: "-1em", display: "table" }}
          >
            Spielesammlung: {game.collection_name}
          </Typography>
        )}
        <Container className={classes.container}>
          <img
            src={game.image}
            alt={game.title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = noImage;
            }}
            className={classes.picture}
          />
        </Container>
        <Tabs
          value={this.state.tabIndex}
          aria-label="gameDetails tabs"
          indicatorColor="primary"
          variant='scrollable'
        >
          <Tab label="Details" {...this.a11yProps(0)} />
        </Tabs>
        <SwipeableViews
          axis="x"
          index={this.state.tabIndex}
          className={classes.SwipeAbleContainer}
        >
          <TabPanel value={this.state.tabIndex} index={0} dir="ltr">
            <TableContainer component={Paper}>
              <Table
                className={classes.tableAltTable}
                aria-label="game-details"
                sx={{tableLayout: "fixed"}}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>EAN</TableCell>
                    <TableCell>{game.ean}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hauptspiel</TableCell>
                    <TableCell>
                      {game.maingame.id && (
                        <Chip
                          avatar={<Avatar alt={game.maingame.title} src={game.maingame.image ? game.maingame.image : Array.from(game.maingame.title)[0]} />}
                          component={Link}
                          to={`/games/${game.maingame.id}`}
                          label={game.maingame.title}
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                          }}
                          clickable
                        />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Erweiterungen</TableCell>
                    <TableCell>
                      {game.extensions && 
                        game.extensions.map((extension, index) => (
                          <Chip
                            avatar={<Avatar alt={extension.title} src={extension.image ? extension.image : Array.from(extension.title)[0]} />}
                            component={Link}
                            to={`/games/${extension.id}`}
                            label={extension.title}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            clickable
                          />
                        ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Autor</TableCell>
                    <TableCell>{game.designer}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Publisher</TableCell>
                    <TableCell>{game.publisher}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Min. Spieler</TableCell>
                    <TableCell>{game.minPlayers}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Max. Spieler</TableCell>
                    <TableCell>{game.maxPlayers}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Alter</TableCell>
                    <TableCell>
                      {game.minAge ? game.minAge + "+" : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spieldauer</TableCell>
                    <TableCell>{game.gameDuration}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Anschaffungsdatum</TableCell>
                    <TableCell>{game.datePurchased}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Veröffentlicht</TableCell>
                    <TableCell>{game.dateReleased}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Koop</TableCell>
                    <TableCell>{game.coop ? "Ja" : "Nein"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Genre</TableCell>
                    <TableCell>
                      {game.categories &&
                        game.categories.map((category, index) => (
                          <Chip
                            onClick={() => this.handleCategoryClick(category.id)}
                            label={category.category_name}
                            classes={{
                              root: classes.chipRoot,
                              label: classes.chipLabel,
                            }}
                            clickable
                          />
                        )
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Hinweis</TableCell>
                    <TableCell>{game.description}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Bewertung</TableCell>
                    <TableCell><Rating value={game.rating} precision={0.5} readOnly icon={<CasinoIcon color={'primary'} />} emptyIcon={<CasinoIcon />}/></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>BGA-URL</TableCell>
                    <TableCell sx={{ wordBreak: 'break-all' }}><URL href={game.apiUrl} target="_blank" rel="noopener">{game.apiUrl}</URL></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </SwipeableViews>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(GameDetails);
