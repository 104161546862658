import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Typography, Button, Box, Card } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  selectButton: {
    marginTop: theme.spacing(3),
  },
  box: {
    display: 'grid', 
    alignItems: 'center',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [theme.breakpoints.up("md")]: {
      gap: '32px', 
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
  card: {
    color: "#212B36",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ",
    background:
      "linear-gradient(to bottom, rgba(145, 158, 171, 0), rgba(145, 158, 171, 0.12))",
    overflow: "hidden",
    borderRadius: "16px",
    padding: "40px",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardHighlighted: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  contentBox: {
    marginTop: "40px"
  },
  contentText: {
    marginLeft: "12px",
  },
  contentPrice: {
  },
  mobile: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 112px)",
    borderRight: "1px solid #ccc",
  },
}));

export default function PaymentForm(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box className={classes.box} sx={{ display: 'grid', alignItems: 'center', gap: '32px', gridTemplateColumns: 'repeat(3, 1fr)'  }} >
          <Card variant="elevation" className={classes.card}>
            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h5" gutterBottom>
                Basis
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row"}}>
                <Typography variant='h4' className={classes.contentPrice}>0€</Typography>
              </Box>
            </Box>
            <Box className={classes.contentBox}>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  3 Sammlungen
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  50 Spiele
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  xyz
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className={classes.selectButton}
                >
                  Wählen
                </Button>
              </Box>
            </Box>
          </Card>
          <Card variant="elevation" className={`${classes.cardHighlighted} ${classes.card}` } >
          <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h5" gutterBottom>
                Plus
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row"}}>
                <Typography variant='h4' className={classes.contentPrice}>6€</Typography>
              </Box>
            </Box>
            <Box className={classes.contentBox}>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  10 Sammlungen
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  500 Spiele
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  xyz
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.selectButton}
                >
                  Wählen
                </Button>
              </Box>
            </Box>
          </Card>
          <Card variant="elevation" className={classes.card}>
          <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}>
              <Typography variant="h5" gutterBottom>
                Pro
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row"}}>
                <Typography variant='h4' className={classes.contentPrice}>10€</Typography>
              </Box>
            </Box>
            <Box className={classes.contentBox}>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  Unbegrenzte Sammlungen
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  Unbegrenzte Spiele
                </Typography>
              </Box>
              <Box sx={{ display: "flex" }}>
                <CheckCircleOutlineIcon fontSize="small" color="primary" />
                <Typography variant="body2" className={classes.contentText}>
                  xyz
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className={classes.selectButton}
                >
                  Wählen
                </Button>
              </Box>
            </Box>
          </Card>
          </Box>
    </React.Fragment>
  );
}
