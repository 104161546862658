import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import {
  StatiticHeader,
  StatisticTable,
} from "src/components/StyledComponents";
import TabPanel from "src/components/TabPanel";

const Matches = ({ value, index, statistic, t, ...other }) => {
  return (
    <TabPanel value={value} index={index} dir="ltr">
      <StatiticHeader variant="h5" align="center" gutterBottom>
        Partien je Kategorie
      </StatiticHeader>
      <TableContainer component={Paper} sx={{ marginBottom: "5px" }}>
        <StatisticTable aria-label="statistic-matches">
          <TableBody>
            {statistic?.matches_per_category &&
              statistic.matches_per_category.map((category, index) => (
                <TableRow key={index}>
                  <TableCell>{category.category_name}</TableCell>
                  <TableCell align="right">{category.cnt_matches}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </StatisticTable>
      </TableContainer>
    </TabPanel>
  );
};
export default Matches;
