import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CollectionDataService from "src/services/collection.service";
import PlayerDataService from "src/services/player.service";
import PlayerGroupDataService from "src/services/playerGroup.service";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";

const AddPlayGroup = (props) => {
  const { match, history } = props;
  const { collection_id } = match.params;
  const [collectionDetails, setCollectionDetails] = useState({});
  const [allPlayers, setAllPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collectionDetailsResponse = await CollectionDataService.get(
          collection_id
        );
        setCollectionDetails(collectionDetailsResponse.data);
        const playersResponse = await PlayerDataService.getAll(collection_id);
        setAllPlayers(playersResponse.data.data.players);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [collection_id]);

  const initialValues = {
    groupname: "",
    description: "",
    players: [],
  };

  const validationSchema = Yup.object().shape({
    groupname: Yup.string()
      .required("Bitte einen Gruppennamen eingeben")
      .max(25, "Der Gruppenname darf maximal 25 Zeichen lang sein"),
    description: Yup.string().max(
      30,
      "Der Hinweis darf maximal 30 Zeichen lang sein"
    ),
    players: Yup.array().min(
      1,
      "Die Gruppe muss mindestens 1 Spieler enthalten"
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = {
        groupname: values.groupname,
        players: values.players.map((player) => ({ id: player.player_id })),
        description: values.description,
        collectionID: collection_id,
      };
      await PlayerGroupDataService.create(data);
      history.push(`/settings/collections/${collection_id}/player-groups`);
    } catch (error) {
      console.error("Error submitting data:", error);
      setSubmitting(false);
    }
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <AccountDashboardLayout>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isLoading && (
        <Container component="main" maxWidth="xs">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <form onSubmit={formikProps.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} xl={6}>
                    <FormControl fullWidth margin="normal" variant="standard">
                      <TextField
                        id="groupname"
                        label="Gruppenname"
                        name="groupname"
                        value={formikProps.values.groupname}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        error={
                          formikProps.touched.groupname &&
                          Boolean(formikProps.errors.groupname)
                        }
                        helperText={
                          formikProps.touched.groupname &&
                          formikProps.errors.groupname
                        }
                        autoComplete="off"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={6}>
                    <FormControl fullWidth margin="normal" variant="standard">
                      <TextField
                        id="description"
                        label="Hinweis"
                        name="description"
                        value={formikProps.values.description}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        error={
                          formikProps.touched.description &&
                          Boolean(formikProps.errors.description)
                        }
                        helperText={
                          formikProps.touched.description &&
                          formikProps.errors.description
                        }
                        autoComplete="off"
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} xl={12}>
                    <FormControl fullWidth margin="normal" variant="standard">
                      <Autocomplete
                        multiple
                        id="players"
                        options={allPlayers}
                        onChange={(event, value) => {
                          formikProps.setFieldValue("players", value);
                          console.dir(value);
                        }}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.player_name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.player_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Mitspieler"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        formikProps.isSubmitting || !formikProps.isValid
                      }
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>
      )}
    </AccountDashboardLayout>
  );
};

export default AddPlayGroup;
