import React, { Component } from "react";
import PlayerDataService from "../../services/player.service";
import withStyles from "@mui/styles/withStyles";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";
import { FlexBox } from "src/components/flex-box";
import {
  Pagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Box,
} from "@mui/material";

const useStyles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    marginBottom: "5vh",
  },
  chevron: {
    textAlign: "right",
  },
  delete: {
    color: "red",
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,
  },
  playerIcon: {
    verticalAlign: "bottom",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
});

class Players extends Component {
  constructor(props) {
    super(props);
    this.handleDeletePlayer = this.handleDeletePlayer.bind(this);
    this.handleChangeSpeedDial = this.handleChangeSpeedDial.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "player.player_other", help: "help.players"})

    this.state = {
      playerError: "",
      accountPlayer: [],
      players: [],
      collection: this.props.match.params.collection_id,
      privileges: {},
      deletePlayer: false,
      deletePlayerID: null,
      deletePlayerName: "",

      currentPage: 1,

      openSpeedDial: false,
    };
  }

  componentDidMount() {
    this.retrievePlayers(this.state.collection);
  }

  retrievePlayers(collection) {
    PlayerDataService.getAll(collection)
      .then((response) => {
        this.setState({
          players: response.data.data.players,
          privileges: response.data.meta.privileges,
          cntPages: Math.ceil(response.data.data.players.length/this.state.itemsPerPage)
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          this.props.history.push(`/`);
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Laden der Spieler",
            key: Math.random(),
          });
        }
      });
  }

  handleDeletePlayer(id, player_name) {
    this.setState({
      deletePlayer: true,
      deletePlayerID: id,
      deletePlayerName: player_name,
    });
  }

  alertDialog() {
    const handleDialogClose = () => {
      this.setState({
        deletePlayer: false,
      });
    };
  

    const handleDialogConfirm = () => {
      PlayerDataService.delete(this.state.deletePlayerID, this.state.collection)
        .then((response) => {
          handleDialogClose();
          this.props.setStateValue("alertMessage", {
            severity: "success",
            message: "Der Spieler wurde erfolgreich gelöscht",
            key: Math.random(),
          });
          this.retrievePlayers(this.state.collection);
        })
        .catch((e) => {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler Löschen des Spielers",
            key: Math.random(),
          });
        });
    };

    return (
      <div>
        <Dialog
          open="open"
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {this.state.deletePlayerName + " wirklich löschen?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Es werden alle Statistiken und Historien in Verbindungen mit
              diesem Spieler gelöscht.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={handleDialogConfirm} color="error" autoFocus>
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  handleChangeSpeedDial() {
    this.setState({
      openSpeedDial: !this.state.openSpeedDial,
    });
  }

  handleChangePage(event, value) {
    this.setState({
      currentPage: value,
    });
  };

  render() {
    const { players, privileges, deletePlayer, openSpeedDial, currentPage } =
      this.state;
    const { classes } = this.props;

    const itemsPerPage = 10;
    const cntPages = Math.ceil(players.length/itemsPerPage)
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;

    return (
      <React.Fragment>
        {deletePlayer ? this.alertDialog() : null}
        <AccountDashboardLayout>
          <Box className={classes.contentBox}>
            {privileges.owner === 1 ||
            privileges.collectiondetail_modify === 1 ? (
              <>
                <List component="nav">
                  {players.slice(indexOfFirstPost,indexOfLastPost).map((player) => (
                    <ListItem>
                      <ListItemText
                        primary={player.player_name}
                        secondary={
                          player.username && (
                            <>
                              <PersonIcon className={classes.playerIcon} />{" "}
                              {player.owner === 1 ? "Eigentümer" : player.username}
                            </>
                          )
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          component={Link}
                          to={`/settings/collections/${this.state.collection}/players/${player.player_id}`}
                          size="large"
                        >
                          <EditIcon />
                        </IconButton>
                        {(privileges.owner === 1 ||
                          privileges.collectiondetail_delete === 1) && (
                          <IconButton
                            edge="end"
                            color="error"
                            onClick={() =>
                              this.handleDeletePlayer(
                                player.player_id,
                                player.player_name
                              )
                            }
                            size="large"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            ) : (
              <List component="nav">
                {players.slice(indexOfFirstPost,indexOfLastPost).map((player) => (
                  <ListItem>
                    <ListItemText
                      primary={player.player_name}
                      secondary={
                        player.username && (
                          <>
                            <PersonIcon className={classes.playerIcon} />{" "}
                            {player.owner === 1 ? "Eigentümer" : player.username}
                          </>
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <FlexBox justifyContent="center" mt={5}>
              <Pagination
                count={cntPages}
                color="primary"
                variant="outlined"
                onChange={this.handleChangePage}
              />
            </FlexBox>
          </Box>
        </AccountDashboardLayout>
        {privileges.collectiondetail_insert === 1 && (
          <SpeedDial
            ariaLabel="Spielermenü"
            className={classes.speedDial}
            icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
            open={openSpeedDial}
            onOpen={this.handleChangeSpeedDial}
            onClose={this.handleChangeSpeedDial}
          >
            <SpeedDialAction
              key="add"
              icon={<AddIcon color="primary" />}
              tooltipTitle="Spieler hinzufügen"
              component={Link}
              to={`/settings/collections/${this.state.collection}/players/add`}
            />
          </SpeedDial>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Players);
