import React from 'react';
import moment from 'moment';
import makeStyles from "@mui/styles/makeStyles";
import { Typography, Grid } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: theme.spacing(2)
  }
}));

function PaymentDetails(props) {
  const { formValues } = props;
  const classes = useStyles();
  return (
    <Grid item container direction="column" xs={12} sm={6}>
      <Typography variant="h6" gutterBottom className={classes.title}>
        Payment details
      </Typography>
      <Grid container>
        <React.Fragment>
          ddd
        </React.Fragment>
      </Grid>
    </Grid>
  );
}

export default PaymentDetails;
