import * as Yup from 'yup';
import moment from 'moment';

export default [
  Yup.object().shape({
    username: Yup.string().required('Bitte einen Benutzernamen eingeben'),
    firstname: Yup.string().required('Bitte einen Vornamen eingeben'),
    lastname: Yup.string().required('Bitte einen Nachnamen eingeben'),
    email: Yup.string()
    .required('Bitte eine E-Mail eingeben')
    .email("Bitte eine gültige E-Mail eingeben"),
    password: Yup.string().required('Bitte ein Passwort eingeben'),
    confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwörter stimmen nicht überein')
    .required('Bitte das Passwort wiederholen'),
    entryCode: Yup.string().required('Bitte einen Teilnahmecode eingeben'),
  })
];
