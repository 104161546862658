import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FlexBox } from "src/components/flex-box";
import NavLink from "src/components/nav-link/NavLink";
import { history } from "src/helpers/history";
import { authenticationService } from "src/auth/authentication.service";
import preval from "preval.macro";


const buildDate = preval`module.exports = new Date().toLocaleDateString('de-DE',{ year: 'numeric', month: '2-digit', day: 'numeric', hour: 'numeric', minute: 'numeric' });`;
const version = process.env.REACT_APP_VERSION;

// custom styled components
const MainContainer = styled(Card)(({ theme }) => ({
  paddingBottom: "1.5rem",
  [theme.breakpoints.down("md")]: {
    overflowY: "auto",
  },
}));
const StyledNavLink = styled(({ children, isCurrentPath, ...rest }) => (
  <NavLink {...rest}>{children}</NavLink>
))(({ theme, isCurrentPath }) => ({
  display: "flex",
  alignItems: "center",
  borderLeft: "4px solid",
  paddingLeft: "1.5rem",
  paddingRight: "1.5rem",
  marginBottom: "1.25rem",
  justifyContent: "space-between",
  borderColor: isCurrentPath ? theme.palette.primary.main : "transparent",
  "& .nav-icon": {
    color: isCurrentPath ? theme.palette.primary.main : theme.palette.grey[600],
  },
  "&:hover": {
    borderColor: theme.palette.primary.main,
    "& .nav-icon": {
      color: theme.palette.primary.main,
    },
  },
}));

const logout = () => {
  authenticationService.logout();
  history.push("/");
};

const Navigations = () => {
  const { pathname }  = useLocation();
  return (
    <MainContainer>
      {linkList.map((item) => (
        <Fragment key={item.title}>
          <Typography p="26px 30px 1rem" color="grey.600" fontSize="12px">
            {item.title}
          </Typography>
          {item.list.map((item) => (
            <StyledNavLink
              href={item.href}
              key={item.title}
              isCurrentPath={pathname.includes(item.href)}
            >
              <FlexBox alignItems="center" gap={1}>
                <span>{item.title}</span>
              </FlexBox>

              <span>{item.count}</span>
            </StyledNavLink>
          ))}
        </Fragment>
      ))}
      <StyledNavLink key="logout" onClick={logout} sx={{ paddingTop: '26px'}}>
        <FlexBox alignItems="center" gap={1}>
          <span>Abmelden</span>
        </FlexBox>
      </StyledNavLink>
      <Typography p="26px 0 0" color="grey.600" fontSize="12px" align="center">
        Version {version} | Build {buildDate}
      </Typography>
    </MainContainer>
  );
};
const linkList = [
  {
    title: "SAMMLUNGEN",
    list: [
      {
        href: "/settings/collections",
        title: "Sammlungen",
      },
    ],
  },
  {
    title: "KONTO",
    list: [
      {
        href: "/settings/account/change-password",
        title: "Passwort ändern",
      },
      {
        href: "/settings/account/edit-profile",
        title: "Persönliche Daten ändern",
      },
      {
        href: "/settings/account/membership",
        title: "Mitgliedschaft",
      },
      {
        href: "/settings/notifications",
        title: "Benachrichtigungen",
      },
      {
        href: "/settings/sessions",
        title: "Sitzungen",
      },
    ],
  },
  {
    title: "SONSTIGES",
    list: [
      {
        href: "/contact",
        title: "Kontakt",
      },
      {
        href: "/support",
        title: "Unterstütze uns",
      },
      {
        href: "/privacy",
        title: "Datenschutz",
      },
      {
        href: "/legalNotice",
        title: "Impressum",
      },
    ],
  },
];
export default Navigations;
