import * as React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useAlertMessageStore } from '../store/store';

import { Snackbar, Alert as MuiAlert, List, ListItem, ListItemText } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  topWarning: {
    marginTop: '50px',
  },

}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertMessage(props) {
  const { message, errorList, severity, resetAllStates } = props;
  const [open, setOpen] = React.useState(true);
  const classes = useStyles();
  const { t } = useTranslation();
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    resetAllStates && resetAllStates();
    useAlertMessageStore.setState({ alertMessage: false})
  };

  return (
    <Snackbar className={classes.topWarning} open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} >
      <Alert onClose={handleClose} severity={severity} key={Math.random()}>
      {t(message)}
      { errorList &&
        <>
        <List>
          { errorList.map((error, key) => {
            return (
              <ListItem key={key}>
                <ListItemText primary={error} />
              </ListItem>
            )
           } ) }
        </List>
        </>
      }
      </Alert>
    </Snackbar>
  );
}
