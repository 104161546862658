import http from "../http-common";

class ClassAlertDataService {
  getAll() {
    return http.get(`/alerts`);
  }

}

const AlertDataService = new ClassAlertDataService();
export default AlertDataService;
