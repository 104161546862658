import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import {
  CssBaseline,
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Link,
  Box,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import UserDataService from "../../services/user.service";
import { useHeaderStore } from 'src/store/store';
import queryString from 'query-string';

function Copyright() {
  return (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" href="https://spieleordner.de/">
      Spieleordner
    </Link>{' '}
    {new Date().getFullYear()}
  </Typography>
  );
  }

const useStyles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      display: 'block',
      margin: 'auto',
      marginTop: '16px',
    },
  })


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "password.resetPassword", help: "help.resetPassword"})
    this.setStateValue = this.props.setStateValue;
    this.state = {
      token: '',
      username: '',
      checked: false,
    }
    this.currentUser = this.props.currentUser;
  }

  componentWillMount() {
    var params = queryString.parse(this.props.location.search);
    this.setState({
      token: params.token,
      username: params.username,
    })
    if(params.token && params.username) {
      this.checkPasswordToken(params.username, params.token)
    }
    this.props.setStateValue('isLoading', false)
  }


  checkPasswordToken(username, token){
    if(typeof this.currentUser === 'undefined' || this.currentUser.username === username) {
      UserDataService.checkPasswordToken(username, token)
      .then(response => {
        this.setState({
          alertMessage: false,
          checked: true,
        });
        this.props.history.push(`/resetpassword?username=${username}&token=${token}`);
      })
      .catch(e => {
        this.props.setStateValue('alertMessage', { severity: 'error', message: 'Der Token ist ungültig', key: Math.random() })
      });
    } else {
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Das Passwort kann nicht für fremde Benutzer geändert werden', key: Math.random() })
    }
  }

  _handleSubmit = ({
    username,
    token,
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    var data = {
      username: username,
      token: token,
      newPassword: newPass,
    }
    if(!this.state.checked) {
      setSubmitting(false) 
      this.checkPasswordToken(username, token)
    } else {
    UserDataService.resetPassword(data)
    .then(response => {
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Das Passwort wurde erfolgreich geändert', key: Math.random() })
      this.props.history.push(`/`);
    })
    .catch(e => {
      resetForm();
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Ändern des Passwortes', key: Math.random() })
    });
  } }

  render() {
    const { username, token, checked } = this.state;
    const { classes } = this.props;

    return checked ? (
    <Formik
      initialValues={{
        newPass: '',
        confirmPass: '',
        username: username,
        token: token,
      }}
      validationSchema={object().shape({
        newPass: string().required('Bitte ein neues Passwort eingeben'),
        confirmPass: string()
          .oneOf([ref('newPass')], 'Passwörter stimmen nicht überein')
          .required('Bitte das neue Passwort wiederholen'),
      })}
      onSubmit={(
        { newPass, confirmPass, username, token },
        { setSubmitting, resetForm }
      ) =>
        this._handleSubmit({
          newPass,
          confirmPass,
          username, 
          token,
          setSubmitting,
          resetForm,
        })
      }
      render={props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        } = props
        return (
          <Container component="main" maxWidth="xs">
          <CssBaseline />
            <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.newPass && errors.newPass)}
                variant="standard">
                <TextField
                  id="password-new"
                  name="newPass"
                  label="Neues Passwort"
                  type="password"
                  variant="outlined"
                  value={values.newPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.newPass && errors.newPass)}
                />
                <FormHelperText
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  {touched.newPass && errors.newPass ? errors.newPass : ''}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.confirmPass && errors.confirmPass)}
                variant="standard">
                <TextField
                  id="password-confirm"
                  name="confirmPass"
                  label="Passwort wiederholen"
                  type="password"
                  variant="outlined"
                  value={values.confirmPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                />
                <FormHelperText
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  {touched.confirmPass && errors.confirmPass
                    ? errors.confirmPass
                    : ''}
                </FormHelperText>
              </FormControl>
              <TextField
                id="username"
                name="username"
                type="hidden"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.username && errors.username)}
                variant="standard" />
                <TextField
                  id="token"
                  name="token"
                  type="hidden"
                  value={values.token}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.token && errors.token)}
                  variant="standard" />
              <Button
                type="submit"
                variant="contained" 
                color="primary"
                disabled={Boolean(!isValid || isSubmitting)}
                className={classes.submit}
              >
                {'Passwort ändern'}
              </Button>
            </form>
            </div>
            <Box mt={8}>
            <Copyright />
          </Box>
          </Container>
        );
      }}
    />
    ) : (
    <Formik
      initialValues={{
        username: username,
        token: token,
        newPass: '', 
        confirmPass: '', 
      }}
      validationSchema={object().shape({
        username: string().required('Bitte einen Usernamen eingeben'),
        token: string().required('Bitte einen Token eingeben'),
      })}
      onSubmit={(
        { newPass, confirmPass, username, token, },
        { setSubmitting, resetForm }
      ) =>
        this._handleSubmit({
          newPass, 
          confirmPass, 
          username,
          token,
          setSubmitting,
          resetForm,
        })
      }
      render={props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        } = props
        return (
          <Container component="main" maxWidth="xs">
          <CssBaseline />
            <div className={classes.paper}>
            <img src="../logo192.png" style={{display: 'block', margin: 'auto' }} /> 
            <form className={classes.form} onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.username && errors.username)}
                variant="standard">
                <TextField
                  id="username"
                  name="username"
                  label="Username"
                  type="text"
                  variant="outlined"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.username && errors.username)}
                />
                <FormHelperText
                  error={Boolean(touched.username && errors.username)}
                >
                  {touched.username && errors.username ? errors.username : ''}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.token && errors.token)}
                variant="standard">
                <TextField
                  id="token"
                  name="token"
                  label="Token"
                  type="text"
                  variant="outlined"
                  value={values.token}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.token && errors.token)}
                />
                <FormHelperText
                  error={Boolean(touched.token && errors.token)}
                >
                  {touched.token && errors.token
                    ? errors.token
                    : ''}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                variant="contained" 
                color="primary"
                disabled={Boolean(!isValid || isSubmitting)}
                className={classes.submit}
              >
                {'Weiter'}
              </Button>
            </form>
            </div>
            <Box mt={8}>
            <Copyright />
          </Box>
          </Container>
        );
      }}
    />
    );
  }
}

export default withStyles(useStyles)(ResetPassword)