import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import LendDataService from "../../services/lend.service";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { deDE } from "@mui/x-data-grid/locales";
import { useHeaderStore } from "src/store/store";
import { Typography } from '@mui/material';

const useStyles = (theme) => ({
  root: {
    "& .MuiButton-root": {
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
      },
    },
    "& .Mui-even": {
      backgroundColor: "#f2f2f2",
    },
    "& .MuiDataGrid-row": {
      "&:hover": {
        backgroundColor: "#f6f6f6",
      },
    },
  },
});

const columns = [
  { field: "date_start", headerName: "Von", type: "date", width: 150 },
  { field: "date_end", headerName: "Bis", type: "date", width: 150 },
  { field: "title", headerName: "Titel", width: 250 },
  { field: "player_name", headerName: "Verliehen an", width: 300 },
  { field: "comment", headerName: "Anmerkung", width: 300 },
];

const sortModel = [
  {
    field: "date_start",
    sort: "desc",
  },
];

class MatchHistory extends Component {
  constructor(props) {
    super(props);

    this.collection_id = localStorage.getItem("collectionID");

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({
      title: "lend.lendHistory",
      help: "help.lendHistory",
    });

    this.state = {
      lendList: false,
    };
  }

  componentDidMount() {
    this.retrieveMatchList();
  }

  retrieveMatchList() {
    LendDataService.getAll(this.collection_id)
      .then((response) => {
        const data = [];
        response.data.data.forEach((val, key) => {
          if (val.date_start !== null) {
            val.date_start = new Date(val.date_start);
          }
          if (val.date_end !== null) {
            val.date_end = new Date(val.date_end);
          }
          data.push(val);
        });
        this.setState({
          lendList: data,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        this.props.setStateValue("isLoading", false);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root} style={{ height: "80vh", width: "100%" }}>
          {this.state.lendList.length > 0 ? (
            <DataGrid
              initialState={{ sortModel: sortModel }}
              rows={this.state.lendList}
              columns={columns}
              checkboxSelection
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              slots={{
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  csvOptions: {
                    delimiter: ";"
                  }
                },
              }}
            />
          ) : (
            <Typography>
              Für diese Sammlung wurde noch keine Leihe erfasst.
            </Typography>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(MatchHistory);
