import React, { Component } from "react";
import UserDataService from "../../../services/user.service";
import OrderDataService from "../../../services/order.service";
import withStyles from "@mui/styles/withStyles";
import UpgradeDialog from "../../../components/UpgradeDialog";
import RedeemCoupon from "../../../components/RedeemCoupon";
import MemberShipPlanCard from "../../../components/Membership/MemberShipPlanCard";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import { Button, Box } from '@mui/material';

const useStyles = (theme) => ({
  selectButton: {
    marginTop: theme.spacing(3),
  },
  box: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,    
    paddingLeft: "24px",
    paddingRight: "24px",
  },
});

class UpgradeAccount extends Component {
  constructor(props) {
    super(props);

    this.retrieveAccountDetails = this.retrieveAccountDetails.bind(this);
    this.getMembershipPlans = this.getMembershipPlans.bind(this);
    this.handleUpgradeAccount = this.handleUpgradeAccount.bind(this);
    this.handleEnterCoupon = this.handleEnterCoupon.bind(this);
    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "account.accountType", help: "help.accountType"})

    this.state = {
      account: {},
      membershipPlans: {},
      upgradeAccountPopup: false,
      isLoading: true,
      enterCoupon: false,
    };
  }

  componentDidMount() {
    this.retrieveAccountDetails();
    this.getMembershipPlans();
  }

  retrieveAccountDetails() {
    UserDataService.checkAccountType("accountInfo", 0)
      .then((response) => {
        this.setState({
          account: response.data.curr_membership,
        });

        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  getMembershipPlans() {
    OrderDataService.getMembershipPlans()
      .then((response) => {
        this.setState({
          membershipPlans: response.data.membershipplans,
          clientID: response.data.client,
          isLoading: false,
        })
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleUpgradeAccount(membershipPlan) {
    this.setState({
      upgradeAccountPopup: true,
      membershipPlan: membershipPlan,
    });
  }

  handleEnterCoupon() {
    this.setState({
      enterCoupon: !this.state.enterCoupon
    })
  }

  alertDialog() {
    const handleDialogClose = () => {
      this.setState({
        upgradeAccountPopup: false,
        accountType: null,
      });
    };

    return (
      <UpgradeDialog
        currAccount={this.state.account}
        membershipPlan={this.state.membershipPlan}
        clientID={this.state.clientID}
        cancel="Abbrechen"
        handleDialogClose={handleDialogClose}
        setStateValue={this.props.setStateValue}
        history={this.props.history}
      />
    );
  }

  render() {
    const { account, upgradeAccountPopup, membershipPlans, enterCoupon, isLoading } = this.state;
    const { classes } = this.props;
    return (
      <>
        {upgradeAccountPopup ? this.alertDialog(account.type) : null}
        <AccountDashboardLayout>
          <Box className={classes.contentBox}>
            <Box
              className={classes.box}
              sx={{
                display: "grid",
                alignItems: "center",
                gap: "32px",
                gridTemplateColumns: "repeat(3, 1fr)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  className={classes.selectButton}
                  onClick={this.handleEnterCoupon}
                >
                  Coupon vorhanden?
                </Button>
              </Box>
              {enterCoupon &&
              (
                <RedeemCoupon history={this.props.history} setStateValue={this.props.setStateValue} />

              )}{
                !isLoading && membershipPlans.map((membershipPlan, key) => {
                return (
                  membershipPlan.level >= account.level &&
                      <MemberShipPlanCard account={account} membershipPlan={membershipPlan} handleUpgradeAccount={this.handleUpgradeAccount} />
                  )
              })
              }
          
            </Box>
          </Box>
        </AccountDashboardLayout>
      </>
    );
  }
}

export default withStyles(useStyles)(UpgradeAccount);
