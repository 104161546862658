import React, { useState, useEffect } from "react";
import { useHeaderStore } from "src/store/store";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from 'query-string';

import StatisticDataService from "../../../services/statistic.service";

import General from "./General";
import SwipeableViews from "react-swipeable-views";
import Overview from "./Overview";
import Games from "./Games";

import {
  Backdrop,
  Box,
  CircularProgress,
  MobileStepper,
  Tabs,
  Tab,
} from "@mui/material";
import Matches from "./Matches";
import Players from "./Players";

const CollectionStatistic = ({ history, classes, match }) => {
  const collection_id = match.params.collection_id;
  const [statistic, setStatistic] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const { active } = queryString.parse(location.search) || 0;

  useEffect(() => {
    (Number(active) > 0 && Number(active) < tabList.length) && setTabIndex(Number(active));
    useHeaderStore.setState({
      title: "statistic.statistic_one",
      help: "help.statistic",
    });
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await StatisticDataService.findByCollection(
          collection_id
        );
        setStatistic(response.data.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [collection_id]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    history.push(`/statistic/collection/${collection_id}?active=${newValue}`)
  };

  const handleIndexChange = (index) => {
    setTabIndex(index);
    history.push(`/statistic/collection/${collection_id}?active=${index}`)
  };

  const tabList = [
    { label: "Übersicht" },
    { label: "Allgemein" },
    { label: "Spiele" },
    { label: "Partien" },
    { label: "Spieler" },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <Backdrop open>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              variant="scrollable"
              indicatorColor="primary"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="statistic tabs"
            >
              {tabList.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                />
              ))}
            </Tabs>
            <MobileStepper
              variant="dots"
              steps={5}
              activeStep={tabIndex}
              position="static"
              sx={{ justifyContent: "center", display: { md: "none" } }}
            />
          </Box>
          <SwipeableViews
            axis="x"
            index={tabIndex}
            onChangeIndex={handleIndexChange}
            style={{minHeight: '55vh', paddingBottom: 40}}
          >
            <Overview value={tabIndex} index={0} statistic={statistic} t={t} />
            <General value={tabIndex} index={1} statistic={statistic} t={t} />
            <Games value={tabIndex} index={2} statistic={statistic} t={t} />
            <Matches value={tabIndex} index={3} statistic={statistic} t={t} />
            <Players
              value={tabIndex}
              index={4}
              statistic={statistic}
              t={t}
              collection_id={collection_id}
            />
          </SwipeableViews>
        </>
      )}
    </React.Fragment>
  );
};

export default CollectionStatistic;
