import "../../css/App.css";
import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import AlertBox from "../../components/AlertBox";
import AlarmIcon from "@mui/icons-material/Alarm";
import GroupIcon from "@mui/icons-material/Group";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { Link } from "react-router-dom";
import AlertDataService from "../../services/alert.service";
import CategoryDataService from "../../services/category.service";
import GameDataService from "../../services/game.service";
import RoomIcon from "@mui/icons-material/Room";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import noImage from "../../images/no_image.png";
import { handleResponseAxios } from "../../helpers/handle-response";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "i18next";
import { useCollectionStore, useGameSearchStore } from '../../store/store';


import {
  Avatar,
  Grid,
  Card,
  Box,
  CardActionArea,
  Typography,
  Badge,
  Chip,
  Stack,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Backdrop,
  CircularProgress,
  LinearProgress,
} from '@mui/material';


const useStyles = (theme) => ({
  scroll: {
    display: "grid",
    [theme.breakpoints.between("md", "xl")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  gameInfoCard: {
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
  gameInfoCardAA: {
    display: "inherit",
    height: "100%",
  },
  speedDial: {
    position: "fixed",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  gameTitle: {
    textAlign: "center",
  },
  badge: {
    transform: "rotate(-45deg) translate(0,50%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  categoryFilter: {
    marginBottom: theme.spacing(2),
    overflowX: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      justifyContent: "center",
    },
  },
});


class Home extends Component {
  constructor(props) {
    super(props);

    this.retrieveGameList = this.retrieveGameList.bind(this);
    //this.retrieveCategories = this.retrieveCategories.bind(this);
    this.handleCategoryFilterClick = this.handleCategoryFilterClick.bind(this);


    this.props.setStateValue("isLoading", true);
    this.props.setStateValue("pageTitle", null);
    this.props.setStateValue('help', false);

    this.state = {
      gameList: [],
      metaInfo: [],
      categories: [],
      gameSearch: useGameSearchStore.getState(),
      isInit: true,
      openSpeedDial: false,
      collectionRole: "",
      hasMore: true,
      page: 1,
      collection: useCollectionStore.getState(),
      missingCollection: false,
      alerts: {},
    };

    this.limit = 50;
    this.timer = null;
  }

  componentDidMount() {
    this.unsub = useGameSearchStore.subscribe((gameSearch) => {
      this.setState({gameSearch})
   }, (state) => state);
   this.unsub2 = useCollectionStore.subscribe((collection) => {
     this.setState({collection})
  }, (state) => state);

    useCollectionStore.setState({collectionID: this.props.match.params.collection_id})
    this.retrieveGameList(
      this.state.gameSearch.searchString,
      this.state.gameSearch.categoryFilter,
      this.state.collection.collectionID,
      this.state.page,
      this.limit,
      0
    );
    //this.retrieveCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.gameSearch !== this.state.gameSearch ||
      prevProps.location.key !== this.props.location.key
    ) {console.dir(this.props)
      useCollectionStore.setState({collectionID: this.props.match.params.collection_id});
      this.setState({
        gameList: [],
        hasMore: true,
        page: 1,
      });
      this.props.setStateValue("isLoading", true);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.retrieveGameList(
          this.state.gameSearch.searchString,
          this.state.gameSearch.categoryFilter,
          this.state.collection.collectionID,
          1,
          this.limit,
          0
        );
      }, 100);
    }
  }

  componentWillUnmount() {
    useGameSearchStore.setState({ searchString: "" });
    this.unsub();
    this.unsub2();
  }

  retrieveGameList(
    title = this.state.gameSearch.searchString,
    categoryFilter = this.state.gameSearch.categoryFilter,
    collectionID = this.state.collection.collectionID,
    page = this.state.page,
    limit = this.limit,
    archive = 0
  ) {
    if (!collectionID || collectionID === null || collectionID === "null") {
      this.setState({
        missingCollection: true,
      });
    } else {
      this.setState({
        missingCollection: false,
      });
      this.props.setStateValue("isLoading", true);
      GameDataService.findByTitlePublic(
        title,
        categoryFilter,
        collectionID,
        page,
        limit,
        archive
      )
        .then((response) => {
          if (page > 1) {
            this.setState({
              gameList: this.state.gameList.concat(response.data.data),
              metaInfo: response.data.meta,
              isInit: false,
            });
            this.props.setStateValue("isLoading", false);
          } else {
            this.setState({
              gameList: response.data.data,
              metaInfo: response.data.meta,
              isInit: false,
            });
            this.props.setStateValue("isLoading", false);
          }
          if (response.data.data.length === 0) {
            this.setState({
              hasMore: false,
            });
          } else {
            this.setState({
              page: this.state.page + 1,
            });
          }
        })
        .catch((e) => {
          handleResponseAxios(e.response);
        });
    }
  }

  /*retrieveCategories() {
    CategoryDataService.getAll()
      .then((response) => {
        this.setState({
          categories: response.data.data,
        });
      })
      .catch((e) => {
        handleResponseAxios(e.response);
      });
  }*/


  handleCategoryFilterClick(categoryID) {
    useGameSearchStore.setState({categoryFilter: categoryID})
  }


  render() {
    const {
      gameSearch,
      gameList,
      metaInfo,
      categories,
      isInit,
      openSpeedDial,
      hasMore,
      alerts,
    } = this.state;
    const { classes } = this.props;

    return (
      <Box className={classes.root} spacing={2}>
        { isInit ? (
          <img
            style={{
              width: "auto",
              maxWidth: "80vw",
              height: "auto",
              maxHeight: "100vh",
              display: "block",
              margin: "auto",
              gridColumn: "span 3",
            }}
            src="../logo512.png"
          />
        ) : metaInfo.totalGames > 0 && gameList.length > 0 ? (
          <>
            <Stack
              direction="row"
              spacing={1}
              className={classes.categoryFilter}
            >
              {categories.map((category, key) => {
                return (
                  <Chip
                    color="primary"
                    label={category.category_name}
                    key={category.category_name}
                    variant={
                      gameSearch.categoryFilter === category.id ? "filled" : "outlined"
                    }
                    onClick={() => this.handleCategoryFilterClick(category.id)}
                    onDelete={
                      gameSearch.categoryFilter === category.id
                        ? () => this.handleCategoryFilterClick("")
                        : null
                    }
                  />
                );
              })}
            </Stack>
            {alerts.length > 0 &&
              <AlertBox alerts={alerts} />
            }
            {(gameSearch.searchString !== "" || gameSearch.categoryFilter !== "") && (
              <Box m={2}>
                <Typography align="center">
                  Suchergebnis: {metaInfo.searchResults} / {metaInfo.totalGames}{" "}
                  Spielen{" "}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={(metaInfo.searchResults / metaInfo.totalGames) * 100}
                />
              </Box>
            )}

            <InfiniteScroll
              dataLength={gameList.length} //This is important field to render the next data
              next={this.retrieveGameList}
              hasMore={hasMore}
              scrollThreshold={0.9}
              className={classes.scroll}
            >
              {" "}
              {gameList.map((val, key) => {
                return (
                  <Card
                    variant="outlined"
                    style={{ marginBottom: "10px", width: "100%" }}
                  >
                    <div className={classes.gameInfoCard}>
                      <CardActionArea
                        className={classes.gameInfoCardAA}
                        component={Link}
                        to={`games/${val.id}`}
                      >
                        <Badge
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          classes={{ badge: classes.badge }}
                          badgeContent={val.current_lend ? "Verliehen" : null}
                          color="primary"
                        >
                          <Avatar
                            alt={val.title}
                            src={val.image}
                            style={{
                              width: "50px",
                              height: "50px",
                              backgroundColor: "transparent",
                            }}
                          >
                            <img
                              src={noImage}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </Avatar>
                        </Badge>
                        <div
                          className="gameInfo-details"
                          style={{ height: "100%" }}
                        >
                          <Typography
                            gutterBottom
                            className={classes.gameTitle}
                            variant="subtitle1"
                          >
                            {val.title}
                          </Typography>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing="1"
                          >
                            <Grid title="Anzahl Spieler" item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <GroupIcon />
                                </Grid>
                                <Grid item>
                                  {!val.min_players && !val.max_players
                                    ? "n/A"
                                    : val.min_players && !val.max_players
                                    ? val.min_players
                                    : !val.min_players && val.max_players
                                    ? val.max_players
                                    : val.min_players + "-" + val.max_players}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid title="Spieldauer" item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <AlarmIcon />
                                </Grid>
                                <Grid item>
                                  {val.game_duration
                                    ? val.game_duration + " min"
                                    : "n/A"}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid title="Alter" item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <SupervisorAccountIcon />
                                </Grid>
                                <Grid item>
                                  {val.min_age ? val.min_age + "+" : "n/A"}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid title="Standort" item>
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                              >
                                <Grid item>
                                  <RoomIcon />
                                </Grid>
                                <Grid item>
                                  {val.location_name
                                    ? val.location_name
                                    : "n/A"}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </CardActionArea>
                    </div>
                  </Card>
                );
              })}
            </InfiniteScroll>
          </>
        ) : metaInfo.totalGames > 0 && gameList.length === 0 ? (
          <>
            <Stack
              direction="row"
              spacing={1}
              className={classes.categoryFilter}
            >
              {categories.map((category, key) => {
                return (
                  <Chip
                    color="primary"
                    label={category.category_name}
                    variant={
                      gameSearch.categoryFilter === category.id ? "filled" : "outlined"
                    }
                    onClick={() => this.handleCategoryFilterClick(category.id)}
                    onDelete={
                      gameSearch.categoryFilter === category.id
                        ? () => this.handleCategoryFilterClick("")
                        : null
                    }
                  />
                );
              })}
            </Stack>
            <h2>Kein passendes Spiel gefunden</h2>
          </>
        ) : (
          metaInfo.totalGames === 0 && (
            <h2>Die Sammlung enthält noch kein Spiel</h2>
          )
        )}
        {(metaInfo.collectionRole === "collection_modify" ||
          metaInfo.collectionRole === "collection_owner") && (
          <SpeedDial
            ariaLabel="Spielmenü"
            className={classes.speedDial}
            icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
            open={openSpeedDial}
            onOpen={this.handleChangeSpeedDial}
            onClose={this.handleChangeSpeedDial}
          >
            <SpeedDialAction
              key="add"
              icon={<AddIcon color="primary" />}
              tooltipTitle="Spiel hinzufügen"
              component={Link}
              to="/add-game"
            />
          </SpeedDial>
        )}
      </Box>
    );
  }
}

export default withStyles(useStyles)(Home);
