import React, { Component } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import withStyles from '@mui/styles/withStyles';
import UserDataService from "../../../services/user.service";
import { compose } from 'recompose'
import { withTranslation } from "react-i18next";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";



import {
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Box,
  Backdrop,
  CircularProgress,
} from '@mui/material';



const useStyles = theme => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      display: 'block',
      margin: 'auto',
      marginTop: '16px',
    },
    contentBox: {
      width: '100%',
      maxWidth: 450,
    }
  })


class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.props.setStateValue('isLoading', true)
    useHeaderStore.setState({ title: "password.changePassword", help: "help.changePassword"})

    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    this.props.setStateValue('isLoading', false)
  }

  _handleSubmit = ({
    currentPass,
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    var data = {
      password: currentPass,
      newPassword: newPass,
    }
    UserDataService.changePassword(data)
    .then(response => {
      resetForm();
      this.props.setStateValue('alertMessage', { severity: 'success', message: 'Passwort wurde erfolgreich geändert', key: Math.random() })
    })
    .catch(e => {
      resetForm();
      this.props.setStateValue('alertMessage', { severity: 'error', message: 'Fehler beim Ändern des Passwortes', key: Math.random() })
    });
  }

  render() {
    const { classes, t } = this.props;
    return (
      <AccountDashboardLayout>
      <Box className={classes.contentBox}>
      <Formik
        initialValues={{
          currentPass: '',
          newPass: '',
          confirmPass: '',
        }}
        validationSchema={object().shape({
          currentPass: string().required('Bitte das aktuelle Passwort eingeben'),
          newPass: string().required('Bitte ein neues Passwort eingeben'),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwörter stimmen nicht überein')
            .required('Bitte das neue Passwort wiederholen'),
        })}
        onSubmit={(
          { currentPass, newPass, confirmPass },
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            currentPass,
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return (
            <>
            <Container component="main" maxWidth="xs">
              {isSubmitting && (
                <Backdrop className={classes.backdrop} open sx={{ zIndex: 1 }}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <form className={classes.form} onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" variant="standard">
                  <TextField
                    id="password-current"
                    name="currentPass"
                    label={t('password.currentPassword')}
                    type="password"
                    variant="outlined"
                    value={values.currentPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.currentPass && errors.currentPass)}
                  >
                    {touched.currentPass && errors.currentPass
                      ? errors.currentPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.newPass && errors.newPass)}
                  variant="standard">
                  <TextField
                    id="password-new"
                    name="newPass"
                    label={t('password.newPassword')}
                    type="password"
                    variant="outlined"
                    value={values.newPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                  variant="standard">
                  <TextField
                    id="password-confirm"
                    name="confirmPass"
                    label={t('password.confirmPassword')}
                    type="password"
                    variant="outlined"
                    value={values.confirmPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {touched.confirmPass && errors.confirmPass
                      ? errors.confirmPass
                      : ''}
                  </FormHelperText>
                </FormControl>
                <Button
                  type="submit"
                  variant="contained" 
                  color="primary"
                  disabled={Boolean(!isValid || isSubmitting)}
                  className={classes.submit}
                >
                  {t('password.changePassword')}
                </Button>
              </form>
            </Container>
            </>
          )
        }}
      />
      </Box>
      </AccountDashboardLayout>
    );
  }
}

export default compose(withTranslation(),withStyles(useStyles)) (ChangePassword)