import { format, isValid } from "date-fns";
import {
  Paper,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import {
  StatisticTable,
} from "src/components/StyledComponents";
import TabPanel from "src/components/TabPanel";

const General = ({ value, index, statistic, t, ...other }) => {

  return (
    <TabPanel value={value} index={index} dir="ltr">
      <Typography variant="h5" align="center" gutterBottom>
        Allgemein
      </Typography>
      <TableContainer component={Paper}>
        <StatisticTable aria-label="statistic-details">
          <TableBody>
            <TableRow>
              <TableCell>Anzahl Spiele</TableCell>
              <TableCell align="right">
                {statistic?.cnt_games > 0 ? statistic?.cnt_games : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Davon Hauptspiele</TableCell>
              <TableCell align="right">
                {statistic?.cnt_maingames > 0 ? statistic?.cnt_maingames : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Davon Erweiterungen</TableCell>
              <TableCell align="right">
                {statistic?.cnt_extensions > 0
                  ? statistic?.cnt_extensions
                  : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anzahl Spiele im Archiv</TableCell>
              <TableCell align="right">
                {statistic?.cnt_archived_games > 0
                  ? statistic?.cnt_archived_games
                  : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anzahl Leihen</TableCell>
              <TableCell align="right">
                {statistic?.cnt_lends > 0
                  ? `${statistic?.cnt_lends} (${statistic?.cnt_lends_active})`
                  : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Anzahl Partien</TableCell>
              <TableCell align="right">
                {statistic?.cnt_matches > 0 ? statistic?.cnt_matches : "0"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Meist gespieltes Spiel</TableCell>
              {statistic?.cnt_matches > 0 ? (
                <TableCell
                  component={Link}
                  to={`/games/${statistic.most_played_game?.id}`}
                  align="right"
                >
                  {statistic?.most_played_game?.title} (
                  {statistic?.most_played_game?.cnt_matches})
                </TableCell>
              ) : (
                <TableCell align="right">n/A</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Meist gespielt am</TableCell>
              <TableCell align="right">
                {statistic?.most_played_date?.date !== undefined &&
                isValid(new Date(statistic?.most_played_date?.date))
                  ? `${format(
                      new Date(statistic?.most_played_date?.date),
                      "dd.MM.yyyy"
                    )} (${statistic?.most_played_date?.cnt_matches})`
                  : "n/A"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Spiel mit den meisten Erweiterungen</TableCell>
              {statistic?.most_extensions?.cnt_extensions > 0 ? (
                <TableCell
                  component={Link}
                  to={`/games/${statistic?.most_extensions?.id}`}
                  align="right"
                >
                  {statistic?.most_extensions?.title} (
                  {statistic?.most_extensions?.cnt_extensions})
                </TableCell>
              ) : (
                <TableCell align="right">n/A</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Spieler mit den meisten Partien</TableCell>
              {statistic?.most_played_player?.name ? (
                <TableCell align="right">
                  {statistic?.most_played_player?.name} (
                  {statistic?.most_played_player?.cnt_matches})
                </TableCell>
              ) : (
                <TableCell align="right">n/A</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Spieler mit den meisten Siegen</TableCell>
              {statistic?.most_wins_player?.name ? (
                <TableCell align="right">
                  {statistic?.most_wins_player?.name} (
                  {statistic?.most_wins_player?.cnt_wins})
                </TableCell>
              ) : (
                <TableCell align="right">n/A</TableCell>
              )}
            </TableRow>
            <TableRow>
              <TableCell>Spieler mit der besten Siegesrate</TableCell>
              {statistic?.best_winrate_player?.name ? (
                <TableCell align="right">
                  {statistic?.best_winrate_player?.name} (
                  {statistic?.best_winrate_player?.winrate}%)
                </TableCell>
              ) : (
                <TableCell align="right">n/A</TableCell>
              )}
            </TableRow>
          </TableBody>
        </StatisticTable>
      </TableContainer>
    </TabPanel>
  );
};
export default General;
