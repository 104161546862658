import React, { Component } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  Container,
  TextField,
  Box,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import UserDataService from "../../../services/user.service";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

const useStyles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  contentBox: {
    width: '100%',
    maxWidth: 450,
  }
});

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "account.editProfile", help: "help.editProfile"})
  }

  state = {
    user: [],
  };

  componentDidMount() {
    this.retrieveUserDetails();
  }

  retrieveUserDetails() {
    UserDataService.getDetails()
      .then((response) => {
        this.setState({
          user: response.data[0],
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  _handleSubmit = ({
    firstname,
    lastname,
    email,
    setSubmitting,
    setFieldError,
    resetForm,
  }) => {
    var data = { firstname: firstname, lastname: lastname, email: email };
    UserDataService.updateSingle(data)
      .then((response) => {
        setSubmitting(false);
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Das Profil wurde erfolgreich bearbeitet",
          key: Math.random(),
        });
      })
      .catch((error) => {
        setSubmitting(false);
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: "Fehler beim Bearbeiten des Profils",
          errorList: error.response.data,
          key: Math.random(),
        });
      });
  };

  render() {
    const { classes, t } = this.props;
    const { user } = this.state;
    return (
      <AccountDashboardLayout>
        <Box className={classes.contentBox}>
          <Formik
            enableReinitialize
            initialValues={{
              username: user.username,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
            }}
            validationSchema={object().shape({
              firstname: string().required(t("validation.required.firstname")),
              lastname: string().required(t("validation.required.lastname")),
              email: string()
                .required(t("validation.required.email"))
                .email(t("validation.valid.email")),
            })}
            onSubmit={(
              { firstname, lastname, email },
              { setSubmitting, setStatus, setFieldError, resetForm }
            ) =>
              this._handleSubmit({
                firstname,
                lastname,
                email,
                setSubmitting,
                setStatus,
                setFieldError,
                resetForm,
              })
            }
            render={(props) => {
              const {
                values,
                touched,
                errors,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
              } = props;
              return (
                <>
                  <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    {isSubmitting && (
                      <Backdrop className={classes.backdrop} open sx={{ zIndex: 1 }}>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )}
                    <form className={classes.form} onSubmit={handleSubmit}>
                      <FormControl fullWidth margin="normal" variant="standard">
                        <TextField
                          id="username"
                          label={t("personal.username")}
                          name="username"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.username}
                          disabled={true}
                        />
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="standard">
                        <TextField
                          id="firstname"
                          label={t("personal.firstname")}
                          name="firstname"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.firstname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.firstname && errors.firstname)}
                        />
                        <FormHelperText
                          error={Boolean(touched.firstname && errors.firstname)}
                        >
                          {touched.firstname && errors.firstname
                            ? errors.firstname
                            : ""}
                        </FormHelperText>
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="standard">
                        <TextField
                          id="lastname"
                          label={t("personal.lastname")}
                          name="lastname"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.lastname}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.lastname && errors.lastname)}
                        />
                        <FormHelperText
                          error={Boolean(touched.lastname && errors.lastname)}
                        >
                          {touched.lastname && errors.lastname
                            ? errors.lastname
                            : ""}
                        </FormHelperText>
                      </FormControl>
                      <FormControl fullWidth margin="normal" variant="standard">
                        <TextField
                          id="email"
                          label={t("personal.email")}
                          name="email"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.email && errors.email)}
                        />
                        <FormHelperText
                          error={Boolean(touched.email && errors.email)}
                        >
                          {touched.email && errors.email ? errors.email : ""}
                        </FormHelperText>
                      </FormControl>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={Boolean(!isValid || isSubmitting)}
                        className={classes.submit}
                      >
                        {t("save")}
                      </Button>
                    </form>
                  </Container>
                </>
              );
            }}
          />
        </Box>
      </AccountDashboardLayout>
    );
  }
}

export default compose(withTranslation(), withStyles(useStyles))(EditProfile);
