import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  CssBaseline,
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Container,
  Checkbox,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { createFilterOptions } from "@mui/material/Autocomplete";
import CollectionDataService from "../../services/collection.service";
import PlayerDataService from "../../services/player.service";
import PermissionDataService from "../../services/permission.service";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { withTranslation } from "react-i18next";
import { compose } from "recompose";
import { useHeaderStore } from "src/store/store";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";

const filter = createFilterOptions();

const useStyles = (theme) => ({
  submit: {
    display: "block",
    margin: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  form: {
    margin: "16px"
  }
});

class EditUser extends Component {
  constructor(props) {
    super(props);

    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "permission.modifyPermission_one", help: "help.modifyPermission"});
    this.state = {
      collection: this.props.match.params.collection_id,
      permissionID: this.props.match.params.permission_id,
      privileges: {},

      newPlayerOpen: false,
      newPlayerName: "",
      allPlayers: [],

      username: "",
      description: "",
      player: "",

      game_view: false,
      game_insert: false,
      game_modify: false,
      game_delete: false, 
      match_view: false,
      match_insert: false,
      match_modify: false,
      match_delete: false, 
      lend_view: false,
      lend_insert: false,
      lend_modify: false,
      lend_delete: false,
      collectiondetail_view: false,
      collectiondetail_insert: false,
      collectiondetail_modify: false,
      collectiondetail_delete: false,
      permission_view: false,
      permission_insert: false,
      permission_modify: false,
      permission_delete: false, 
      statistic_view: false,
    };
  }

  componentDidMount() {
    this.retrieveCollectionDetails(this.state.collection);
    this.retrievePermission(this.state.permissionID, this.state.collection);
    this.retrievePlayers(this.state.collection);
  }

  retrieveCollectionDetails(id) {
    CollectionDataService.get(id).then((response) => {
      this.setState({
        collectionName: response.data.data[0].collection_name,
        privileges: response.data.meta.privileges,
      });
    });
  }

  retrievePermission(permissionID, collection) {
    PermissionDataService.get(permissionID, collection)
      .then((response) => {
        this.setState({
          username: response.data.data[0].username,
          description: response.data.data[0].description,
          player: {
            id: response.data.data[0].player_id,
            name: response.data.data[0].player_name,
          },
          game_view: response.data.data[0].game_view,
          game_insert: response.data.data[0].game_insert,
          game_modify: response.data.data[0].game_modify,
          game_delete: response.data.data[0].game_delete,
          match_view: response.data.data[0].match_view,
          match_insert: response.data.data[0].match_insert,
          match_modify: response.data.data[0].match_modify,
          match_delete: response.data.data[0].match_delete,
          lend_view: response.data.data[0].lend_view,
          lend_insert: response.data.data[0].lend_insert,
          lend_modify: response.data.data[0].lend_modify,
          lend_delete: response.data.data[0].lend_delete,
          collectiondetail_view: response.data.data[0].collectiondetail_view,
          collectiondetail_insert: response.data.data[0].collectiondetail_insert,
          collectiondetail_modify: response.data.data[0].collectiondetail_modify,
          collectiondetail_delete: response.data.data[0].collectiondetail_delete,
          permission_view: response.data.data[0].permission_view,
          permission_insert: response.data.data[0].permission_insert,
          permission_modify: response.data.data[0].permission_modify,
          permission_delete: response.data.data[0].permission_delete,
          statistic_view: response.data.data[0].statistic_view
        });
      })
      .catch((error) => {
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: "Der Benutzer konnte nicht gefunden werden",
          key: Math.random(),
        });
        this.props.history.push(
          `/settings/collections//${this.state.collection}/permissions`
        );
      });
  }

  retrievePlayers(collection) {
    PlayerDataService.getUnassigned(collection).then((response) => {
      this.setState({
        allPlayers: response.data.data.players,
      });
      this.props.setStateValue("isLoading", false);
    });
  }

  toggleNewPlayerOpen(newPlayerName) {
    this.setState({
      newPlayerOpen: true,
      newPlayerName: newPlayerName,
    });
  }

  newPlayerDialog(classes) {
    const handleDialogClose = () => {
      this.setState({
        newPlayerOpen: false,
      });
    };

    const handleNewPlayerAdd = ({
      playername,
      description,
      setSubmitting,
      setError,
      resetForm,
    }) => {
      var data = {
        playername: playername,
        description: description,
        collectionID: this.state.collection,
      };
      PlayerDataService.create(data)
      .then((response) => {
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Der Spieler wurde erfolgreich hinzugefügt",
          key: Math.random(),
        });
        handleDialogClose();
        this.setState({
          player: response.data.data.player
        })
        this.state.allPlayers.push(response.data.data.player)
        this.state.allPlayers.sort(this.sortFunction)
      })
      .catch((e) => {
        setSubmitting(false);
        if (e.response?.data.data.message) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Hinzufügen des Mitspielers",
            key: Math.random(),
          });
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Hinzufügen des Mitspielers",
            key: Math.random(),
          });
          this.setState({
            addFailure: true,
          });
          console.log(e);
        }
      });
    };

    return (
      <Formik
        enableReinitialize
        initialValues={{
          playername: this.state.newPlayerName,
          description: "",
        }}
        validationSchema={Yup.object().shape({
          playername: Yup.string().required(
            "Bitte einen Spielernamen eingeben"
          ),
          description: Yup.string().max(
            30,
            "Der Hinweis darf maximal 30 Zeichen lang sein"
          ),
        })}
        onSubmit={(
          { playername, description },
          { setSubmitting, setStatus, setError, resetForm }
        ) =>
          handleNewPlayerAdd({
            playername,
            description,
            setSubmitting,
            setStatus,
            resetForm,
          })
        }
        render={(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          } = props;
          return (
            <>
              <CssBaseline />
              {isSubmitting && (
                <Backdrop className={classes.backdrop} open>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
              <Dialog
                open="open"
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Mitspieler zur Sammlung hinzufügen
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="playername"
                            label="Mitspieler"
                            name="playername"
                            value={values.playername}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.playername && errors.playername
                            )}
                            autoComplete="off"
                            variant="standard"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.playername && errors.playername
                            )}
                          >
                            {touched.playername && errors.playername
                              ? errors.playername
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="description"
                            label="Hinweis"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            autoComplete="off"
                            variant="standard"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          >
                            {touched.description && errors.description
                              ? errors.description
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogClose} color="error">
                    Abbrechen
                  </Button>
                  <Button onClick={handleSubmit} color="primary">
                    Speichern
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    );
  }

  _handleSubmit = ({
    username,
    description,
    player,
    game_view,
    game_insert,
    game_modify,
    game_delete,
    match_view,
    match_insert,
    match_modify,
    match_delete,
    lend_view,
    lend_insert,
    lend_modify,
    lend_delete,
    collectiondetail_view,
    collectiondetail_insert,
    collectiondetail_modify,
    collectiondetail_delete,
    permission_view,
    permission_insert,
    permission_modify,
    permission_delete,
    statistic_view,
    setSubmitting,
    setError,
    resetForm,
  }) => {
    var data = {
      username: username,
      description: description,
      player: player,
      collectionID: this.state.collection,
      game_view: game_view,
      game_insert: game_insert,
      game_modify: game_modify,
      game_delete: game_delete,
      match_view: match_view,
      match_insert: match_insert,
      match_modify: match_modify,
      match_delete: match_delete,
      lend_view: lend_view,
      lend_insert: lend_insert,
      lend_modify: lend_modify,
      lend_delete: lend_delete,
      collectiondetail_view: collectiondetail_view,
      collectiondetail_insert: collectiondetail_insert,
      collectiondetail_modify: collectiondetail_modify,
      collectiondetail_delete: collectiondetail_delete,
      permission_view: permission_view,
      permission_insert: permission_insert,
      permission_modify: permission_modify,
      permission_delete: permission_delete,
      statistic_view: statistic_view
    };
    PermissionDataService.update(this.state.permissionID, data)
      .then((response) => {
        this.props.setStateValue("alertMessage", {
          severity: "success",
          message: "Der Benutzer wurde erfolgreich bearbeitet",
          key: Math.random(),
        });
        this.props.history.push(
          `/settings/collections/${this.state.collection}/permissions`
        );
      })
      .catch((e) => {
        setSubmitting(false);
        if (e.response.data.data.message) {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: e.response.data.data.message,
            key: Math.random(),
          });
        } else {
          this.props.setStateValue("alertMessage", {
            severity: "error",
            message: "Fehler beim Hinzufügen des Benutzers",
            key: Math.random(),
          });
          console.log(e);
        }
      });
  };

  render() {
    const { classes } = this.props;
    const {
      username,
      description,
      privileges,
      player,
      allPlayers,
      newPlayerOpen,
      game_view,
      game_insert,
      game_modify, 
      game_delete,
      match_view,
      match_insert,
      match_modify,
      match_delete,
      lend_view,
      lend_insert,
      lend_modify,
      lend_delete,
      collectiondetail_view,
      collectiondetail_insert,
      collectiondetail_modify,
      collectiondetail_delete,
      permission_view,
      permission_insert,
      permission_modify,
      permission_delete,
      statistic_view
    } = this.state;

    if (privileges.owner === 1) {
      return (
        <>
          {newPlayerOpen ? this.newPlayerDialog(classes) : null}
          <Formik
            enableReinitialize
            initialValues={{
              username: username,
              description: description,
              player: player,
              game_view: game_view,
              game_insert: game_insert,
              game_modify: game_modify,
              game_delete: game_delete,
              match_view: match_view,
              match_insert: match_insert,
              match_modify: match_modify,
              match_delete: match_delete,
              lend_view: lend_view,
              lend_insert: lend_insert,
              lend_modify: lend_modify,
              lend_delete: lend_delete,
              collectiondetail_view: collectiondetail_view,
              collectiondetail_insert: collectiondetail_insert,
              collectiondetail_modify: collectiondetail_modify,
              collectiondetail_delete: collectiondetail_delete,
              permission_view: permission_view,
              permission_insert: permission_insert,
              permission_modify: permission_modify,
              permission_delete: permission_delete,
              statistic_view:statistic_view,              
            }}
            validationSchema={Yup.object().shape({
              description: Yup.string().max(
                30,
                "Der Hinweis darf maximal 30 Zeichen lang sein"
              ),
            })}
            onSubmit={(
              { username, description, player, game_view, game_insert, game_modify,  game_delete, match_view, match_insert, match_modify, match_delete, lend_view, lend_insert, lend_modify, lend_delete, collectiondetail_view, collectiondetail_insert, collectiondetail_modify, collectiondetail_delete, permission_view, permission_insert, permission_modify, permission_delete, statistic_view },
              { setSubmitting, setStatus, setError, resetForm }
            ) =>
              this._handleSubmit({
                username,
                description,
                player,
                game_view,
                game_insert,
                game_modify, 
                game_delete,
                match_view,
                match_insert,
                match_modify,
                match_delete,
                lend_view,
                lend_insert,
                lend_modify,
                lend_delete,
                collectiondetail_view,
                collectiondetail_insert,
                collectiondetail_modify,
                collectiondetail_delete,
                permission_view,
                permission_insert,
                permission_modify,
                permission_delete,
                statistic_view,
                setSubmitting,
                setStatus,
                resetForm,
              })
            }
            render={(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
                isSubmitting,
                setFieldValue,
              } = props;
              return (
                <>
                  <CssBaseline />
                  <AccountDashboardLayout>
                  {isSubmitting && (
                    <Backdrop className={classes.backdrop} open>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="username"
                            label="Benutzername"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(touched.username && errors.username)}
                            disabled
                            variant="outlined"
                          />
                          <FormHelperText
                            error={Boolean(touched.username && errors.username)}
                          >
                            {touched.username && errors.username
                              ? errors.username
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl fullWidth variant="standard">
                          <TextField
                            id="description"
                            label="Hinweis"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            autoComplete="off"
                            variant="outlined"
                          />
                          <FormHelperText
                            error={Boolean(
                              touched.description && errors.description
                            )}
                          >
                            {touched.description && errors.description
                              ? errors.description
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} xl={6}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.player && errors.player)}
                          variant="standard"
                        >
                          <Autocomplete
                            name="player"
                            id="player"
                            value={values.player?.id ? values.player : null}
                            options={allPlayers}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              if (params.inputValue !== "") {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  name: `"${params.inputValue}" zur Sammlung hinzufügen`,
                                });
                              }
                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              if (typeof option === "string") {
                                return option;
                              }
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              return option.name;
                            }}
                            renderOption={(props, option) => (
                              <li {...props}>{option.name}</li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Verknüpfen mit Mitspieler"
                                inputProps={{
                                  ...params.inputProps,
                                  "aria-autocomplete": "none",
                                }}
                                variant="outlined"
                              />
                            )}
                            onChange={(event, newValue) => {
                              if (typeof newValue === "string") {
                                setTimeout(() => {
                                  this.toggleNewPlayerOpen(newValue);
                                });
                              } else if (newValue && newValue.inputValue) {
                                this.toggleNewPlayerOpen(newValue.inputValue);
                              } else {
                                setFieldValue("player", newValue);
                              }
                            }}
                            onBlur={handleBlur}
                            error={Boolean(touched.player && errors.player)}
                            freeSolo
                          />
                          <FormHelperText
                            error={Boolean(touched.player && errors.player)}
                          >
                            {touched.player && errors.player
                              ? errors.player
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} xl={6} display={{ xs: "none", xl: "grid" }} />
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="games-content"
                            id="games-header"
                          >
                            <FormControlLabel
                              label="Spiele"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="game"
                                checked={values.game_view && values.game_insert && values.game_modify && values.game_delete}
                                indeterminate={values.game_view !== values.game_insert || (values.game_view !== values.game_modify || values.game_view !== values.game_delete)}
                                style={{zIndex:1000}}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("game_view", value);
                                  setFieldValue("game_insert", value);
                                  setFieldValue("game_modify", value);
                                  setFieldValue("game_delete", value);      
                                  if(value === false) {
                                    setFieldValue("match_view", value);
                                    setFieldValue("match_insert", value);
                                    setFieldValue("match_modify", value);
                                    setFieldValue("match_delete", value);
                                    setFieldValue("lend_view", value);
                                    setFieldValue("lend_insert", value);
                                    setFieldValue("lend_modify", value);
                                    setFieldValue("lend_delete", value);
                                  }
                                  if(value === true) {                            
                                    setFieldValue("collectiondetail_view", value);  
                                  }
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Ansehen"
                                    control={<Checkbox 
                                      id="game_view"
                                      checked={values.game_view}
                                      onChange={(e, value) => {
                                        setFieldValue("game_view", value);
                                        if(value === false) {
                                          setFieldValue("game_insert", value);
                                          setFieldValue("game_modify", value);
                                          setFieldValue("game_delete", value);
                                          setFieldValue("match_view", value);
                                          setFieldValue("match_insert", value);
                                          setFieldValue("match_modify", value);
                                          setFieldValue("match_delete", value);
                                          setFieldValue("lend_view", value);
                                          setFieldValue("lend_insert", value);
                                          setFieldValue("lend_modify", value);
                                          setFieldValue("lend_delete", value);
                                        }
                                        if(value === true) {                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Erfassen"
                                    control={<Checkbox
                                      id="game_insert"
                                      checked={values.game_insert}
                                      onChange={(e, value) => {
                                        setFieldValue("game_insert", value);
                                        if(value === true){
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Bearbeiten"
                                    id="game_modify"
                                    control={<Checkbox
                                      id="game_modify"
                                      checked={values.game_modify}
                                      onChange={(e, value) => {
                                        setFieldValue("game_modify", value);
                                        if(value === true){
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        } else if(value === false) {
                                          setFieldValue("game_delete", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Löschen"
                                    control={<Checkbox
                                      id="game_delete"
                                      checked={values.game_delete}
                                      onChange={(e, value) => {
                                        setFieldValue("game_delete", value);
                                        if(value === true){
                                          setFieldValue("game_modify", value);
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="match-content"
                            id="match-header"
                          >
                            <FormControlLabel
                              label="Partien"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="match"
                                checked={values.match_view && values.match_insert && values.match_modify && values.match_delete}
                                indeterminate={values.match_view !== values.match_insert || (values.match_view !== values.match_modify || values.match_view !== values.match_delete)}
                                style={{zIndex:1000}}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("match_view", value);
                                  setFieldValue("match_insert", value);
                                  setFieldValue("match_modify", value);
                                  setFieldValue("match_delete", value);
                                  if(value === true) {
                                    setFieldValue("game_view", value);                            
                                    setFieldValue("collectiondetail_view", value);  
                                  }       
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Ansehen (inkl. Leaderboard)"
                                    control={<Checkbox
                                      id="match_view"
                                      checked={values.match_view}
                                      onChange={(e, value) => {
                                        setFieldValue("match_view", value);
                                        if(value === false) {
                                          setFieldValue("match_insert", value);
                                          setFieldValue("match_modify", value);
                                          setFieldValue("match_delete", value);
                                        } else if(value === true) {
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }       
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Erfassen"
                                    control={<Checkbox
                                      id="match_insert"
                                      checked={values.match_insert}
                                      onChange={(e, value) => {
                                        setFieldValue("match_insert", value);
                                        if(value === true){
                                          setFieldValue("match_view", value);
                                          setFieldValue("game_view", value);
                                          setFieldValue("collectiondetail_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Bearbeiten"
                                    control={<Checkbox
                                      id="match_modify"
                                      checked={values.match_modify}
                                      onChange={(e, value) => {
                                        setFieldValue("match_modify", value);
                                        if(value === true){
                                          setFieldValue("match_view", value);
                                          setFieldValue("game_view", value);
                                          setFieldValue("collectiondetail_view", value);
                                        } else if(value === false) {
                                          setFieldValue("match_delete", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Löschen"
                                    control={<Checkbox
                                      id="match_delete"
                                      checked={values.match_delete}
                                      onChange={(e, value) => {
                                        setFieldValue("match_delete", value);
                                        if(value === true){
                                          setFieldValue("match_modify", value);
                                          setFieldValue("match_view", value);
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="lend-content"
                            id="lend-header"
                          >
                            <FormControlLabel
                              label="Leihen"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="lend"
                                checked={values.lend_view && values.lend_insert && values.lend_modify && values.lend_delete}
                                indeterminate={values.lend_view !== values.lend_insert || (values.lend_view !== values.lend_modify || values.lend_view !== values.lend_delete)}
                                style={{zIndex:1000}}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("lend_view", value);
                                  setFieldValue("lend_insert", value);
                                  setFieldValue("lend_modify", value);
                                  setFieldValue("lend_delete", value);   
                                  if(value === true) {
                                    setFieldValue("game_view", value);
                                    setFieldValue("collectiondetail_view", value);
                                  }           
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Ansehen"
                                    control={<Checkbox
                                      id="lend_view"
                                      checked={values.lend_view}
                                      onChange={(e, value) => {
                                        setFieldValue("lend_view", value);
                                        if(value === false) {
                                          setFieldValue("lend_insert", value);
                                          setFieldValue("lend_modify", value);
                                          setFieldValue("lend_delete", value);
                                        } else if(value === true) {
                                          setFieldValue("game_view", value);
                                          setFieldValue("collectiondetail_view", value);
                                        }       
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Erfassen"
                                    control={<Checkbox
                                      id="lend_insert"
                                      checked={values.lend_insert}
                                      onChange={(e, value) => {
                                        setFieldValue("lend_insert", value);
                                        if(value === true){
                                          setFieldValue("lend_view", value);
                                          setFieldValue("game_view", value);
                                          setFieldValue("collectiondetail_view", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Bearbeiten"
                                    control={<Checkbox
                                      id="lend_modify"
                                      checked={values.lend_modify}
                                      onChange={(e, value) => {
                                        setFieldValue("lend_modify", value);
                                        if(value === true){
                                          setFieldValue("lend_view", value);
                                          setFieldValue("game_view", value);
                                          setFieldValue("collectiondetail_view", value);
                                        } else if(value === false) {
                                          setFieldValue("lend_delete", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Löschen"
                                    control={<Checkbox
                                      id="lend_delete"
                                      checked={values.lend_delete}
                                      onChange={(e, value) => {
                                        setFieldValue("lend_delete", value);
                                        if(value === true){
                                          setFieldValue("lend_modify", value);
                                          setFieldValue("lend_view", value);
                                          setFieldValue("game_view", value);                            
                                          setFieldValue("collectiondetail_view", value);  
                                        }
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="collectiondetail-content"
                            id="collectiondetail-header"
                          >
                            <FormControlLabel
                              label="Sammlungsdetails"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="collectiondetail"
                                checked={values.collectiondetail_view && values.collectiondetail_insert && values.collectiondetail_modify && values.collectiondetail_delete}
                                indeterminate={values.collectiondetail_view !== values.collectiondetail_insert || (values.collectiondetail_view !== values.collectiondetail_modify || values.collectiondetail_view !== values.collectiondetail_delete)}
                                style={{zIndex:1000}}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("collectiondetail_view", value);
                                  setFieldValue("collectiondetail_insert", value);
                                  setFieldValue("collectiondetail_modify", value);
                                  setFieldValue("collectiondetail_delete", value);           
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Ansehen"
                                    control={<Checkbox
                                      id="collectiondetail_view"
                                      checked={values.collectiondetail_view}
                                      onChange={(e, value) => {
                                        setFieldValue("collectiondetail_view", value);
                                        if(value === false) {
                                          setFieldValue("collectiondetail_insert", value);
                                          setFieldValue("collectiondetail_modify", value);
                                          setFieldValue("collectiondetail_delete", value);
                                          setFieldValue("game_view", value);
                                          setFieldValue("game_insert", value);
                                          setFieldValue("game_modify", value);
                                          setFieldValue("game_delete", value);
                                          setFieldValue("match_view", value);
                                          setFieldValue("match_insert", value);
                                          setFieldValue("match_modify", value);
                                          setFieldValue("match_delete", value);
                                          setFieldValue("lend_view", value);
                                          setFieldValue("lend_insert", value);
                                          setFieldValue("lend_modify", value);
                                          setFieldValue("lend_delete", value);
                                        }   
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Erfassen"
                                    control={<Checkbox
                                      id="collectiondetail_insert"
                                      checked={values.collectiondetail_insert}
                                      onChange={(e, value) => {
                                        setFieldValue("collectiondetail_insert", value);
                                        if(value === true){
                                          setFieldValue("collectiondetail_view", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Bearbeiten"
                                    control={<Checkbox
                                      id="collectiondetail_modify"
                                      checked={values.collectiondetail_modify}
                                      onChange={(e, value) => {
                                        setFieldValue("collectiondetail_modify", value);
                                        if(value === true){
                                          setFieldValue("collectiondetail_view", value);
                                        } else if(value === false) {
                                          setFieldValue("collectiondetail_delete", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Löschen"
                                    control={<Checkbox
                                      id="collectiondetail_delete"
                                      checked={values.collectiondetail_delete}
                                      onChange={(e, value) => {
                                        setFieldValue("collectiondetail_delete", value);
                                        if(value === true){
                                          setFieldValue("collectiondetail_modify", value);
                                          setFieldValue("collectiondetail_view", value);
                                        }
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      {/*<Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="permission-content"
                            id="permission-header"
                          >
                            <FormControlLabel
                              label="Berechtigungen"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="permission"
                                checked={values.permission_view && values.permission_insert && values.permission_modify && values.permission_delete}
                                indeterminate={values.permission_view !== values.permission_insert || (values.permission_view !== values.permission_modify || values.permission_view !== values.permission_delete)}
                                style={{zIndex:1000}}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("permission_view", value);
                                  setFieldValue("permission_insert", value);
                                  setFieldValue("permission_modify", value);
                                  setFieldValue("permission_delete", value);           
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Ansehen"
                                    control={<Checkbox
                                      id="permission_view"
                                      checked={values.permission_view}
                                      onChange={(e, value) => {
                                        setFieldValue("permission_view", value);
                                        if(value === false) {
                                          setFieldValue("permission_insert", value);
                                          setFieldValue("permission_modify", value);
                                          setFieldValue("permission_delete", value);
                                        }   
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Erfassen"
                                    control={<Checkbox
                                      id="permission_insert"
                                      checked={values.permission_insert}
                                      onChange={(e, value) => {
                                        setFieldValue("permission_insert", value);
                                        if(value === true){
                                          setFieldValue("permission_view", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Bearbeiten"
                                    control={<Checkbox
                                      id="permission_modify"
                                      checked={values.permission_modify}
                                      onChange={(e, value) => {
                                        setFieldValue("permission_modify", value);
                                        if(value === true){
                                          setFieldValue("permission_view", value);
                                        } else if(value === false) {
                                          setFieldValue("permission_delete", value);
                                        }
                                      }}
                                      />}
                                  />
                                  <FormControlLabel
                                    label="Löschen"
                                    control={<Checkbox
                                      id="permission_delete"
                                      checked={values.permission_delete}
                                      onChange={(e, value) => {
                                        setFieldValue("permission_delete", value);
                                        if(value === true){
                                          setFieldValue("permission_modify", value);
                                          setFieldValue("permission_view", value);
                                        }
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                                    </Grid>*/}
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={3}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="miscellaneous-content"
                            id="miscellaneous-header"
                          >
                            <FormControlLabel
                              label="Sonstiges"
                              labelPlacement="start"
                              onClick={(e) => { e.stopPropagation(); }}
                              control={<Checkbox
                                id="miscellaneous"
                                checked={values.statistic_view}
                                onClick={(e) => { e.stopPropagation(); }}
                                onChange={(e, value) => {
                                  setFieldValue("statistic_view", value);     
                                }}
                                />}
                            />
                          </AccordionSummary>
                          <AccordionDetails>
                            <Container>
                              <FormControl component="fieldset">
                                <FormGroup aria-label="position" column>
                                  <FormControlLabel
                                    label="Statistik ansehen"
                                    control={<Checkbox
                                      id="statistic_view"
                                      checked={values.statistic_view}
                                      onChange={(e, value) => {
                                        setFieldValue("statistic_view", value);     
                                      }}
                                      />}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Container>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={Boolean(!isValid || isSubmitting)}
                          className={classes.submit}
                        >
                          {"Speichern"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  </AccountDashboardLayout>
                </>
              );
            }}
          />
        </>
      );
    }
    return <></>;
  }
}

export default compose(withTranslation(), withStyles(useStyles))(EditUser);
