import { BehaviorSubject } from "rxjs";
import { authHeader } from "../helpers/auth-header";
import { handleResponse } from "../helpers/handle-response";
import SessionDataService from "../services/session.service";

const base_url = process.env.REACT_APP_BASE_URL;

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${base_url}/api/users/login`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      let userSubject =  response.auth;
      userSubject = {...userSubject, ...response.account}
      localStorage.setItem("currentUser", JSON.stringify(userSubject));
      localStorage.setItem("collectionID", response.session.collectionID);
      localStorage.setItem("session", response.session.sessionToken);
      currentUserSubject.next(userSubject);

      return response.auth;
    });
}

function logout() {
  const session_token = localStorage.getItem("session");
  SessionDataService.delete(session_token).then(response => {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("collectionID");
    localStorage.removeItem("session");
    currentUserSubject.next();
    authHeader();
  });
}
