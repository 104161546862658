import React, { Component } from "react";
import { authenticationService } from "../../auth/authentication.service";
import { Formik } from "formik";
import { object, string } from "yup";
import {
  CssBaseline,
  Backdrop,
  CircularProgress,
  FormControl,
  FormHelperText,
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CategoryIcon from "@mui/icons-material/Category";
import CasinoIcon from "@mui/icons-material/Casino";
import GroupsIcon from "@mui/icons-material/Groups";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import loginBackground from '../../images/login_background.jpg'

function Copyright(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center" {...props}>
      <Link color="inherit" href="/">
        Über uns
      </Link>
      {" | "}
      <Link color="inherit" href="/privacy">
        Datenschutz
      </Link>
      {" | "}
      <Link color="inherit" href="/legalNotice">
        Impressum
      </Link>
      {" | © "}
      <Link color="inherit" href="/">
        Spieleordner
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function LearnMore(props) {
  const { classes, myRef } = props;
  return (
    <Box sx={{ display: "block", bottom: "0px", top: "auto" }}>
      {" "}
      <Button
        variant="contained"
        color="primary"
        className={classes.learnMore}
        startIcon={<ArrowCircleDownIcon />}
        endIcon={<ArrowCircleDownIcon />}
        onClick={() => myRef.current.scrollIntoView({ behavior: "smooth" })}
      >
        {"Lerne Spieleordner kennen"}
      </Button>
    </Box>
  );
}

const useStyles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  leftSideGrid: {
    [theme.breakpoints.down("md")]: {
      minHeight: "100vh",
      paddingBottom: theme.spacing(1),
      order: 2,
    },
  },
  rightSideGrid: {
    [theme.breakpoints.down("md")]: {
      width: '100vw',
      minHeight: "100vh",
      paddingBottom: theme.spacing(1),
      order: 1,
    },
  },
  featurePaper: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.up("xxl")]: {
      maxWidth: "50%",
    },
    margin: "auto",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "1rem",
  },
  featureIcon: {
    color: "#547890",
    [theme.breakpoints.down("md")]: {
      fontSize: 40,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 80,
    },
    [theme.breakpoints.only("md")]: {
      fontSize: 60,
    },
  },
  featureHeader: {
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  featureBody: {
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
      lineHeight: "1.2",
    },
  },
  learnMore: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    borderRadius: "1rem",
    margin: "24px 0 16px 0",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.props.setStateValue("isLoading", true);
    // redirect to home if already logged in
    if (authenticationService.currentUserValue) {
      //const { from } = this.props.location.pathname || { from: { pathname: "/" } }; console.log(from)
      const from = this.props.location.pathname || "/";
      const query = this.props.location.search || "";
      this.props.history.push({ pathname: from, search: query });
    }
    this.state = {};
  }

  componentDidMount() {
    this.props.setStateValue("isLoading", false);
  }

  _handleSubmit = ({
    username,
    password,
    setSubmitting,
    setError,
    resetForm,
  }) => {
    authenticationService.login(username, password).then(
      (user) => {
        const { from } = this.props.location.pathname || {
          from: { pathname: "/" },
        };
        this.props.history.push(from);
      },
      (error) => {
        setSubmitting(false);
        this.props.setStateValue("alertMessage", {
          severity: "error",
          message: "Fehler bei der Anmeldung",
          key: Math.random(),
        });
      }
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          ref={this.myRef}
          className={classes.leftSideGrid}
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${loginBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <Paper className={classes.featurePaper}>
              <Grid
                container
                component="div"
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: "16px" }}>
                  <Grid
                    container
                    component="div"
                    sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Box>
                        <CategoryIcon className={classes.featureIcon} />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.featureHeader}
                        >
                          Bring Ordnung in deine Sammlung
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                          className={classes.featureBody}
                        >
                          Mit Spieleordner.de kannst du ganz einfach deine
                          gesamte Spielesammlung inventarisieren. Praktische
                          Features wie der Import von Informationen zu Spielen
                          machen die Inventarisierung und das spätere Finden von
                          Spielen kinderleicht.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
            <Paper className={classes.featurePaper}>
              <Grid
                container
                component="div"
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: "16px" }}>
                  <Grid
                    container
                    component="div"
                    sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Box>
                        <CasinoIcon className={classes.featureIcon} />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.featureHeader}
                        >
                          Erfasse deine Partien
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                          className={classes.featureBody}
                        >
                          Du liebst Statistiken? Wir auch! Mit Spieleordner.de
                          kannst du ganz einfach deine ganzen Partien erfassen
                          und so spannende Statistiken einsehen. Wer hat die
                          höchste Siegesrate? Was ist euer meist gespieltes
                          Spiel?
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
            <Paper className={classes.featurePaper}>
              <Grid
                container
                component="div"
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: "16px" }}>
                  <Grid
                    container
                    component="div"
                    sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Box>
                        <GroupsIcon className={classes.featureIcon} />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.featureHeader}
                        >
                          Spiele und Sammle gemeinsam
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                          className={classes.featureBody}
                        >
                          Spieleordner.de ermöglicht es dir, dass du gemeinsam
                          mit Freunden, Partner oder gar deinem Haustier an
                          deiner Spielesammlung arbeitest. So kannst du z.B.
                          Spiele verleihen oder die Sammlung gemeinsam pflegen.
                          Der Planung der nächsten gemeinsamen Runde steht somit
                          nichts mehr im Weg!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
            <Paper className={classes.featurePaper}>
              <Grid
                container
                component="div"
                sx={{
                  display: "flex",
                  flexFlow: "row wrap",
                  alignItems: "center",
                }}
              >
                <Box sx={{ padding: "16px" }}>
                  <Grid
                    container
                    component="div"
                    sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <Box>
                        <LoyaltyIcon className={classes.featureIcon} />
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Box>
                        <Typography
                          component="div"
                          variant="h6"
                          className={classes.featureHeader}
                        >
                          Hier findest du keine Kekse oder Werbung
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="text.secondary"
                          component="div"
                          className={classes.featureBody}
                        >
                          Wir lieben Spieleordner.de, darum gibt es bei uns
                          keine Werbung, keine Cookies oder sonst etwas. Wir
                          entwickeln Spieleordner.de aus Leidenschaft und als
                          passionierte Alles-Spieler. Also probier es aus!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <Grid
          item
          className={classes.rightSideGrid}
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={object().shape({
                username: string().required(
                  "Bitte einen Benutzernamen eingeben"
                ),
                password: string().required("Bitte ein Passwort eingeben"),
              })}
              onSubmit={(
                { username, password },
                { setSubmitting, setStatus, setError, resetForm }
              ) =>
                this._handleSubmit({
                  username,
                  password,
                  setSubmitting,
                  setStatus,
                  resetForm,
                })
              }
              render={(props) => {
                const {
                  values,
                  touched,
                  errors,
                  status,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                  isSubmitting,
                } = props;
                return (
                  <>
                    {isSubmitting && (
                      <Backdrop className={classes.backdrop} open>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )}
                    <img
                      src="../logo192.png"
                      alt="Spieleordner Logo"
                      style={{ display: "block", margin: "auto" }}
                    />
                    <LearnMore classes={classes} myRef={this.myRef} />
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 1 }}
                    >
                      <FormControl fullWidth margin="normal" variant="standard">
                        <TextField
                          id="username"
                          label="Benutzername"
                          name="username"
                          variant="outlined"
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.username && errors.username)}
                        />
                        <FormHelperText
                          error={Boolean(touched.username && errors.username)}
                        >
                          {touched.username && errors.username
                            ? errors.username
                            : ""}
                        </FormHelperText>
                      </FormControl>
                      <FormControl
                        fullWidth
                        margin="normal"
                        error={Boolean(touched.password && errors.password)}
                        variant="standard"
                      >
                        <TextField
                          name="password"
                          label="Passwort"
                          type="password"
                          id="password"
                          variant="outlined"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.password && errors.password)}
                        />
                        <FormHelperText
                          error={Boolean(touched.password && errors.password)}
                        >
                          {touched.password && errors.password
                            ? errors.password
                            : ""}
                        </FormHelperText>
                      </FormControl>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={Boolean(!isValid || isSubmitting)}
                        className={classes.submit}
                      >
                        {"Anmelden"}
                      </Button>
                      <Grid container>
                        <Grid item xs>
                          <Link href="/forgotpassword" variant="body2">
                            Passwort vergessen?
                          </Link>
                        </Grid>
                        <Grid item>
                          <Link href="/signup" variant="body2">
                            Registrieren
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                );
              }}
            />

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(Login);
