import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import { Box, Typography, Button } from '@mui/material';

const useStyles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  contentBox: {
    width: "100%",
    maxWidth: 600,
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "8px",
  },
});

class SupportUs extends Component {
  constructor(props) {
    super(props);
    this.props.setStateValue("isLoading", false);
    useHeaderStore.setState({ title: "other.supportUs", help: "help.supportUs"})
  }

  componentDidMount() {
    this.props.setStateValue("isLoading", false);
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <AccountDashboardLayout>
          <Box className={classes.contentBox}>
            <Typography variant="body1" align="left" gutterBottom>
              Um unsere Arbeit und das Fortbestehen dieses Projektes zu
              unterstützen, kannst du einen der folgenden Links* nutzen.
              <br />
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              href="https://www.amazon.de/b?_encoding=UTF8&tag=spieleordner.de-21&linkCode=ur2&linkId=109b6684e3f5403a443d84efa6ab9b2b&camp=1638&creative=6742&node=12950651"
              fullWidth="true"
              target="_blank"
              sx={{ marginTop: "50px" }}
            >
              {"Amazon"}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              href="https://www.spiele-offensive.de/index.php?suchwort=Brettspiel&cmd=suchergebnis&pid=536&bid=17"
              fullWidth="true"
              target="_blank"
              sx={{ marginTop: "10px" }}
            >
              {"Spiele-Offensive"}
            </Button>            
            <Typography
              variant="caption"
              align="left"
              display="block"
              gutterBottom
              sx={{ marginTop: "20px" }}
            >
              (*) Hierbei handelt es sich um Provisions-Links, auch
              Affiliate-Links genannt. Wenn du auf einen solchen Link klickst
              und auf der Zielseite etwas kaufst, bekommen wir vom betreffenden
              Anbieter oder Online-Shop eine Vermittlerprovision. Es entstehen
              für dich keine Nachteile beim Kauf oder Preis.
            </Typography>
          </Box>
        </AccountDashboardLayout>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(SupportUs);
