import React, { Component } from "react";
import UserDataService from "../../../services/user.service";
import { Link } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";
import moment from "moment";import AccountDashboardLayout from 'src/components/layouts/account-dashboard';
import { useHeaderStore } from "src/store/store";

import { Button, Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

const useStyles = (theme) => ({
  selectButton: {
    marginTop: theme.spacing(3),
  },
  box: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
    [theme.breakpoints.up("md")]: {
      gap: "32px",
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  card: {
    color: "#212B36",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms ",
    background:
      "linear-gradient(to bottom, rgba(145, 158, 171, 0), rgba(145, 158, 171, 0.12))",
    overflow: "hidden",
    borderRadius: "16px",
    padding: "40px",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  cardHighlighted: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "64px",
      paddingBottom: "64px",
    },
  },
  contentText: {
    marginLeft: "12px",
  },
  contentPrice: {},
  mobile: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 112px)",
    borderRight: "1px solid #ccc",
  },
  contentBox: {
    width: "100%",
    maxWidth: 450,
    marginTop: "30px",
  },
  contentTable: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
});

class Membership extends Component {
  constructor(props) {
    super(props);

    this.retrieveAccountDetails = this.retrieveAccountDetails.bind(this);
    this.props.setStateValue("isLoading", true);
    useHeaderStore.setState({ title: "account.membership", help: "help.membership"})

    this.state = {
      account: {},
      isLoading: true,
    };
  }

  componentDidMount() {
    this.retrieveAccountDetails();
  }

  retrieveAccountDetails() {
    UserDataService.checkAccountType("accountInfo", 0)
      .then((response) => {
        this.setState({
          account: response.data.curr_membership,
        });
        this.props.setStateValue("isLoading", false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { account, isLoading } = this.state;
    const { classes } = this.props;
    return (
      <AccountDashboardLayout>
        <Box className={classes.contentBox}>
          <TableContainer className={classes.contentTable}>
            <Table className={classes.tableAltTable} aria-label="account-type">
              <TableBody>
                <TableRow>
                  <TableCell>Mitgliedschaft:</TableCell>
                  <TableCell>{account.type}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Gültig bis:</TableCell>
                  <TableCell>
                    {account.type === "Basis"
                      ? "Unbegrenzt"
                      : moment(account.valid_until).isValid()
                      ? moment(account.valid_until).format("DD.MM.YYYY")
                      : ""}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sammlungen:</TableCell>
                  <TableCell>
                    {account.cnt_collections}/
                    {account.type === "Pro"
                      ? "Unbegrenzt"
                      : account.max_collections}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Spiele:</TableCell>
                  <TableCell>
                    {account.cnt_games}/
                    {account.type === "Pro" ? "Unbegrenzt" : account.max_games}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "grid",
              alignItems: "center",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.selectButton}
              component={Link}
              to="/settings/account/membership/upgrade"
            >
              {account.type === "Pro"
                ? "Verlängern"
                : account.type === "Plus"
                ? "Verlängern oder Upgraden"
                : "Upgraden"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.selectButton}
              component={Link}
              to="/settings/account/membership/history"
            >
              Historie
            </Button>
          </Box>
        </Box>
      </AccountDashboardLayout>
    );
  }
}

export default withStyles(useStyles)(Membership);
