import React from "react";
import AccountDashboardLayout from "src/components/layouts/account-dashboard";
import { useHeaderStore } from "src/store/store";


export default function Settings(props) {
  
  const updateHeader = useHeaderStore(state => state.updateHeader);

  React.useEffect(() => {
    updateHeader({title: "setting.setting_other", help: "help.settings"});
    props.setStateValue("isLoading", false);
  }, []);

  return (
    <AccountDashboardLayout />     
  );
}
